import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetail from "./BasicDetail";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate, useLocation } from "react-router-dom";

const { TabPane } = Tabs;
const identifiers = ["SaudatHeader"];

const ContractNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [editcustomer, setEditcustomer] = useState(true);
  const [edititem, setEdititem] = useState(true);
  const [editgarde, setEditgrade] = useState(true);
  const [edituom, setEdituom] = useState(true);
  const [editMode, setEditMode] = useState(true);
  const [editMode1, setEditMode1] = useState(true);
  const [allData, setAllData] = useState({
    SaudatHeader: [
      {
        sauda_code: null,
        dealer_code: null,
        sauda_date: dayjs(new Date()).format("DD-MM-YYYY"),
        sauda_quality: null,
        per_unit: "t",
        sauda_qty: null,
        sauda_remark: null,
        sauda_rate: null,
        cust_code: null,
        item_code: null,
        actual_qty: null,
        item_cat_code: null,
        freight_type_code: null,
        uom_code: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);

    axios
      .get(employeeData.URL + "/api/v1/salesContract/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,

            PER_KG: {
              fields: [{ name: "KEY" }, { name: "VALUE" }],
              rows: [
                { KEY: "k", VALUE: "Kg" },
                { KEY: "t", VALUE: "Ton" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleSave = (event) => {
    // console.log("ytu467676");
    var formv = true;

    identifiers.forEach((val) => {
      // console.log(val);
      // console.log(val);
      let count = 0;
      allData[val].forEach((data) => {
        // console.log(data);
        // console.log(data);
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }

          count = count + 1;
        }
      });
    });

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
      };

      console.log(postData);

      const missingFields = [];

      // Check for each field and add to missingFields if null or empty
      // if (
      //   postData.SaudatHeader[0].actual_qty == null ||
      //   postData.SaudatHeader[0].actual_qty === ""
      // ) {
      //   missingFields.push("Actual Quantity");
      // }

      if (
        postData.SaudatHeader[0].cust_code == null ||
        postData.SaudatHeader[0].cust_code === ""
      ) {
        missingFields.push("Customer Name");
      }

      if (
        postData.SaudatHeader[0].dealer_code == null ||
        postData.SaudatHeader[0].dealer_code === ""
      ) {
        missingFields.push("Dealer Name");
      }

      if (
        postData.SaudatHeader[0].freight_type_code == null ||
        postData.SaudatHeader[0].freight_type_code === ""
      ) {
        missingFields.push("Freight Type ");
      }

      if (
        postData.SaudatHeader[0].item_cat_code == null ||
        postData.SaudatHeader[0].item_cat_code === ""
      ) {
        missingFields.push("Item Category ");
      }

      if (
        postData.SaudatHeader[0].item_code == null ||
        postData.SaudatHeader[0].item_code === ""
      ) {
        missingFields.push("Item ");
      }

      if (
        postData.SaudatHeader[0].sauda_date == null ||
        postData.SaudatHeader[0].sauda_date === ""
      ) {
        missingFields.push("Contract Date");
      }

      if (
        postData.SaudatHeader[0].sauda_qty == null ||
        postData.SaudatHeader[0].sauda_qty === ""
      ) {
        missingFields.push("Contract  Quantity");
      }

      if (
        postData.SaudatHeader[0].sauda_quality == null ||
        postData.SaudatHeader[0].sauda_quality === ""
      ) {
        missingFields.push("Contract Grade");
      }

      if (
        postData.SaudatHeader[0].sauda_rate == null ||
        postData.SaudatHeader[0].sauda_rate === ""
      ) {
        missingFields.push("Contract Rate");
      }

      // if (postData.SaudatHeader[0].sauda_remark == null || postData.SaudatHeader[0].sauda_remark === "") {
      //   missingFields.push("Sauda Remark");
      // }

      // Display missing fields if any
      if (missingFields.length > 0) {
        message.error({
          content:
            "Please fill the mandatory fields: " + missingFields.join(", "),
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if validation fails
      }

      axios
        .post(
          employeeData.URL + "/api/v1/salesContract/create-sales-contract",
          postData,
          {
            withCredentials: true,
            credentials: "include",
          }
        )
        .then((response) => {
          message.success({
            content: "Sales Contract Created Successfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/sales/transaction/salesForm");
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  // const handleCancel = (event) => {
  //   setLoading(false);
  //   history("/sales/transaction/salesForm");
  // };

  const handleCancel = (event) => {
    // Set loading to false if you have a loading state
    // setLoading(false);

    // Check if the current location's search is "hi"
    if (location.search === "?contract") {
      // Navigate to /sales/dashboard
      history("/sales/dashboard");
    } else {
      // Fallback to another route if needed
      history("/sales/transaction/salesForm");
    }
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition={"left"}
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Basic Details</span>} key="0">
                  <BasicDetail
                    editcustomer={editcustomer}
                    setEditcustomer={setEditcustomer}
                    edititem={edititem}
                    setEdititem={setEdititem}
                    editgarde={editgarde}
                    setEditgrade={setEditgrade}
                    edituom={edituom}
                    setEdituom={setEdituom}
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.SaudatHeader[0]}
                    editMode={editMode}
                    editMode1={editMode1}
                    setEditMode1={setEditMode1}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default ContractNew;
