import {
  Row,
  InputNumber,
  Col,
  Card,
  Input,
  Form,
  DatePicker,
  Select,
  Flex,
} from "antd";
import classes from "../Pages.module.css";
import dayjs from "dayjs";
import { TimePicker } from "antd";
const constantTimeValue = dayjs().format("HH:mm:ss"); // Get the current time

const { Option } = Select;

const DataField = (props) => {
  const commonStyles = {
    textAlign: "left",
    width: "100%",
    backgroundColor: "white",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  };
  // const enabledStyles = {
  //   ...commonStyles,
  //   color: "#071e54",
  //   fontWeight: "bold",
  //   cursor: "text",
  //   pointerEvents: "auto",
  // };

  // const disabledStyles = {
  //   ...commonStyles,
  //   color: "#071e54 !important",
  //   fontWeight: "bold !important",
  //   opacity: 1, // Override the default opacity for disabled state
  //   cursor: "not-allowed",
  //   pointerEvents: "none",
  // };
  // // console.log(props, "datafeild");

  const options = props.options || { rows: [], fields: [] };

  return (
    <Col lg={props.lg} md={props.md} className={classes["Col"]}>
      {props.editMode ? (
        <Form layout="vertical">
          <Form.Item
            colon={false}
            style={{ margin: "0", padding: "0" }}
            label={
              <div
                style={{
                  padding: "0rem 0.5rem",
                  fontSize: "0.6rem",
                  fontWeight: "bold",
                }}
                className={classes["Label"]}
              >
                {props.name}
                {props.required ? <span style={{ color: "red" }}> *</span> : ""}
              </div>
            }
          >
            {" "}
            {props.type === "Date" ? (
              <DatePicker
                value={props.value ? dayjs(props.value, "DD-MM-YYYY") : null}
                format="DD-MM-YYYY"
                style={{
                  textAlign: "left",
                  width: "100%",
                  backgroundColor: "white",
                  color: "#071e54",
                  fontWeight: "normal",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
                onChange={(date, dateString) =>
                  props.handleChange(date, dateString, props.param)
                }
              />
            ) : props.type === "Time" ? (
              <TimePicker
                value={
                  props.param === "in_time"
                    ? dayjs(constantTimeValue, "HH:mm:ss")
                    : props.value === null
                    ? null
                    : dayjs(props.value, "HH:mm:ss")
                }
                disabled={props.readOnly}
                // value={props.value ? dayjs(props.value, "HH:mm:ss") : null}
                format="HH:mm:ss"
                // style={props.readOnly ? disabledStyles : enabledStyles}
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  color: "#000000",
                  fontWeight: "normal",
                  height: "33px", // Adjust the height as needed
                  // lineHeight: "40px", // Ensure line height matches height
                  // padding: "0 30px 0 10px",
                  boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 12px 0px",
                }}
                placeholder="Select Time"
                onChange={(time, timeString) =>
                  props.handleChange(time, timeString, props.param)
                }
              />
            ) : props.type === "Select" ? (
              <Select
                id={props.id}
                value={props.value === null ? null : props.value}
                // showSearch
                onChange={(value) => props.handleChange(value, props.param)}
                // bordered={false}
                // disabled={props.readOnly}
                dropdownStyle={{ textTransform: "capitalize" }}
                style={{
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  color: "#000000",
                  fontWeight: "normal",
                  height: "33px", // Adjust the height as needed
                  // lineHeight: "40px", // Ensure line height matches height
                  // padding: "0 30px 0 10px",
                  boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 12px 0px",
                }}
                placeholder="Search to Select"
                optionFilterProp="children"

                // filterOption={(input, option) => option.children >= 0}
                // filterSort={(optionA, optionB) => optionA.children}
              >
                {props.options && props.options.rows ? (
                  // {props.options && props.options.length ? (
                  props.options.rows.map((option) => {
                    // props.options.map((option) => {
                    return (
                      <Option
                        disabled={props.readOnly}
                        style={{
                          textTransform: "capitalize",
                          color: "#1777C4",
                        }}
                        key={option[props.options.fields[0].name]}
                        value={option[props.options.fields[0].name]}
                      >
                        {option[props.options.fields[1].name]}
                      </Option>

                      // <Option
                      // key={option.invoice_code} // Use invoice_code as the key
                      // value={option.invoice_code} // Use invoice_code as the value
                      // disabled={props.readOnly}
                      // style={{ textTransform: "capitalize", color: "#1777C4" }}
                      // >
                      // {option.invoice_code} {/* Display the invoice_code */}
                      // </Option>
                    );
                  })
                ) : (
                  <Option disabled={true} key="no-data" value="No Data">
                    No Data
                  </Option>
                )}
              </Select>
            ) : (
              <Input
                placeholder="Enter"
                id={props.id}
                value={props.value ? props.value : null}
                // bordered={false}
                onChange={(e) => props.handleChange(e, props.param, props.id)}
                style={{
                  // padding: "13.3px 63px 14px 24px",
                  // borderRadius: "50px",
                  // background: "transparent",
                  // width: "100%",
                  // border: "none",
                  // outline: "none",
                  // fontSize: "16px",
                  // fontWeight: "600",
                  // lineHeight: "1.5",
                  // color: "#071e54",
                  // WebkitAppearance: "none",
                  // WebkitBoxShadow:
                  //   "inset 3px 3px 6px #bfc3cf, inset -3px -3px 6px #fff",
                  // boxShadow:
                  //   "inset 3px 3px 6px #bfc3cf, inset -3px -3px 6px #fff",
                  // height: "40px",
                  width: "100%",
                  textAlign: "left",
                  backgroundColor: "white",
                  textTransform: "capitalize",
                  color: "#000000",
                  fontWeight: "normal",
                  height: "33px", // Adjust the height as needed
                  // color: "#1777C4",
                  // fontWeight: "bold",
                  // lineHeight: "40px", // Ensure line height matches height
                  // padding: "0 30px 0 10px",
                  boxShadow: "rgba(100, 100, 111, 0.1) 0px 0px 12px 0px",
                }}
                readOnly={props.readOnly}
              />
            )}
          </Form.Item>
        </Form>
      ) : (
        <Card
          bodyStyle={{ padding: "0.5rem 1rem" }}
          bordered={false}
          className={classes["Card"]}
        >
          {props.type === "Select" ? (
            <p className={classes["Label"]}>
              {props.name}
              <span className={classes["Data"]}>
                {props.value
                  ? props.options.rows[
                      props.options.rows.findIndex(
                        (element) =>
                          element[props.options.fields[0].name] === props.value
                      )
                    ][props.options.fields[1].name]
                  : null}
              </span>
            </p>
          ) : (
            <p className={classes["Label"]}>
              {props.name}{" "}
              <span className={classes["Data"]}>
                {props.value ? props.value : null}
              </span>
            </p>
          )}
        </Card>
      )}
    </Col>
  );
};

export default DataField;
