import { Row, Col, Card, Space, Statistic } from "antd";
import classes from "./Dashboard.module.css";
import DataCards from "./DataCards";
import ProfileCard from "./ProfileCard";
import { useEffect, useContext } from "react";
import DataContext from "../../../Context/dataContext";
import Charts from "./Charts";
import {
  ShoppingCartOutlined,
  UserOutlined,
  ShoppingOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
const Dashboard = () => {
  const employeeData = useContext(DataContext);

  return (
    <>
      <Row gutter={16} className={classes["SiteLayoutBackground"]}>
        <Col xl={24} lg={24} md={24}>
          {/* <DataCards data={employeeData.analysis} /> */}
          <DataCards />
          <Row>
            <Col
              lg={{ span: 24, offset: 0 }}
              md={24}
              xs={24}
              className={classes["ColC"]}
            >
              <Card className={classes["Card"]}>
                {/* <Charts chartType="bar" data={employeeData.analysis.data} /> */}
                <Charts />
              </Card>
            </Col>
          </Row>
        </Col>
        <p></p>
        <Col
          lg={{ span: 24, offset: 0 }}
          md={24}
          xs={24}
          className={classes["ColC"]}
        >
          <Card className={classes["Card"]}>
            <Charts
              chartType="line"
              // data1={employeeData.analysis.itemQtyOfOrder}
            />
          </Card>
        </Col>
      </Row>
      <p></p>

      <p></p>

      <p></p>
    </>
  );
};

export default Dashboard;
