import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import classes from "../Pages.module.css";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
const { Option } = Select;

const DependentEdit = (props) => {
  const [pinErrors, setPinErrors] = useState({});
  const [gstErrors, setGstErrors] = useState({});
  const [form] = Form.useForm();

  const handlePinChange = (index, event) => {
    const value = event.target.value;

    // Only allow numeric input
    if (/^\d*$/.test(value)) {
      // Validate pin_no (should be exactly 6 numeric digits)
      if (value.length === 6) {
        setPinErrors((prev) => ({
          ...prev,
          [index]: "", // Clear the error message if valid
        }));
      } else {
        setPinErrors((prev) => ({
          ...prev,
          [index]: "Please enter exactly 6 digits for PIN.",
        }));
      }
    } else {
      setPinErrors((prev) => ({
        ...prev,
        [index]: "Only numeric values are allowed.",
      }));
    }
  };

  const handleGstChange = (index, event) => {
    const value = event.target.value;

    // GST number validation regex
    const gstRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    // Maximum length check
    if (value.length > 15) {
      setGstErrors((prev) => ({
        ...prev,
        [index]: "GST number must be 15 characters long.",
      }));
      return; // Stop further validation if length exceeds 15
    }

    if (!gstRegex.test(value)) {
      setGstErrors((prev) => ({
        ...prev,
        [index]: "Invalid GST number format. Format: 11AAAAA1111A1Z1",
      }));
    } else {
      setGstErrors((prev) => ({
        ...prev,
        [index]: "", // Clear error if valid
      }));
    }
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      return {
        ...data,
        deliveryDetails: allValues.deliveryDetails,
      };
    });
  };

  return (
    <Form
      layout="vertical"
      name="filter_form"
      form={form}
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="deliveryDetails" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Space key={key} className={classes["Space"]} align="center">
                  <Row gutter={16}>
                    <p></p>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "name"]}
                        fieldKey={[fieldKey, "name"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Name <span style={{ color: "red" }}> *</span>
                          </div>
                        }
                      >
                        <Input
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={true}
                          placeholder="Enter Name"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "city_code"]}
                        fieldKey={[fieldKey, "city_code"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            City <span style={{ color: "red" }}> *</span>
                          </div>
                        }
                      >
                        <Select
                          bordered={true}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          optionFilterProp="children"
                          placeholder="Search to Select"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.localeCompare(optionB.children)
                          }
                        >
                          {props.cityOptions.rows.map((option) => {
                            return (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={option[props.cityOptions.fields[0].name]}
                                value={option[props.cityOptions.fields[0].name]}
                              >
                                {option[props.cityOptions.fields[1].name]}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "add_1"]}
                        fieldKey={[fieldKey, "add_1"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Address <span style={{ color: "red" }}> *</span>
                          </div>
                        }
                      >
                        <Input
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={true}
                          placeholder="Enter Address"
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "pin"]}
                        fieldKey={[fieldKey, "pin"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Pin Number <span style={{ color: "red" }}> *</span>
                          </div>
                        }
                        validateStatus={pinErrors[index] ? "error" : ""}
                        help={pinErrors[index]} // Display error message if present
                      >
                        <Input
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            color: "#1777C4",
                            fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={true}
                          placeholder="Enter Pin Number"
                          onChange={(value) => handlePinChange(index, value)} // Validate on change
                          maxLength={6} // Limit input length to 6 characters
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "locality_code"]}
                        fieldKey={[fieldKey, "locality_code"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.6rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Locality <span style={{ color: "red" }}> *</span>
                          </div>
                        }
                      >
                        <Select
                          bordered={true}
                          style={{
                            width: "100%",
                            textAlign: "left",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          placeholder="Select"
                          optionFilterProp="children"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children.localeCompare(optionB.children)
                          }
                        >
                          {props.localityOptions.rows.map((option) => {
                            return (
                              <Option
                                style={{
                                  textTransform: "capitalize",
                                  color: "#1777C4",
                                }}
                                key={
                                  option[props.localityOptions.fields[0].name]
                                }
                                value={
                                  option[props.localityOptions.fields[0].name]
                                }
                              >
                                {option[props.localityOptions.fields[1].name]}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col lg={8} md={24}>
                      <Form.Item
                        {...restField}
                        name={[name, "gst_number"]}
                        fieldKey={[fieldKey, "gst_number"]}
                        label={
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Gst Number <span style={{ color: "red" }}> *</span>
                          </div>
                        }
                        validateStatus={gstErrors[index] ? "error" : ""}
                        help={gstErrors[index]}
                      >
                        <Input
                          style={{
                            width: "100%",
                            float: "left",
                            backgroundColor: "white",
                            // color: "#1777C4",
                            // fontWeight: "bold",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                          bordered={true}
                          placeholder="Enter GST Number"
                          onChange={(event) => handleGstChange(index, event)}
                          maxLength={15}
                        />
                      </Form.Item>
                    </Col>

                    <Col lg={21} md={0}></Col>
                    <Col lg={1} md={24}>
                      <MinusCircleOutlined
                        className={classes["Remove"]}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    </Col>
                    <Col lg={1} md={24}>
                      <PlusCircleOutlined
                        className={classes["Add"]}
                        onClick={() => {
                          add();
                        }}
                      />
                    </Col>
                    <Col lg={1} md={24}></Col>
                    <Col lg={24} md={24}>
                      <hr></hr>
                    </Col>
                  </Row>
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  className={classes["DashedButton"]}
                  onClick={() => {
                    add();
                  }}
                >
                  Add Delivery
                </Button>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default DependentEdit;
