import PieChart, { Connector } from "devextreme-react/pie-chart";
import { BarSeries } from "@devexpress/dx-react-chart-material-ui";
import {
  Chart,
  Series,
  SeriesTemplate,
  CommonSeriesSettings,
  ArgumentAxis,
  Label,
  ValueAxis,
  Legend,
  Export,
  Tooltip,
  ZoomAndPan,
} from "devextreme-react/chart";
import "./style.css";
import { useState } from "react";
import { Select } from "antd";

const { Option } = Select;

function titleCase(str) {
  str = str.toLowerCase().split(" ");
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(" ");
}

const Charts = (props) => {
  const [data1, setData] = useState([
    {
      get_distributor: "",
      quantity: "",
    },
  ]);
  const [type, setType] = useState("QUANTITY");
  const [type2, setType2] = useState("AMOUNT");
  const data3 = [
    { color: "FF0000" },
    { color: "0000FF" },
    { color: "00FF00" },
    { color: "FF0000" },
    { color: "0000FF" },
    { color: "00FF00" },
  ];
  const onChange = (val) => {
    setType(val);
  };

  const onChange2 = (val) => {
    setType2(val);
  };

  if (props.chartType !== "line")
    return (
      <>
        <p></p>
        <Chart
          className="line"
          title={"Top  Customer Wise Sales"}
          // dataSource={props.chartType === "line" ? props.data1 : props.data}
          palette="Soft"
        >
          <CommonSeriesSettings
            label={{ visible: true }} //for value in top of bar
            argumentField="get_distributor"
            type={props.chartType}
            barPadding={0.1}
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          ></CommonSeriesSettings>

          <ZoomAndPan
            valueAxis="both"
            argumentAxis="both"
            allowMouseWheel={true}
            panKey="shift"
          />
          <Series
            key={props.chartType}
            argumentField="get_distributor"
            // valueField={"quantity"}
            // name={props.data.quantity}
          />
          <ArgumentAxis>
            <Label wordWrap="none" overlappingBehavior="rotate" />
          </ArgumentAxis>
          <Legend
            visible={false}
            horizontalAlignment="right"
            verticalAlignment="top"
          ></Legend>
          <ValueAxis grid={{ visible: false }} />
          <Tooltip enabled={true} />
        </Chart>
      </>
    );
  else {
    return (
      <>
        <Chart
          className="line"
          type={props.chartType}
          // dataSource={props.data1}
          palette="Pastel"
          title={"Item Wise Sales "}
        >
          <Series argumentField="item" valueField={"qty"}>
            <Label visible={true}>
              <Connector visible={true} width={1} />
            </Label>
          </Series>
          <Tooltip enabled={true} />
        </Chart>
      </>
    );
  }
};

export default Charts;
