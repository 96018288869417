import React from "react";
import { Table, InputNumber, Select } from "antd";
import Input from "@mui/material/Input";

import { IconButton, Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onDeleteChange,
  onDiscountChange,
}) => {
  // Define columns
  const columns = [
    { key: "1", title: "Item", dataIndex: "item_name" },
    { key: "2", title: "Req Code", dataIndex: "req_code" },
    { key: "3", title: "Size", dataIndex: "size_name" },
    { key: "4", title: "Uom", dataIndex: "uom" },
    { key: "5", title: "Grade", dataIndex: "grade" },
    { key: "6", title: "Cost Center", dataIndex: "cost_center" },

    { key: "7", title: "Actual Bal.", dataIndex: "actual_bal" },
    // { key: "7", title: "Qty", dataIndex: "qty" },
    {
      key: "8",
      title: "Qty",
      dataIndex: "qty",
      editable: true,
      render: (text, record, index) => {
        const qtyInteger = parseInt(text, 10); // Convert to integer
        return (
          record.qty && (
            <Input
              type="number"
              style={{
                border: "none", // Remove border
                outline: "none", // Remove outline
                textAlign: "right",
                width: "70px", // Set width to 70px
                height: "20px", // Set height to 20px
              }}
              value={isNaN(qtyInteger) ? "" : String(qtyInteger)} // Convert back to string
              onChange={(e) => {
                onInputChange(e, index, record);
              }}
            />
          )
        );
      },
    },
    { key: "9", title: "Pcs", dataIndex: "no_of_pcs" },
    {
      key: "10",
      title: "Action",
      render: (text, record, index) => (
        <IconButton
          onClick={() => onDeleteChange(record.uniq_id)}
          aria-label="delete"
          color="primary"
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.uniq_id} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
