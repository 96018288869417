import menu_icon from "./menu_icon.png";
import plus_icon from "./plus_icon.png";
import bulb_icon from "./bulb_icon.png";
import compass_icon from "./compass_icon.png";
import user_icon from "./user_icon.png";
import message_icon from "./message_icon.png";
import code_icon from "./code_icon.png";
import send_icon from "./send_icon.png";
import Genie from "./Genie.jpeg";
import Chat from "./chatlogo.jpg";

export const assets = {
  menu_icon,
  plus_icon,
  bulb_icon,
  compass_icon,
  user_icon,
  message_icon,
  code_icon,
  send_icon,
  Genie,
  Chat,
};
