import { Row, Input } from "antd";
import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Tooltip } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import classes from "../Pages.module.css";
import DataField from "./datafieldir";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import IssueDetails from "./Issuedetail";

const Issueheaderdetails = (props) => {
  // // console.log(props, "12345678909654323456789");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [deletedRowKeys, setDeletedRowKeys] = useState([]);

  useEffect(() => {
    if (
      props.ad.ACCOUNT_CODE.fields &&
      Array.isArray(props.ad.ACCOUNT_CODE.fields)
    ) {
      const newCols = props.ad.ACCOUNT_CODE.fields.map((col) => ({
        title: col.name.split("_").join(" ").toLowerCase(),
        dataIndex: col.name,
        key: col.name,
      }));

      setColumns(newCols);

      if (
        props.ad.ACCOUNT_CODE.rows &&
        Array.isArray(props.ad.ACCOUNT_CODE.rows)
      ) {
        const newRows = props.ad.ACCOUNT_CODE.rows.map((row, index) => ({
          key: uuidv4(), // Use uuid for unique key
          SNO: tableData.length + 1,
          ...row,
        }));
        setRows(newRows);
      } else {
        console.error("Expected rows to be an array");
      }
    } else {
      console.error("Expected fields to be an array");
    }
  }, [props.ad.ACCOUNT_CODE]);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleRowClick = async (record) => {
    // // console.log("Clicked Row Data:", record);
    // // console.log("Clicked Row Data:", record);
    const val = record.issue_code;
    try {
      const response = await axios.get(
        `http://sastetab.com/api/v1/issuereturnroute/table-data/${val}`,
        {
          withCredentials: true, // Include credentials (cookies, authorization headers, etc.)
        }
      );

      const responseData = response.data;
      // // console.log("wetyuiochfssxhjsixvcv", responseData);

      // Assuming responseData contains the data you want to use to update the state
      props.setData((data) => {
        // Update IssueReturnHeader
        const updatedIssueReturnHeader = [
          {
            ...data.IssueReturnHeader[0],
            issue_code: record.issue_code,
            dept_code: record.dept_nm,
            d_code: record.division_nm,
            issue_date: record.date,
            depttcode: record.dept_code,
            dcccode: record.d_code,
            additionalData: responseData.additionalField, // Add any additional data if needed
          },
        ];

        // Update tableData within IssueReturnHeader
        const updatedTableData = responseData.ACCOUNT_CODE.rows.map((row) => ({
          key: uuidv4(), // Assign a unique key to each row
          ...row,
        }));

        setTableData(updatedTableData);
        return {
          ...data,
          IssueReturnHeader: updatedIssueReturnHeader,
          IssueDetail: updatedTableData, // Add or update the tableData in the state
        };
      });

      setIsModalVisible(false); // Close the modal after selection
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error (e.g., show a message to the user)
    }
  };
  const rowSelection = {
    // Your row selection configuration if needed
  };

  const handledDeleteRow = (key) => {
    setTableData((prevData) => prevData.filter((row) => row.key !== key));
  };

  const handleDeleteRow = (key) => {
    setDeletedRowKeys((prevKeys) => {
      const newDeletedKeys = [...prevKeys, key];

      // Update the IssueDetail in the parent component
      props.setData((prevData) => {
        const updatedIssueDetail = prevData.IssueDetail.filter(
          (item) => item.key !== key
        );

        return {
          ...prevData,
          IssueDetail: updatedIssueDetail,
        };
      });

      // Notify parent about the change in deleted rows
      //   if (onDeletedRowsChange) {
      //     onDeletedRowsChange(newDeletedKeys);
      //   }

      return newDeletedKeys;
    });
  };

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["IssueReturnHeader"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        IssueReturnHeader: newdata,
      };
    });
  };

  const handleInputChange = (key, param, value) => {
    // // console.log("Input value:", value);
    // // console.log("Input value:", value);

    props.setData((prevData) => {
      const updatedIssueDetail = prevData.IssueDetail.map((item) => {
        if (item.key === key) {
          // // console.log("Updating item:", item);
          return { ...item, [param]: value };
        }
        return item;
      });

      return {
        ...prevData,
        IssueDetail: updatedIssueDetail,
      };
    });

    // // console.log("Updated data:", props.data); // Note: This may not immediately reflect the new state due to React's asynchronous state updates
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["IssueReturnHeader"]];
      newdata[0][param] = val;
      return {
        ...data,
        IssueReturnHeader: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["IssueReturnHeader"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        IssueReturnHeader: newdata,
      };
    });
  };

  const tableColumns = [
    { title: "Item Name", dataIndex: "itm_nm", key: "itm_nm" },
    { title: "Size Name", dataIndex: "size_nm", key: "size_nm" },
    { title: "Grade", dataIndex: "quality_nm", key: "quality_nm" },
    { title: "Cost Center", dataIndex: "cc_code", key: "cc_code" },
    {
      title: "Return Qty",
      dataIndex: "return_qty",
      key: "return_qty",
      render: (_, record) => (
        <Input
          value={record.return_qty}
          onChange={(e) =>
            handleInputChange(record.key, "return_qty", e.target.value)
          }
        />
      ),
    },
    { title: "Issue Qty", dataIndex: "qty", key: "qty" },
    { title: "UOM Code", dataIndex: "uom_code", key: "uom_code" },
    { title: "Rate", dataIndex: "rate", key: "rate" },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="link" onClick={() => handleDeleteRow(record.key)}>
          <AiFillDelete />
        </Button>
      ),
    },
  ];

  // const handleTableDataChange = (e, index, field) => {
  //     const newTableData = [...tableData];
  //     newTableData[index][field] = e.target.value;
  //     setTableData(newTableData);
  // };

  const filteredTableData = tableData.filter(
    (row) => !deletedRowKeys.includes(row.key)
  );

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="Issue Code"
            param="issue_code"
            value={props.data.issue_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleDChange}
          name="Return Date"
          type="Date"
          param="return_date"
          value={props.data.return_date}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          name="Issue Code"
          type="Input"
          param="issue_code"
          value={props.data.issue_code}
          onClick={handleOpenModal}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleDChange}
          type="Date"
          name="Issue Date"
          param="issue_date"
          value={props.data.issue_date}
        />

        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange}  name = "Return Code" type="Input" param = "return_code" value = {props.data.return_code}/>   */}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Division "
          type="Input"
          param="d_code"
          value={props.data.d_code}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Department"
          type="Input"
          param="dept_code"
          value={props.data.dept_code}
        />
      </Row>

      <Modal
        title="SELECT A CODE HERE"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={rows}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record);
            },
          })}
          rowKey={(record) => record.id}
        />
      </Modal>

      <Table
        columns={tableColumns}
        dataSource={filteredTableData}
        rowKey="key"
      />
    </div>
  );
};

export default Issueheaderdetails;
