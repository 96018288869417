import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import Consum from "./Consumption";
import Elect from "./Electricity";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const { TabPane } = Tabs;

const RequisitionView = (props) => {
  const { id } = useParams();
  const employeeData = useContext(DataContext);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editDode, setEditDode] = useState(true);
  const [allData, setAllData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [ad, setAD] = useState(null);
  const [itemm, setItemm] = useState(null);
  const [responseDataArray, setResponseDataArray] = useState([]);
  const [dataFetchingCompleted, setDataFetchingCompleted] = useState();

  useEffect(() => {
    setAllData(null);
    setStoreData(null);
    setAD(null);

    axios
      .get(employeeData.URL + "/api/v1/rollingprod/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setItemm(response.data.data);
        setDataFetchingCompleted(response.data.data.prodDetail.length);

        setAllData((allData) => {
          let newDetails = response.data.data;

          // Modify reqDetail array to include Mode property with value true for each object
          newDetails.prodDetail.forEach((detail) => {
            detail.Mode = true;
          });

          newDetails.prodConsumDet.forEach((detail) => {
            detail.Mode = true;
          });

          newDetails.prodElecDet.forEach((detail) => {
            detail.Mode = true;
          });
          return {
            ...newDetails,
          };
        });

        setStoreData((storeData) => {
          let newDetails = response.data.data;

          return {
            ...newDetails,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .get(employeeData.URL + "/api/v1/rollingprod/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  useEffect(() => {
    const fetchData = async (item, index) => {
      try {
        // console.log(item.item_code);
        const response = await axios.get(
          `${employeeData.URL}/api/v1/rollingprod/additional-data-of-hsn/${item.item_cd}`,
          {
            withCredentials: true,
          }
        );
        // console.log(response.data.data);
        const data = response.data.data;
        setResponseDataArray((prevDataArray) => {
          const newArray = [...prevDataArray];
          newArray[index] = data; // Set the response data at the correct index
          return newArray;
        });
      } catch (error) {
        console.error(
          `Error fetching data for item_code ${item.item_code}:`,
          error
        );
      }
    };

    const fetchAllData = async () => {
      if (!itemm || !itemm.prodDetail) {
        console.error("itemm or itemm.prodHdr is null or undefined", itemm);
        return;
      }

      try {
        await Promise.all(
          itemm.prodDetail.map((item, index) => fetchData(item, index))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchAllData().then(() => {
      // console.log(responseDataArray.length);
      // console.log(responseDataArray.length);
    });
  }, [itemm, setResponseDataArray]);

  const handleEditButtonClick = () => {
    setEditMode(true);
    setEditDode(true);
  };

  const handleSave = (event) => {
    // console.log(storeData, "this is store data");
    // console.log(storeData, "this is store data");
    const postData = {
      ...allData,
    };

    const postDataS = {
      ...storeData,
      prodDetail: allData.prodDetail.map((dep) => {
        return {
          ...dep,
        };
      }),
      prodConsumDet: allData.prodConsumDet.map((dep) => {
        return {
          ...dep,
        };
      }),
      prodElecDet: allData.prodElecDet.map((dep) => {
        return {
          ...dep,
        };
      }),
    };

    // console.log(postData);
    // console.log(postData);
    // console.log(postData);

    // Filter out undefined elements from reqDetail array
    postData.prodDetail = postData.prodDetail.filter(
      (detail) => detail !== null && detail !== undefined
    );

    postData.prodConsumDet = postData.prodConsumDet.filter(
      (detail) => detail !== null && detail !== undefined
    );

    postData.prodElecDet = postData.prodElecDet.filter(
      (detail) => detail !== null && detail !== undefined
    );

    // Process the filtered array
    postData.prodDetail.forEach((lang, index) => {
      if (!lang.uniq_id) {
        postData.prodDetail[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.prodDetail[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    storeData.prodDetail.forEach((lang, index) => {
      const dataIndex = postData.prodDetail.findIndex(
        (element) => element.uniq_id === lang.uniq_id
      );
      if (dataIndex === -1) {
        postData.prodDetail.push({
          uniq_id: lang.uniq_id,
          PARAM: "DELETE",
        });
      }
    });

    // Process the filtered array
    postData.prodConsumDet.forEach((lang, index) => {
      if (!lang.scd_code) {
        postData.prodConsumDet[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.prodConsumDet[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    storeData.prodConsumDet.forEach((lang, index) => {
      const dataIndex = postData.prodConsumDet.findIndex(
        (element) => element.scd_code === lang.scd_code
      );
      if (dataIndex === -1) {
        postData.prodConsumDet.push({
          scd_code: lang.scd_code,
          PARAM: "DELETE",
        });
      }
    });

    // Process the filtered array
    postData.prodElecDet.forEach((lang, index) => {
      if (!lang.uniq_id) {
        postData.prodElecDet[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.prodElecDet[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    storeData.prodElecDet.forEach((lang, index) => {
      const dataIndex = postData.prodElecDet.findIndex(
        (element) => element.uniq_id === lang.uniq_id
      );
      if (dataIndex === -1) {
        postData.prodElecDet.push({
          uniq_id: lang.uniq_id,
          PARAM: "DELETE",
        });
      }
    });

    // console.log(JSON.stringify(postData, undefined, 2));

    // if (
    //   postData.prodHdr[0].stock_date == null ||
    //   postData.prodHdr[0].from_time == null ||
    //   postData.prodHdr[0].to_time == null ||
    //   postData.prodHdr[0].no_of_hrs == null
    // ) {
    //   message.error({
    //     content: "Please Fill The Mandatory Fields!!!",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return; // Stop further execution if validation fails
    // }

    // const allFieldsNull = postData.prodDetail.every(
    //   (detail) =>
    //     detail.item_code === null &&
    //     detail.uom_code === null &&
    //     detail.size_code === null &&
    //     detail.quality_code === null &&
    //     detail.no_of_picces === null &&
    //     detail.quantity === null &&
    //     detail.rate === null
    // );

    // if (allFieldsNull) {
    //   message.error({
    //     content: "Please Fill At Least One Row In The Prod Detail Tab!!!",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return; // Stop further execution if all fields are null
    // }

    // const fieldDisplayNameMap = {
    //   item_code: "Item",
    //   size_code: "Size",
    //   quality_code: "Grade",
    //   rate: "Rate",
    //   no_of_picces: "Pcs",
    //   quantity: "Quantity",
    //   uom_code: "Uom",
    // };

    // const invalidRows = postData.prodDetail.filter((detail, index) => {
    //   if (detail.PARAM === "DELETE") {
    //     return false;
    //   }
    //   const mandatoryFields = [
    //     "item_code",
    //     "size_code",
    //     "quality_code",
    //     "rate",
    //     "no_of_picces",
    //     "quantity",
    //     "uom_code",
    //   ];
    //   const missingFields = mandatoryFields.filter(
    //     (field) =>
    //       detail[field] === null ||
    //       detail[field] === undefined ||
    //       detail[field] === ""
    //   );
    //   if (missingFields.length > 0) {
    //     // Map field names to display names
    //     const displayNames = missingFields.map(
    //       (field) => fieldDisplayNameMap[field]
    //     );
    //     // Construct error message for this row
    //     const errorMessage = `Row ${
    //       index + 1
    //     }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
    //     // Display error message
    //     message.error({
    //       content: errorMessage,
    //       className: "custom-class",
    //       style: {
    //         marginTop: "1vh",
    //       },
    //     });

    //     setLoading(false);
    //     return true;
    //   }
    //   return false;
    // });
    // if (invalidRows.length > 0) {
    //   return; // Stop further execution if validation fails
    // }

    // ///////////////////

    // const allFieldsNullCon = postData.prodConsumDet.every(
    //   (detail) =>
    //     detail.item_code === null &&
    //     detail.uom_code === null &&
    //     detail.size_code === null &&
    //     detail.quality_code === null &&
    //     detail.no_of_picces === null &&
    //     detail.quantity === null &&
    //     detail.rate === null
    // );

    // if (allFieldsNullCon) {
    //   message.error({
    //     content: "Please Fill At Least One Row In The Prod Consum Det Tab!!!",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return true; // Stop further execution if all fields are null
    // }

    // const fieldDisplayNameMapCon = {
    //   item_code: "Item",
    //   size_code: "Size",
    //   quality_code: "Grade",
    //   rate: "Rate",
    //   no_of_picces: "Pcs",
    //   quantity: "Quantity",
    //   uom_code: "Uom",
    // };

    // const invalidRowsCon = postData.prodConsumDet.filter((detail, index) => {
    //   if (detail.PARAM === "DELETE") {
    //     return false;
    //   }
    //   const mandatoryFields = [
    //     "item_code",
    //     "size_code",
    //     "quality_code",
    //     "rate",
    //     "no_of_picces",
    //     "quantity",
    //     "uom_code",
    //   ];
    //   const missingFields = mandatoryFields.filter(
    //     (field) =>
    //       detail[field] === null ||
    //       detail[field] === undefined ||
    //       detail[field] === ""
    //   );
    //   if (missingFields.length > 0) {
    //     const displayNames = missingFields.map(
    //       (field) => fieldDisplayNameMapCon[field]
    //     );
    //     const errorMessage = `Row ${
    //       index + 1
    //     }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
    //     message.error({
    //       content: errorMessage,
    //       className: "custom-class",
    //       style: {
    //         marginTop: "1vh",
    //       },
    //     });

    //     setLoading(false);
    //     return true;
    //   }
    //   return false;
    // });

    // if (invalidRowsCon.length > 0) {
    //   return; // Stop further execution if validation fails
    // }

    // /////////////////////////

    // const allFieldsNullEl = postData.prodElecDet.every(
    //   (detail) =>
    //     detail.meter_no === null &&
    //     detail.rate_unit === null &&
    //     detail.reading === null &&
    //     detail.current_reading === null &&
    //     detail.pmt_unit === null
    // );

    // if (allFieldsNullEl) {
    //   message.error({
    //     content: "Please Fill At Least One Row In The Prod Elec Det Tab!!!",
    //     className: "custom-class",
    //     style: {
    //       marginTop: "1vh",
    //     },
    //   });
    //   setLoading(false);
    //   return true; // Stop further execution if all fields are null
    // }

    // const fieldDisplayNameMapEl = {
    //   meter_no: "Meter No",
    //   rate_unit: "Rate Unit",
    //   reading: "Reading",
    //   current_reading: "Current Reading",
    //   pmt_unit: "PMT Unit",
    // };

    // const invalidRowsEl = postData.prodElecDet.filter((detail, index) => {
    //   if (detail.PARAM === "DELETE") {
    //     return false;
    //   }
    //   const mandatoryFields = [
    //     "meter_no",
    //     "rate_unit",
    //     "reading",
    //     "current_reading",
    //     "pmt_unit",
    //   ];
    //   const missingFields = mandatoryFields.filter(
    //     (field) =>
    //       detail[field] === null ||
    //       detail[field] === undefined ||
    //       detail[field] === ""
    //   );
    //   if (missingFields.length > 0) {
    //     const displayNames = missingFields.map(
    //       (field) => fieldDisplayNameMapEl[field]
    //     );
    //     const errorMessage = `Row ${
    //       index + 1
    //     }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
    //     message.error({
    //       content: errorMessage,
    //       className: "custom-class",
    //       style: {
    //         marginTop: "1vh",
    //       },
    //     });

    //     setLoading(false);
    //     return true;
    //   }
    //   return false;
    // });

    // if (invalidRowsEl.length > 0) {
    //   return; // Stop further execution if validation fails
    // }

    axios
      .patch(employeeData.URL + "/api/v1/rollingprod/" + id, postData, {
        withCredentials: true,
        credentials: "include",
      })
      .then((response) => {
        message.success({
          content: "Rolling Updated Successfully!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
        history("/production/transaction/furnance-detail");
      })
      .catch((err) => {
        message.error({
          content: "An Error Occurred!!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      });

    // setEditMode(false);
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/production/transaction/furnance-detail");
  };

  return (
    <>
      {allData && ad && responseDataArray.length == dataFetchingCompleted ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={handleEditButtonClick}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-5px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Rolling Production
                </span>

                <BasicDetail
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.prodHdr[0]}
                  editMode={editMode}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
                tabBarGutter="10"
                id="tabs"
                tabBarUnderlineStyle={{ borderWidth: 0 }} // Remove border from the tab bar
              >
                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Daily Production Details
                    </span>
                  }
                  key="0"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {dataFetchingCompleted && (
                      <SizeDetail
                        ad={ad}
                        setData={setAllData}
                        data={allData.prodDetail}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        responseDataArray={responseDataArray}
                        lenght={dataFetchingCompleted}
                        setResponseDataArray={setResponseDataArray}
                        setDataFetchingCompleted={setDataFetchingCompleted}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Consumption Details
                    </span>
                  }
                  key="1"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px", // Adjust the value as needed
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {console.log(dataFetchingCompleted, "this is lenght")}

                    {dataFetchingCompleted && (
                      <Consum
                        ad={ad}
                        setData={setAllData}
                        data={allData.prodConsumDet}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        responseDataArray={responseDataArray}
                        lenght={dataFetchingCompleted}
                        setResponseDataArray={setResponseDataArray}
                        setDataFetchingCompleted={setDataFetchingCompleted}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Electricity Con Details
                    </span>
                  }
                  key="2"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px", // Adjust the value as needed
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {console.log(dataFetchingCompleted, "this is lenght")}

                    {dataFetchingCompleted && (
                      <Elect
                        ad={ad}
                        setData={setAllData}
                        data={allData.prodElecDet}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        responseDataArray={responseDataArray}
                        lenght={dataFetchingCompleted}
                        setResponseDataArray={setResponseDataArray}
                        setDataFetchingCompleted={setDataFetchingCompleted}
                      />
                    )}
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RequisitionView;
