import React, { useEffect, useState, useRef } from "react";
import {
  Form,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Table,
  Input,
  message,
} from "antd";
import {
  UpOutlined,
  DownOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import classes from "../Pages.module.css";
import styles from "../Pages.module.css";
const { Option } = Select;

const ControlRoomForm = () => {
  const [form] = Form.useForm();
  const [sites, setSites] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [heads, setHeads] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [tableData, setTableData] = useState([]); // Initialize as empty
  const [filteredTableData, setFilteredTableData] = useState([]); // Initialize as empty
  const [sortAsc, setSortAsc] = useState(false);
  const [sortByHead, setSortByHead] = useState(false);
  const [selectedHead, setSelectedHead] = useState(null);
  const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null); // New state for selected customer
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [updateHeadValue, setUpdateHeadValue] = useState("");
  const [openingBalance, setOpeningBalance] = useState(0);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const tableRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/controldata/additional-data",
          {
            withCredentials: true,
          }
        );

        const { site_list, dealer_naem, headlist, delearlistcode } =
          response.data.data;

        setSites(site_list.rows || []);
        setDealers(dealer_naem.rows || []);
        setHeads(headlist.rows || []);
        setCustomers(delearlistcode.rows || []);
        setFilteredCustomers(delearlistcode.rows || []);
      } catch (error) {
        console.error("Error fetching additional data:", error);
      }
    };

    fetchData();
  }, []);

  const fetchTableData = async () => {
    try {
      const response = await axios.get(
        "http://sastetab.com/api/v1/controldata/controldata",
        {
          withCredentials: true,
        }
      );

      const { table_data } = response.data.data;
      const accountBalances = {};

      const formattedTableData = table_data.rows.map((row) => {
        const accountCode = row.account_code;
        const amount = parseFloat(row.amount);

        if (!accountBalances[accountCode]) {
          accountBalances[accountCode] = 0;
        }

        if (row.entry_type === "D") {
          accountBalances[accountCode] += amount;
        } else if (row.entry_type === "C") {
          accountBalances[accountCode] -= amount;
        }

        const balance = accountBalances[accountCode];
        const balanceType = balance >= 0 ? "Dr" : "Cr";
        const formattedBalance = `${Math.abs(balance)} ${balanceType}`;

        return {
          ...row,
          key: row.unique_id, // Use unique_id as the key for each row
          vDate: row.voucher_date.split("T")[0],
          balanceAmount: formattedBalance,
          debit: row.entry_type === "D" ? row.amount : "",
          credit: row.entry_type === "C" ? row.amount : "",
          balanceType: balanceType,
          ha: row.ha,
          no_days: row.crd,
          dueDate: row.due_date.split("T")[0],
        };
      });

      setTableData(formattedTableData);
      setFilteredTableData(formattedTableData);
      setIsDataLoaded(true);
    } catch (error) {
      console.error("Error fetching table data:", error);
    }
  };

  useEffect(() => {
    fetchTableData();
  }, []);

  const handleDateRangeChange = (dates) => {
    if (dates) {
      const [startDate, endDate] = dates;

      let filteredData = tableData;
      if (selectedCustomer) {
        filteredData = filteredData.filter(
          (row) => row.account_code === selectedCustomer
        );
      }

      filteredData = filteredData.filter((row) => {
        const rowDate = new Date(row.vDate);
        return rowDate >= startDate && rowDate <= endDate;
      });

      setFilteredTableData(filteredData);

      if (filteredData.length > 0) {
        const firstTransaction = filteredData[0];
        const openingBalance = `${firstTransaction.balanceAmount}`;
        setOpeningBalance(openingBalance);
      } else {
        setOpeningBalance("0"); // Reset if no data
      }
    } else {
      if (selectedCustomer) {
        const customerFilteredData = tableData.filter(
          (row) => row.account_code === selectedCustomer
        );
        setFilteredTableData(customerFilteredData);
      } else {
        setFilteredTableData(tableData); // Reset to original data if no customer is selected
      }
      setOpeningBalance("0"); // Reset if no date is selected
    }
  };

  const handleSort = (key, asc = true) => {
    const sortedData = [...filteredTableData].sort((a, b) => {
      if (asc) {
        return a[key] > b[key] ? 1 : -1;
      } else {
        return a[key] < b[key] ? 1 : -1;
      }
    });
    setFilteredTableData(sortedData);
  };

  useEffect(() => {
    if (sortAsc) {
      handleSort("balanceAmount");
    }
  }, [sortAsc]);

  useEffect(() => {
    if (sortByHead && selectedHead) {
      const filteredData = tableData.filter((row) => row.head === selectedHead);
      setFilteredTableData(filteredData);
    } else {
      setFilteredTableData(tableData);
    }
  }, [sortByHead, selectedHead, tableData]);

  const handleSubmit = (values) => {
    // console.log("Form values:", values);
  };

  const handleDealerChange = (value) => {
    setSelectedDealer(value);

    if (value) {
      const selectedDealerObj = dealers.find(
        (dealer) => dealer.external_entity_code === value
      );
      if (selectedDealerObj) {
        const entityCode = selectedDealerObj.external_entity_code;

        const filtered = customers.filter(
          (customer) => customer.distributor_code === entityCode
        );
        setFilteredCustomers(filtered);
      } else {
        setFilteredCustomers([]);
      }
    } else {
      setFilteredCustomers(customers);
    }
  };

  const handleCustomerChange = (value) => {
    const selectedCustomerObj = customers.find(
      (customer) => customer.distributor_name === value
    );
    if (selectedCustomerObj) {
      setSelectedCustomer(selectedCustomerObj.account_code);
      // console.log(customers.account_code); // Set the account_code of the selected customer
    } else {
      setSelectedCustomer(null);
    }
  };

  const handleShowButtonClick = () => {
    // console.log("Handle Show Button Clicked"); // Check if this logs to the console
    if (selectedCustomer) {
      console.log("Selected Customer Account Code:", selectedCustomer);
      const filteredData = tableData.filter(
        (row) => row.account_code === selectedCustomer
      );
      setFilteredTableData(filteredData);
    }
  };

  const handleupdateButtonClick = async () => {
    if (!selectedRowKeys.length) {
      message.error("Please select rows to update.");
      return;
    }

    if (!updateHeadValue.trim()) {
      message.error("Please enter a head value to update.");
      return;
    }

    try {
      const response = await axios.put(
        "http://sastetab.com/api/v1/controldata/update_head",
        {
          rowKeys: selectedRowKeys,
          newHeadValue: updateHeadValue.trim(),
        },
        { withCredentials: true }
      );

      // console.log("Update response:", response); // Log the response for debugging
      if (response.status === 200) {
        // Check if the update was successful
        message.success("Head updated successfully.");
        fetchTableData(); // Refetch table data to reflect changes
      } else {
        message.error("Update failed. Please try again.");
      }
    } catch (error) {
      console.error("Error  :", error);
      message.error("Failed to update head. Please try again.");
    }
  };

  const handleRowSelect = (unique_id) => {
    setSelectedRowKeys((prevKeys) => {
      if (prevKeys.includes(unique_id)) {
        // Deselect the row
        return prevKeys.filter((k) => k !== unique_id);
      } else {
        // Select the row
        return [...prevKeys, unique_id];
      }
    });
  };

  const handleScrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const handleScrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = tableRef.current.scrollHeight;
    }
  };
  const handleSortByHead = () => {
    setFilteredTableData((prevTableData) => {
      const sortedData = [...prevTableData].sort((a, b) => a.ha - b.ha);
      return sortedData;
    });
  };
  const handleDetailsRedirect = () => {
    navigate("/Vouchers/BasicDetails"); // Use navigate instead of history.push
  };

  const getTotal = (data, key) =>
    data.reduce((total, row) => total + parseFloat(row[key] || 0), 0);

  const columns = [
    {
      title: "Voucher Date",
      dataIndex: "vDate",
      key: "vDate",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 110,
    },
    {
      title: "Voucher Type Desc",
      dataIndex: "voucher_type_desc",
      key: "voucher_type_desc",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 150,
    },
    {
      title: "View Details",
      key: "viewDetails",
      width: 70,
      render: (text, record) => (
        <Button onClick={handleDetailsRedirect}>
          <EditOutlined />
        </Button>
      ),
    },
    {
      title: "Account Name",
      dataIndex: "account_name",
      key: "account_name",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 200,
    },
    {
      title: "Debit",
      dataIndex: "debit",
      key: "debit",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 150,
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 150,
    },
    {
      title: "Balance Amount",
      dataIndex: "balanceAmount",
      key: "balanceAmount",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 150,
    },
    {
      title: "Head Value",
      dataIndex: "ha",
      key: "ha",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 200,
    },
    {
      title: "No Days",
      dataIndex: "no_days",
      key: "no_days",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 100,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      key: "dueDate",
      className: `${classes.tableColumn} ${classes.tableColumnLeftAlign}`,
      width: 110,
    },
  ];

  return (
    <div className={classes.container}>
      <p className={classes["Titl"]}>Control Room</p>
      <Form form={form} onFinish={handleSubmit} className={classes.form}>
        <Row gutter={[24, 0]}>
          <Col span={3}>
            <Form.Item
              name="site"
              label="Site"
              className={classes["Label"]}
              labelCol={{ span: 24 }} // Set to 0 to keep it out of the grid flow
              wrapperCol={{ span: 24 }} // Full width for the input
              // Align label at the top
            >
              <Select placeholder="Select a site" allowClear>
                {sites.map((site) => (
                  <Select.Option key={site.site_desc} value={site.site_desc}>
                    {site.site_desc}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              name="dealer"
              label="Dealer/Broker"
              className={classes.Label}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                placeholder="Select a dealer"
                allowClear
                onChange={handleDealerChange}
              >
                {dealers.map((dealer) => (
                  <Select.Option
                    key={dealer.external_entity_code}
                    value={dealer.external_entity_code}
                  >
                    {dealer.external_entity_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item
              name="customer"
              label="Customer"
              className={classes.Label}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                placeholder="Select a customer"
                allowClear
                onChange={handleCustomerChange}
              >
                {filteredCustomers.map((customer) => (
                  <Select.Option
                    key={customer.distributor_code}
                    value={customer.distributor_name}
                  >
                    {customer.distributor_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={3}>
            <Form.Item
              name="head"
              label="Head"
              className={classes.Label}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                placeholder="Select a head"
                allowClear
                onChange={(value) => setSelectedHead(value)}
              >
                {heads.map((head) => (
                  <Select.Option
                    key={head.description}
                    value={head.description}
                  >
                    {head.description}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              name="dateRange"
              label="Voucher Date"
              className={classes.Label}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <DatePicker.RangePicker onChange={handleDateRangeChange} />
            </Form.Item>
          </Col>
          {/* <Col span={8}></Col> */}
          <Col span={4}>
            <Form.Item
              label="Opening Balance"
              className={classes.Label}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input value={openingBalance} readOnly />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Update Head"
              className={classes.Label}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                value={updateHeadValue}
                onChange={(e) => setUpdateHeadValue(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item style={{ marginTop: "40px" }}>
              <Button
                style={{
                  backgroundColor: "#007bff", // Your desired blue color
                  color: "#ffffff", // Text color
                  border: "none", // Remove border if desired
                }}
              >
                Update Head
              </Button>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item style={{ marginTop: "40px" }}>
              <Button
                style={{
                  backgroundColor: "#007bff", // Your desired blue color
                  color: "#ffffff", // Text color
                  border: "none", // Remove border if desired
                }}
              >
                Show
              </Button>

              {/* <Button
                type="default"
                onClick={handleScrollToTop}
                className={classes.commonButton}
              >
                <UpOutlined />
              </Button>
              <Button
                type="default"
                onClick={handleScrollToBottom}
                className={classes.commonButton}
              >
                <DownOutlined />
              </Button> */}
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item style={{ marginTop: "40px" }}>
              <Button
                style={{
                  backgroundColor: "#007bff", // Your desired blue color
                  color: "#ffffff", // Text color
                  border: "none", // Remove border if desired
                }}
              >
                Head Wise Balance
              </Button>
              {/* <Button
                type="default"
                onClick={handleScrollToTop}
                className={classes.commonButton}
              >
                <UpOutlined />
              </Button>
              <Button
                type="default"
                onClick={handleScrollToBottom}
                className={classes.commonButton}
              >
                <DownOutlined />
              </Button> */}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className={classes.componentContainer} ref={tableRef}>
        <Table
          columns={columns}
          dataSource={filteredTableData}
          pagination={false}
          rowClassName={(record) =>
            selectedRowKeys.includes(record.unique_id) ? styles.selectedRow : ""
          }
          rowSelection={{
            selectedRowKeys,
            onChange: setSelectedRowKeys,
          }}
          // scroll={{ y: 400 }} // Adjust height as needed
        />
      </div>
      <Col className={classes.RowD}>
        <Row span={8} className={classes.Col}>
          <div className={classes.Name}>Total Count</div>
          <div className={classes.Data}>{tableData.length}</div>
        </Row>
        <Row span={8} className={classes.Col}>
          <div className={classes.Name}>Checked</div>
          <div className={classes.Data}>{selectedRowKeys.length}</div>
        </Row>
        <Row span={8} className={classes.Col}>
          <div className={classes.Name}>Unchecked</div>
          <div className={classes.Data}>
            {tableData.length - selectedRowKeys.length}
          </div>
        </Row>
      </Col>
    </div>
  );
};

export default ControlRoomForm;
