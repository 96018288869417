import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetail from "./BasicDetail";
// import BookingSize from './BookingSize';
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const { TabPane } = Tabs;

const SalesContractView = (props) => {
  const { id } = useParams();
  const employeeData = useContext(DataContext);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editMode1, setEditMode1] = useState(true);
  const [java, setJava] = useState(true);
  const [editcustomer, setEditcustomer] = useState(true);
  const [edititem, setEdititem] = useState(true);
  const [editgarde, setEditgrade] = useState(true);
  const [edituom, setEdituom] = useState(true);
  const [allData, setAllData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [ad, setAD] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [cats, setCats] = useState([]);
  const [uom, setUom] = useState([]);

  useEffect(() => {
    setAllData(null);
    setStoreData(null);
    setAD(null);

    axios
      .get(employeeData.URL + "/api/v1/vender/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setAllData((allData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });

        setStoreData((storeData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .get(employeeData.URL + "/api/v1/vender/additional-data", {
        withCredentials: true,
      })

      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  // useEffect(() => {
  //   setAllData(null);
  //   setStoreData(null);
  //   setAD(null);

  //   axios
  //     .get(employeeData.URL + "/api/v1/items/" + id, {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       console.log(response);

  //       setAllData((allData) => {
  //         let newDetails = response.data.data;
  //         return {
  //           ...newDetails,
  //         };
  //       });

  //       setStoreData((storeData) => {
  //         let newDetails = response.data.data;
  //         return {
  //           ...newDetails,
  //         };
  //       });
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });

  //   axios
  //     .get(employeeData.URL + "/api/v1/items/additional-data", {
  //       withCredentials: true,
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       setAD((ad) => {
  //         let newad = response.data.data;
  //         return {
  //           ...newad,
  //         };
  //       });
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     });
  // }, []);

  const handleSave = (event) => {
    const postData = {
      ...allData,
    };

    console.log(postData);
    const missingFields = [];

    // Check for each field and add to missingFields if null or empty
    // if (
    //   postData.SaudatHeader[0].actual_qty == null ||
    //   postData.SaudatHeader[0].actual_qty === ""
    // ) {
    //   missingFields.push("Actual Quantity");
    // }

    if (
      postData.DealerHeader[0].party_name == null ||
      postData.DealerHeader[0].party_name === ""
    ) {
      missingFields.push("Vendor Name");
    }

    if (
      postData.DealerHeader[0].type_code == null ||
      postData.DealerHeader[0].type_code === ""
    ) {
      missingFields.push("Vendor Type");
    }

    if (
      postData.DealerHeader[0].acc_group_code == null ||
      postData.DealerHeader[0].acc_group_code === ""
    ) {
      missingFields.push("Group");
    }

    // if (postData.SaudatHeader[0].sauda_remark == null || postData.SaudatHeader[0].sauda_remark === "") {
    //   missingFields.push("Sauda Remark");
    // }

    // Display missing fields if any
    if (missingFields.length > 0) {
      message.error({
        content:
          "Please fill the mandatory fields: " + missingFields.join(", "),
        className: "custom-class",
        style: {
          marginTop: "1vh",
        },
      });
      setLoading(false);
      return; // Stop further execution if validation fails
    }

    // console.log(allData);

    // console.log(JSON.stringify(postData, undefined, 2));

    axios
      .patch(
        employeeData.URL +
          "/api/v1/vender/" +
          postData.DealerHeader[0]["vendor_code"],
        postData,
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        message.success({
          content: "Vendor Master Updated Successfully!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
        history("/purchase/transactions/vender-master");
      })
      .catch((err) => {
        message.error({
          content: "An Error Occurred!!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      });

    setEditMode(false);
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/purchase/transactions/vender-master");
  };

  return (
    <>
      {allData && ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition={"left"}
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Basic Details</span>} key="0">
                  <BasicDetail
                    editcustomer={editcustomer}
                    setEditcustomer={setEditcustomer}
                    edititem={edititem}
                    setEdititem={setEdititem}
                    editgarde={editgarde}
                    setEditgrade={setEditgrade}
                    edituom={edituom}
                    setEdituom={setEdituom}
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.DealerHeader[0]}
                    editMode={editMode}
                    customers={customers}
                    items={items}
                    cats={cats}
                    uom={uom}
                    editMode1={editMode1}
                    setEditMode1={setEditMode1}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default SalesContractView;
