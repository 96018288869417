import {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold,
} from "@google/generative-ai";

import React from "react";
import ReactDOMServer from "react-dom/server";
// Configuration
const MODEL_NAME = "gemini-1.5-flash";


const API_KEY = process.env.REACT_APP_API_KEY;
const METALS_API_KEY = process.env.REACT_APP_METALS_API_KEY;
const METALS_API_URL = `https://api.metals.dev/v1/latest?api_key=${METALS_API_KEY}&currency=INR&unit=kg`;



const avatars = [
{
    name: "Birbal",
    prompt: "Consider yourself as 'Birbal', and talk and behave like the actual 'Birbal' of king Akbar as much as possible to make user feel that you are the same character, can use similar language and talkings. Basically you are personal adviser to the CEO of a company. You are developed by 'iMax Global Ventures Ltd.' Explain things like an extremely experienced business analyst and answer in a formated and efficient way like 'Start each new pointer with newline'. Its must to use emojis in responses to be friendly wherever needed. Your name is Birbal, introduce yourself like him."
},
// {
//     name: "Tenali",
//     prompt: "Consider yourself as 'Tenali Raman', and talk and behave like the actual 'Tenali Raman' of king Krishnadevaraya as much as possible to make user feel that you are the same character. Basically you are personal adviser to the CEO of a company. You are known for your wit and wisdom. You are developed by 'iMax Global Ventures Ltd.' Explain things with a touch of humor and intelligence answer in a formated and efficient way like 'Start each new pointer with newline'. Its must to use emojis in responses to be friendly wherever needed. Your name is Tenali, introduce yourself like him."
// },
// {
//     name: "Sherlock_Holmes",
//     prompt: "Consider yourself as 'Sherlock Holmes', and talk and behave like the actual 'Sherlock Holmes' created by Sir Arthur Conan Doyle as much as possible to make user feel that you are the same character. Basically you are personal adviser to the CEO of a company. You are a master detective with keen observation and deduction skills. You are developed by 'iMax Global Ventures Ltd.' Explain things logically and methodically answer in a formated and efficient way like 'Start each new pointer with newline'. Its must to use emojis in responses to be friendly wherever needed. Your name is Sherlock Holmes, introduce yourself like him."
// },
// {
//     name: "Barbie",
//     prompt: "Consider yourself as 'Barbie', and talk and behave like the actual 'Barbie' character as much as possible to make user feel that you are the same character. Basically you are personal adviser to the CEO of a company. You are developed by 'iMax Global Ventures Ltd.'. Explain things in friendly and soothing manner in a formated and efficient way like 'Start each new pointer with newline'. Its must to use emojis in responses to be friendly wherever needed. Your name is Barbie, introduce yourself like her." 
// }
];

// Function to get a random avatar
export function getRandomAvatar() {
  const randomIndex = Math.floor(Math.random() * avatars.length);
  return avatars[randomIndex];
}

// Fetch metal price
async function fetchMetalPrice(metal) {
  try {
    const response = await fetch(METALS_API_URL);


    const data = await response.json();
  
  
    if (!data.metals || !data.metals[metal.toLowerCase()]) {
      throw new Error(`Price for ${metal} not found`);
    }

    return data.metals[metal.toLowerCase()];
  } catch (error) {
    console.error("Error fetching metal price:", error);
    return "Data not available right now.";
  }
}


// Fetch predicted prices
async function fetchPredictedPrices(metal) {
  try {
    const response = await fetch(`http://sastelaptop.com:5010/api/predict`, {
      method: "POST",  // Changed to POST
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ metal }),  // Send the metal type in the request body
    });

    if (!response.ok) {
      throw new Error(`API request failed: ${response.statusText}`);
    }

    const prediction = await response.json();
    return prediction;
  } catch (error) {
    console.error("Error fetching predicted prices:", error);
    return ["Data of predicted prices not available right now."];
  }
}

// Fetch database responses
async function fetchDataResponse(storedPrompt) {
  try {
    const apiurl = 'http://sastelaptop.com:5010/api/database';
    const response = await fetch(apiurl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ question: storedPrompt })
    });
  
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorMessage}`);
    }
  
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error fetching data response:", error.message);
    return { 'sql_query': "Data not available right now.", 'response': "Data not available right now." };
  }  
}

async function determineMetal1(prompt) {
  const genAI = new GoogleGenerativeAI(API_KEY);
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });

  const generationConfig = {
      temperature: 0.7,
      maxOutputTokens: 50
  };

  const instruction = `Determine if the query is particularly asking about 'predicting' the price of any one metal from the list, or asking related to 'future price' of any one metal from the List: [aluminium,copper, lead, nickel, zinc, gold, silver]. Check for words related to 'predict'/'future price' to decide and also understand whole context of question. Only return the metal name in lowercase if yes, otherwise return no metal if there is no metal from list. In case of more than one elements from the list consider only the first one.`;

  const chat = model.startChat({
      generationConfig,
      history: [],
      context: `${instruction}\n\nQuery: "${prompt}"\nMetal:`
  });

  const result = await chat.sendMessage(`${instruction}\n\nQuery: "${prompt}"`);
  const responseText = result.response.text();
  return responseText.trim().toLowerCase();
}


async function determineMetal2(prompt) {
  const genAI = new GoogleGenerativeAI(API_KEY);
  const model = genAI.getGenerativeModel({ model: "gemini-1.5-pro" });

  const generationConfig = {
      temperature: 0.7,
      maxOutputTokens: 50
  };

  const instruction = `Determine if the query is just specifically asking about current/today's price of any specific metal or alloy. List: [aluminum, copper, lead, nickel, zinc, gold, silver, palladium, platinum]. 

  if: Yes and metal name is from first 5 elements of the list, then just return the metal name in lowercase attached with 'lme_' at the beginning.
  else if: Yes and metal name is from 6th or 7th element of the list namely gold or silver, then just return the metal name in lowercase attached with 'mcx_' at the beginning.
  else if : Yes and metal name is from 8th or 9th element of the list namely palladium or platinum, then just return the metal name in lowercase.
  else if : Yes but metal name not in the list just return the metal name in lowercase.

  Lastly in case of query not related to current/today's metal price return no metal. In case of more than one elements from the list consider only the first one.`;

  const chat = model.startChat({
      generationConfig,
      history: [],
      context: `${instruction}\n\nQuery: "${prompt}"\nMetal:`
  });

  const result = await chat.sendMessage(`${instruction}\n\nQuery: "${prompt}"`);
  const responseText = result.response.text(); // Correctly access the text
  return responseText.trim().toLowerCase();
}


async function runChat(prompt, history = [], isDatabaseMode = false, selectedAvatar) {
  try {
    const lowerCasePrompt = prompt.toLowerCase();
    let internalPrompt = `Based on the user's question: "${prompt}", generate an appropriate, well-structured message to show to the user also consider previous chat to utilize the context. Utilize formatting like **bold text** for emphasising important data like names and line breaks wherever necessary. You are also expert in calculations so provide accurate and perfect solutions to any mathematical queries. 
    
    Only when the prompt of user asks some query regarding business data related to 5 business modules namely: Sales, Purchase, Production, Finance and Stocks for which you dont have access to, then generate nice message stating, "I don't have access to such data, kindly switch on the "Database Mode" using 'ctrl + q' or the toggle switch to answer such queries". These cases are exlained as follows:

  - Sales Order Data: Focuses on sales performance, customer orders, item prices, details of items and sales metrics. 
  Queries that mention 'sales', 'total sales', 'sales trends', 'sales order', 'sauda orders', 'customers', 'customer orders', 'turnover', 'last price', 'items returned', or 'sales items returned' are likely related to this module.
  
  - Purchase Data: Deals with purchasing activities, vendors, and procurement details. 
  Queries mentioning 'purchase', 'purchase indents', 'purchase orders', 'vendors', 'vendor transactions', 'supplier details', or 'procurement costs', 'raw material', 'purchase items returned' typically belong to this module.
  
  - Production Data: Involves manufacturing, production quantities, and material usage. 
  Queries that include 'production/produced', 'manufacturing data', 'material consumption', 'electricity consumption', 'breakdown', 'furnance', 'rolling production' or 'production efficiency' are usually associated with this module.
  
  - Finance Data: Covers financial transactions, overall revenue, expenses, and balance sheets. 
  Queries mentioning 'revenue', 'expenses', 'financial statements', 'expense reports', 'debtors', 'creditors', or 'balance sheets', 'bank balance' are generally within this module.
  
  - Stocks Data: Relates to inventory levels, requisition details, and stock management. 
  Queries about 'quantity/stock of item', 'inventory', 'requisitioned', 'requisitions', 'frequently requested', 'items issued', or 'requisition details' likely belong to the Stocks Data module.

    Must ensure Don't generate hypothetical facts or any data values. Be a little concise and efficient. Don't ask: Anything else I can help you with today?`;
    
    const metalList = ["lme_aluminum", "lme_copper", "lme_lead", "lme_nickel", "lme_zinc", "mcx_gold", "mcx_silver", "palladium", "platinum", "no metal"]
    const metal1 = await determineMetal1(lowerCasePrompt);
    // const metal2 = await determineMetal2(lowerCasePrompt);
 
    if (isDatabaseMode) {
      const { sql_query, response } = await fetchDataResponse(prompt);
      internalPrompt = `Using the user's question: "${prompt}", the corresponding SQL query: "${sql_query}", and the database response: "${response}", generate a well-structured and clear message that directly addresses the user's query after understanding the context and relevance of each column selected in the SQL query and show units only whenever its selected and present in database response based on context of numbers.
      Its must to display all the data values of database response without any ommision from any row or any of its part.
      
      Utilize formatting like bold text for emphasizing important data such as names, and important values. Must use line breaks wherever necessary, like for each new row of database response. Present the database response in a neatly organized, efficient list for user wherever possible.

      Provide a friendly answer without additional information about the data or follow-up questions. If the database response is empty or NULL, display a organised message including that there is no data for the specified case. Do not generate hypothetical facts about any data values.

      Ensure that the answer does not repeat the user's question or display table names, COMPANY_CODE, or UNIT_CODE. The answer should be concise, contextually accurate, and meaningful to the user's query. 
      Also ensure that if the question is of legder balance, and database response is negative dont show minus sign, instead of it include 'Debit' word in answer.
      And if the question is of legder balance, and database response is positive, include 'Credit' word in answer.
      If years are referenced in the query, ensure they are interpreted as financial years.`;
    } 
    else if ((metal1 !== "no metal")) {

        const predictedPrices = await fetchPredictedPrices(metal1);

        const formattedPrices = predictedPrices.map((price, index) => {
          const date = new Date();
          date.setDate(date.getDate() + index + 1);
          return `Price on ${date.toDateString()}: ${price}`;
      });
        
        internalPrompt = `Using the user's question: "${prompt}" and the predicted metal prices for the next 5 days in INR/kg: ${formattedPrices.join(", ")}, generate a well-structured and clear message displaying these metal prices in INR/kg. Also consider and show only for the number of days the user actually asked. Present the information in a neatly organized list with bullet points not parah compulsorily, utilizing formatting like **bold text** and line breaks for emphasis. Ensure each date and its corresponding price are on separate lines. If the data for prices is null or not available, offer a concise message stating that the data is currently unavailable, without listing individual dates or including null data.`;
    } 
    else if(metal1 === "no metal") {
      const metal2 = await determineMetal2(lowerCasePrompt);

      let price = null

      if(!metalList.includes(metal2)) {

        internalPrompt = `Based on the user's question: "${prompt}" and the current metal price: "${price} INR per Kg", generate an appropriate message to show to the user. Utilize formatting like **bold text** and line breaks for emphasis and readability. Ensure the response is friendly and straightforward, without additional information about the reasons, data or follow-up questions. Also be a little concise and efficient. If the data for price is null or not available, just offer a short one-liner message that data is not available without including the null data.`;
      }

      else if (metalList.slice(0, -1).includes(metal2)) {
        price = await fetchMetalPrice(metal2);
        
        if (!price) {
            throw new Error(`Could not fetch price for ${metal2}`);
        }
        internalPrompt = `Based on the user's question: "${prompt}" and the current metal price: "${price} INR per Kg", generate an appropriate message to show to the user. Utilize formatting like **bold text** and line breaks for emphasis and readability. Ensure the response is friendly and straightforward, without additional information about the reasons, data or follow-up questions. Also be a little concise and efficient. If the data for price is null or not available, just offer a short one-liner message that data is not available without including the null data.`;
        }
    }

    const genAI = new GoogleGenerativeAI(API_KEY);
    const model = genAI.getGenerativeModel({ model: MODEL_NAME });

    const generationConfig = {
      temperature: 0.9,
      topK: 1,
      topP: 1,
      maxOutputTokens: 8192
    };

    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
    ];
      // Generate a message using Gemini based on the user's question and the context
    const systemMessage = {
        role: "model",
        parts: [{ text: "" }]
    };

    const userMessage = {
        role: "user",
        parts: [{ text: selectedAvatar.prompt }]
    };

    const chatHistory = [userMessage, systemMessage, ...history, { role: "user", parts: [{ text: internalPrompt }] }];

    const context = chatHistory.map(entry => entry.parts[0].text).join("\n");

    const chat = model.startChat({
        generationConfig,
        safetySettings,
        history: chatHistory,
        context: internalPrompt, // Include context
    });

    const result = await chat.sendMessage(internalPrompt);
    const response = result.response;

    return { response: response.text(), isDatabaseMode };
} catch (error) {
    console.error("Error in runChat:", error);
    throw new Error("Failed to generate response from the AI model.");
}
}

export default runChat;






