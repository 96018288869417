import React from "react";
import { Table, InputNumber, Select } from "antd";

const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onSelectChange,
  onDiscountChange,
  onQuantityChage,
}) => {
  // Define columns
  const columns = [
    { title: "RQ Code", dataIndex: "rq_code", key: "rq_code" },

    { title: "Req. Date", dataIndex: "plan_date", key: "plan_date" },

    { title: "Item Name", dataIndex: "item_name", key: "item_name" },

    { title: "Hsn", dataIndex: "hsn", key: "hsn" },

    { title: "Uom", dataIndex: "uom", key: "uom" },

    { title: "Size", dataIndex: "sz", key: "sz" },

    { title: "Grade", dataIndex: "qt", key: "qt" },

    {
      title: "Quantity",
      key: "total_qty",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter quantity"
          value={record.total_qty}
          onChange={
            (value) => onQuantityChage(value, record.unique_code) // Use the new handler
          }
          min={0}
        />
      ),
    },
    {
      title: "Rate",
      key: "rate",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter value"
          value={record.rate}
          onChange={(value) => onInputChange(value, record.unique_code)}
          min={0}
        />
      ),
    },

    {
      title: "Dis On",
      key: "discount_on",
      render: (text, record) => (
        <Select
          value={record.discount_on}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "discount_on")
          }
          style={{ width: 120 }}
        >
          <Option value="r">Rate</Option>
          <Option value="ta">Total Amt</Option>
        </Select>
      ),
    },

    {
      title: "Discount",
      key: "discount",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter discount"
          value={record.discount}
          onChange={(value) => onDiscountChange(value, record.unique_code)}
          min={0}
        />
      ),
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{record.total_qty * (record.rate || 0)}</span>
      ),
    },

    {
      title: "Dis Type",
      key: "dis_type",
      render: (text, record) => (
        <Select
          value={record.dis_type}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "dis_type")
          }
          style={{ width: 120 }}
        >
          <Option value="p">%</Option>
          <Option value="a">A</Option>
        </Select>
      ),
    },

    {
      title: "Final Rate",
      dataIndex: "rate1",
      key: "rate1",
    },

    {
      title: "Net Amount",
      dataIndex: "amount2",
      key: "amount2",
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_code} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
