import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { Select, Input } from "antd";

const { Option } = Select;
const BasicDetail = (props) => {
  console.log(props, "prrrrrrrrrrrrrrrrrrrrrrrrrrrr");
  const [customers, setCustomers] = useState([]);
  const [items, setItems] = useState([]);
  const [cats, setCats] = useState([]);
  const [uom, setUom] = useState([]);
  const [pinError, setPinError] = useState("");
  const [panError, setPanError] = useState("");
  const [gstError, setGstError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");

  const employeeData = useContext(DataContext);
  // const [unit, setUnit] = useState("kg"); // Default unit is 'kg'

  const handleChange = (e, param) => {
    const { value } = e.target;

    // For pin_no
    if (param === "pin_no") {
      // Allow only numeric input up to 6 digits
      if (!/^\d{0,6}$/.test(value)) {
        return; // Prevent invalid input
      }

      // Validate pin_no
      if (value.length < 6) {
        setPinError("Please enter exactly 6 digits for PIN.");
      } else {
        setPinError(""); // Clear error if valid
      }
    }
    // For pan_no
    else if (param === "pan_no") {
      // Validate length: must be exactly 10 characters
      if (value.length < 10) {
        setPanError(
          "Please enter exactly 10 characters for PAN No and valid format :XXXXX9999X."
        );
      } else if (value.length > 10) {
        return; // Prevent any further input if more than 10 characters
      } else {
        // Validate PAN format: 5 letters, 4 digits, 1 letter (XXXXX9999X)
        if (!/^[A-Z]{5}\d{4}[A-Z]$/.test(value)) {
          setPanError(
            "Invalid PAN format. Please enter in format: XXXXX9999X."
          );
        } else {
          setPanError(""); // Clear error if valid
        }
      }
    }

    // For s_tax_no (GST Number)
    else if (param === "service_tax") {
      // Convert GST to uppercase
      const uppercaseValue = value.toUpperCase();
      e.target.value = uppercaseValue; // Update the input field with uppercase

      // Validate length: must be exactly 15 characters for GST Number
      if (uppercaseValue.length < 15) {
        setGstError("Please enter exactly 15 characters for GST No.");
      } else if (uppercaseValue.length > 15) {
        return; // Prevent any further input if more than 15 characters
      } else {
        // Validate GST format: 15 characters
        if (
          !/^\d{2}[A-Z]{5}\d{4}[A-Z]{1}\d{1}[Z]{1}[A-Z0-9]{1}$/.test(
            uppercaseValue
          )
        ) {
          setGstError(
            "Invalid GST format. Please enter in format: 12ABCDE1234F1Z5."
          );
        } else {
          setGstError(""); // Clear error if valid
        }
      }
    }

    // For Email
    else if (param === "email") {
      // Basic email format validation
      if (!/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
        setEmailError("Invalid email format. Please enter a valid email.");
      } else {
        setEmailError(""); // Clear error if valid
      }
    }
    // For mobile_no
    else if (param === "ph1") {
      // Allow only numeric input of exactly 10 digits
      if (!/^\d{0,10}$/.test(value)) {
        return; // Prevent invalid input
      }

      // Validate mobile_no length
      if (value.length < 10) {
        setMobileError("Please enter exactly 10 digits for mobile number.");
      } else if (value.length > 10) {
        return; // Prevent any further input if more than 10 digits
      } else {
        setMobileError(""); // Clear error if valid
      }
    }
    props.setData((data) => {
      const newdata = [...data["DealerHeader"]];
      newdata[0][param] = e.target.value;
      //  // console.log(newdata);
      return {
        ...data,
        DealerHeader: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    // props.setData((data) => {
    //   const newdata = [...data["SaudatHeader"]];

    //   newdata[0][param] = val;
    //   return {
    //     ...data,
    //     SaudatHeader: newdata,
    //   };
    // });

    props.setData((data) => {
      // Create a new copy of salesOrder to avoid mutating the state directly
      const newdata = [...data["DealerHeader"]];

      newdata[0][param] = val;

      // Return the updated state
      return {
        ...data,
        DealerHeader: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["SaudatHeader"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        SaudatHeader: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="Party CODE"
            param="party_code"
            value={props.data.party_code}
          />
        )}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ACCOUNT NAME" param = "account_name" value = {props.data.account_name}/>    */}
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="Vendor Name"
          type="string"
          param="party_name"
          required="True"
          value={props.data.party_name}
          rules={[{ required: true, message: "Vendor name is required" }]}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleSChange}
          options={props.ad.TYPE_CODE}
          type="Select"
          name="Vendor Type"
          param="type_code"
          required="True"
          value={props.data.type_code}
        />
        <DataField
          editMode={props.editMode}
          editcustomer={props.editcustomer}
          lg={8}
          md={24}
          options={props.ad.GROUP_CODE}
          handleChange={handleSChange}
          type="Select"
          name="Group"
          param="acc_group_code"
          value={props.data.acc_group_code}
          required="True"
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="Address"
          type="textarea"
          param="add1"
          value={props.data.add1}
        />
        <DataField
          editMode={props.editMode}
          options={props.ad.CITY_CODE}
          lg={8}
          md={24}
          handleChange={handleSChange}
          type="Select"
          name="City"
          param="city"
          value={props.data.city}
        />

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="PIN CODE"
          param="pin_no"
          type="Input"
          value={props.data.pin_no}
          error={pinError}
        />

        {/* <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="CONTRACT RATE"
          param="sauda_rate"
          type="Input"
          required="True"
          value={props.data.sauda_rate}
        /> */}

        {/* <div style={{ display: "flex", alignItems: "center", color: "red" }}> */}
        {/* CONTRACT RATE Input */}
        <DataField
          editMode={props.editMode}
          lg={6}
          md={24}
          handleChange={handleChange}
          name="Phone Number"
          param="ph1"
          type="Input"
          value={props.data.ph1}
          error={mobileError}
        />

        {/* Unit Selection */}
        {/* <Select
            value={unit}
            onChange={handleUnitChange}
            style={{ marginLeft: "10px", width: 120 }}
          >
            <Option value="kg">kg</Option>
            <Option value="ton">ton</Option>
          </Select> */}
        {/* </div> */}

        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="Email"
          param="email"
          type="Input"
          value={props.data.email}
          error={emailError}
          onInput={(e) => (e.target.value = e.target.value.toLowerCase())}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="GST NO"
          param="service_tax"
          type="Input"
          value={props.data.service_tax}
          error={gstError}
        />
        <DataField
          editMode={props.editMode}
          lg={8}
          md={24}
          handleChange={handleChange}
          name="Pan No"
          param="pan_no"
          type="Input"
          value={props.data.pan_no}
          error={panError}
        />
      </Row>
    </div>
  );
};

export default BasicDetail;
