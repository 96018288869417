//import {Row} from 'antd';
import React from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  Tooltip,
  DatePicker,
  Select,
  InputNumber,
  Modal,
  Skeleton,
} from "antd";
import { Link } from "react-router-dom";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import axios from "axios";
import dayjs from "dayjs";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useContext, useEffect } from "react";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import AdminTable from "./AdminTable";
import DataContext from "../../../Context/dataContext";
//import SalesNew from './SalesNew';
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;

const BookingSize = (props) => {
  const employeeData = useContext(DataContext);
  const [sizef, setSizef] = useState([]);
  const [gradef, setGradef] = useState([]);
  const [uom, setUom] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rowsedi, setRowsedi] = useState(null);
  const [columnsedi, setColumnsedi] = useState([]);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [sizeData, setSizeData] = useState(null);
  const [form] = Form.useForm(); // Create a form instance
  const [isOpen, setIsOpen] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
    // Place to call your API if needed
  };

  const handleDateChange = (date, dateString) => {
    // handleChange(date, dateString, props.param);
    setIsOpen(false);
    if (props.onNextFocus) {
      props.onNextFocus();
    }
  };

  const disabledDate = (current) => {
    // Disable days after today (can't select future dates)
    return current && current > dayjs().endOf("day");
  };

  // Function to close modal
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      setRows(null);
      setColumns([]);
      //// console.log("useEffect");
      axios
        .get(employeeData.URL + "/api/v1/salesreturn/alldailyproduction", {
          withCredentials: true,
        })
        .then((response) => {
          // console.log(response);
          setColumns((columns) => {
            let newCols = response.data.data.order.fields.map((col) => {
              return {
                name: col.name,
                title: col.name.split("_").join(" ").toLowerCase(),
                editable: true,
              };
            });

            const newNewCols = [
              { name: "SNO", title: "SNo" },
              { name: "Select", title: "Select" },
              ...newCols,
            ];
            // console.log(newNewCols);
            return newNewCols;
          });

          setRows((rows) => {
            let newRows = response.data.data.order.rows.map((row, index) => {
              return {
                SNO: index + 1,
                Select: (
                  <Tooltip
                    placement="bottom"
                    title="Select Invoice Here"
                    color="#1777C4"
                  >
                    {/* <Link
                    to={"/sales/transaction/invoice-create"}
                    style={{ color: "#1777C4", fontWeight: "bolder" }}
                  > */}
                    <BsFillArrowRightSquareFill
                      onClick={(event) =>
                        deleteHandler(event, index, row["invoice_no"])
                      }
                      style={{
                        color: "red",
                        fontWeight: "bolder",
                        fontSize: "0.8rem",
                        id: "hhh",
                      }}
                    />
                  </Tooltip>
                ),
                ...row,
              };
            });
            return newRows;
          });
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [isModalVisible === true]);

  const deleteHandler = (event, index, code) => {
    setSizeData(null);
    // console.log(index);
    // console.log(index);
    setIsModalVisible(false);

    axios
      .get(
        employeeData.URL +
          "/api/v1/salesreturn/production-planning-item-detail/" +
          code,
        {
          withCredentials: true,
        }
      )
      .then((response) => {
        // console.log(response);
        const planningCode = response.data.data.headerDetail.rows[0].invoice_no;
        const planningDate =
          response.data.data.headerDetail.rows[0].invoice_date;
        const customerName =
          response.data.data.headerDetail.rows[0].distributor;
        const deliverAdd = response.data.data.headerDetail.rows[0].address;
        const dealer = response.data.data.headerDetail.rows[0].dealer_name;
        const distributorCode =
          response.data.data.headerDetail.rows[0].distributor_code;
        const dealerCode = response.data.data.headerDetail.rows[0].dealer_code;
        const delAdd = response.data.data.headerDetail.rows[0].del_add;
        // Update the allData state
        // Update the allData state
        props.setData((prevData) => {
          const newData = {
            ...prevData,
            bookingSize: prevData.bookingSize.map((item, idx) =>
              idx === 0
                ? {
                    ...item,
                    invoice_no: planningCode,
                    invoice_date: planningDate,
                    distributor_name: customerName,
                    delivery_add: deliverAdd,
                    dealer_name: dealer,
                    distributor_code: distributorCode,
                    dealer_code: dealerCode,
                    del_add: delAdd,
                  }
                : item
            ),
          };

          // Update the form values
          form.setFieldsValue({
            bookingSize: newData.bookingSize,
          });
          setSizeData(response.data.data);
          return newData;
        });
      });
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      //  // console.log(changedValues.bookingSize.length);
      return {
        ...data,
        bookingSize: allValues.bookingSize,
      };
    });
    // // console.log( allValues.bookingSize);
    // // console.log( allValues.bookingSize);
    // // console.log( allValues.bookingSize);
  };

  return props.editMode ? (
    <Form
      form={form} // Attach the form instance
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
      style={{ marginTop: "4rem" }} // Add top margin here
    >
      <Form.List name="bookingSize" initialValue={props.data}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, i) => (
              <React.Fragment key={key}>
                {/* Dynamic form fields can be added here */}
                {/* Example: A text input for booking name */}
                <Row gutter={16}>
                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "invoice_no"]}
                      fieldKey={[fieldKey, "invoice_no"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      label={
                        <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                          Invoice Code{" "}
                        </span>
                      }
                    >
                      <Select
                        // onChange={handleChangee}
                        onClick={showModal} // Use onClick or onFocus based on your requirement
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {/* {props.ad.MASKING_DESC.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.MASKING_DESC.fields[1].name]}
                              value={
                                option[props.ad.MASKING_DESC.fields[1].name]
                              }
                            >
                              {option[props.ad.MASKING_DESC.fields[0].name]}
                            </Option>
                          );
                        })} */}
                      </Select>
                    </Form.Item>

                    {/* Modal Component */}
                    <Modal
                      title="List Of Invoice"
                      visible={isModalVisible}
                      // onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                      width={800} // Adjust the width as needed
                      style={{ maxHeight: "80vh" }} // Adjust the height as needed
                    >
                      {rows && columns.length > 0 ? (
                        <>
                          <EmployeeTable
                            data={rows}
                            columns={columns}
                            val={true}
                          />
                        </>
                      ) : (
                        <>
                          <Skeleton loading={false} />
                          <Skeleton loading={false} />
                          <Skeleton loading={false} />
                          <Skeleton loading={false} />
                        </>
                      )}
                      {/* Place for your API call result or any other content */}
                    </Modal>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "invoice_date"]}
                      fieldKey={[fieldKey, "invoice_date"]}
                      label={
                        <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                          Invoice Date
                        </span>
                      }
                    >
                      <Input
                        bordered={true}
                        placeholder="Invoice date"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "return_date"]}
                      fieldKey={[fieldKey, "return_date"]}
                      rules={[{ required: true, message: "Missing Date" }]}
                      label={
                        <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                          Return Date{" "}
                        </span>
                      }
                    >
                      <DatePicker
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        disabledDate={disabledDate} // Disable dates before today
                        format="DD-MM-YYYY"
                        bordered={true}
                        placeholder="Select Date"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "distributor_name"]}
                      fieldKey={[fieldKey, "distributor_name"]}
                      label={
                        <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                          Customer Name
                        </span>
                      }
                      rules={[
                        {
                          //   required: true,
                          //message: 'Field should not be blank!!'
                        },
                        {
                          //   type: 'number',
                          // message: "please enter only numeric value"
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          // textAlign: "right",
                          backgroundColor: "white",
                          // color: "#1777C4",
                          // fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        disabled={true}
                        // type="number"
                        // placeholder="Enter contact no"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "dealer_name"]}
                      fieldKey={[fieldKey, "dealer_name"]}
                      label={
                        <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                          Dealer Name
                        </span>
                      }
                      rules={[
                        {
                          //   required: true,
                          //message: 'Field should not be blank!!'
                        },
                        {
                          //   type: 'number',
                          // message: "please enter only numeric value"
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          // textAlign: "right",
                          backgroundColor: "white",
                          // color: "#1777C4",
                          // fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        disabled={true}
                        // type="number"
                        // placeholder="Enter contact no"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={4} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "delivery_add"]}
                      fieldKey={[fieldKey, "delivery_add"]}
                      label={
                        <span style={{ fontWeight: "bold", fontSize: "11px" }}>
                          Delivery Address
                        </span>
                      }
                      rules={[
                        {
                          //   required: true,
                          //message: 'Field should not be blank!!'
                        },
                        {
                          //   type: 'number',
                          // message: "please enter only numeric value"
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          // textAlign: "right",
                          backgroundColor: "white",
                          // color: "#1777C4",
                          // fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        disabled={true}
                        // type="number"
                        // placeholder="Enter contact no"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24} className={classes["Col"]}>
                    <div>
                      {/* Other components or content */}
                      {sizeData !== null ? (
                        <AdminTable
                          sizeData={sizeData}
                          setMata={props.setData}
                          // data1={props.data1}
                          // data2={props.data2}
                        />
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.ITEM_CODE}
                type="Select"
                name="Item"
                value={data.item_code}
              />

              <DataField
                editMode={false}
                lg={8}
                md={24}
                name="NET AMOUNT"
                value={data.net_size_rate}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default BookingSize;
