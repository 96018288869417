import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Table,
  Tabs,
  message,
} from "antd";
import axios from "axios";
import { PlusOutlined, DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import classes from "../Pages.module.css";

const { Option } = Select;
const { TabPane } = Tabs;

const SaleReturnForm = () => {
  const [form] = Form.useForm();
  const [customers, setCustomers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [deliveryAddresses, setDeliveryAddresses] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [invodata, setInvodata] = useState([]);
  const [tableData, setTableData] = useState([
    {
      key: 1,
      item_code: "",
      item_name: "",
      size_code: "",
      size_name: "",
      quality: "",
      quality_code: "",
      no_pcs: "",
      qty: "",
      booking_rate: "",
      amount: "",
      return_wt: "",
      invoice_qty: "",
      inv_pc: "",
      invoice_no: "",
      uniq_code: "",
    },
  ]);
  const [chargeTableData, setChargeTableData] = useState([]);
  const [filteredItemName, setFilteredItemName] = useState([]);
  const [filteredSizeName, setFilteredSizeName] = useState([]);
  const [filteredQuality, setFilteredQuality] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/salesreturn/salesdata",
          {
            withCredentials: true,
          }
        );
        const { saledata, invodata } = response.data.data;
        setInvoices(saledata.rows || []);
        setInvodata(invodata.rows || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleInvoiceSelect = (value) => {
    const selectedInvoice = invoices.find(
      (invoice) => invoice.invoice_no === value
    );
    if (selectedInvoice) {
      form.setFieldsValue({
        distributor_cd: selectedInvoice.distributor,
        dealer_code: selectedInvoice.dealer_name,
        invoice_type_code: selectedInvoice.invoice_type_desc,
        delivery_add: selectedInvoice.address,

        invoice_date: dayjs(selectedInvoice.invoice_date),
        distributor: selectedInvoice.distributor_code,
      });

      const invoiceItems = invodata.filter(
        (invoice) => invoice.invoice_no === value
      );

      const updatedTableData = invoiceItems.map((item, index) => ({
        key: index + 1,
        item_code: item.item_code,
        item_name: item.item_name,
        size_code: item.size_code,
        size_name: item.size_name,
        quality_code: item.quality,
        quality: item.grade,
        no_pcs: "",
        qty: item.qty,
        booking_rate: item.booking_rate,
        amount: item.return_wt * item.booking_rate || 0,
        return_wt: item.return_wt,
        invoice_qty: item.qty,
        inv_pc: item.no_pcs,
        invoice_no: item.invoice_no,
        uniq_code: item.uniq_code,
      }));
      setTableData(updatedTableData);
    }
  };

  const handleSave = async () => {
    try {
      // console.log(form);
      const values = await form.validateFields();

      const payload = {
        invoice_no: values.invoice_no || null,
        trans_code: values.trans_code || null,
        driver_name: values.driver_name || null,
        truck_no: values.truck_no || null,
        remarks: values.remark || null,
        total_qty: values.total_qty ? parseFloat(values.total_qty) : null,
        net_amount: values.net_amount ? parseFloat(values.net_amount) : null,
        distributor_cd: values.distributor_cd
          ? parseInt(values.distributor_cd)
          : null,
        dealer_code: values.dealer_code ? parseInt(values.dealer_code) : null,
        invoice_type_code: values.invoice_type_code
          ? parseInt(values.invoice_type_code)
          : null,
        delivery_add: values.delivery_add
          ? parseInt(values.delivery_add)
          : null,
        invoice_date: values.invoice_date
          ? values.invoice_date.format("YYYY-MM-DD")
          : null,
        sales_return_code: values.sales_return_code || null,
        voucher_code: values.voucher_code || null,
        sales_return_date: values.sales_return_date
          ? values.sales_return_date.format("YYYY-MM-DD")
          : null,
        // distributor: values.distributor_code || null,
      };

      // console.log("Payload:", payload);

      const response = await axios.put(
        "http://sastetab.com/api/v1/salesreturn/filldatain",
        payload,
        { withCredentials: true }
      );

      // console.log("Save response:", response.data);
      message.success("Data saved successfully");
    } catch (error) {
      console.error(
        "Error saving data:",
        error.response ? error.response.data : error.message
      );
      message.error("Failed to save data");
    }
  };

  const handleSubmit = () => {
    // console.log("Form submitted");
    handleSave();
  };

  const handleAddRow = () => {
    const newRow = {
      key: tableData.length + 1,
      item_code: "",
      item_name: "",
      size_code: "",
      size_name: "",
      quality_code: "",
      quality: "",
      no_pcs: "",
      qty: "",
      booking_rate: "",
      amount: "",
      return_wt: "",
      invoice_qty: "",
      inv_pc: "",
      invoice_no: "",
      uniq_code: "",
    };
    setTableData([...tableData, newRow]);
  };

  const handleDeleteRow = (key) => {
    const updatedTableData = tableData.filter((row) => row.key !== key);
    setTableData(updatedTableData);
  };

  const columns = [
    // {
    //   title: "Item Code",
    //   dataIndex: "item_code",
    //   key: "item_code",
    //   render: (text, record, index) => (
    //     <Form.Item name={`item_code_${index}`} initialValue={text}>
    //       <Input />
    //     </Form.Item>
    //   ),
    // },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      render: (text, record, index) => (
        <Form.Item name={`item_name_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    // {
    //   title: "Size Code",
    //   dataIndex: "size_code",
    //   key: "size_code",
    //   render: (text, record, index) => (
    //     <Form.Item name={`size_code_${index}`} initialValue={text}>
    //       <Input />
    //     </Form.Item>
    //   ),
    // },
    {
      title: "Size Name",
      dataIndex: "size_name",
      key: "size_name",
      render: (text, record, index) => (
        <Form.Item name={`size_name_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Quality",
      dataIndex: "quality",
      key: "quality",
      render: (text, record, index) => (
        <Form.Item name={`quality_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: (
        <span>
          Return PCS<span style={{ color: "red" }}>*</span>
        </span>
      ),
      dataIndex: "no_pcs",
      key: "no_pcs",
      render: (text, record, index) => (
        <Form.Item name={`no_pcs_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Qty",
      dataIndex: "qty",
      key: "qty",
      render: (text, record, index) => (
        <Form.Item name={`qty_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Booking Rate",
      dataIndex: "booking_rate",
      key: "booking_rate",
      render: (text, record, index) => (
        <Form.Item name={`booking_rate_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record, index) => (
        <Form.Item name={`amount_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Return Wt",
      dataIndex: "return_wt",
      key: "return_wt",
      render: (text, record, index) => (
        <Form.Item name={`return_wt_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Invoice Qty",
      dataIndex: "invoice_qty",
      key: "invoice_qty",
      render: (text, record, index) => (
        <Form.Item name={`invoice_qty_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Inv PC",
      dataIndex: "inv_pc",
      key: "inv_pc",
      render: (text, record, index) => (
        <Form.Item name={`inv_pc_${index}`} initialValue={text}>
          <Input />
        </Form.Item>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        tableData.length >= 1 ? (
          <Button
            danger
            onClick={() => handleDeleteRow(record.key)}
            icon={<DeleteOutlined />}
          >
            Delete
          </Button>
        ) : null,
    },
  ];

  return (
    <div>
      <Row
        className={classes["Row"]}
        style={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Col>
          <p className={classes["Title"]}>Sales Return</p>
        </Col>
        <Col>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            style={{ marginRight: 10 }}
          >
            Save
          </Button>
        </Col>
      </Row>

      <div className={classes.container}>
        <Form form={form} onFinish={handleSubmit}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="invoice_no"
                label="Invoice No"
                rules={[
                  { required: true, message: "Please select an invoice!" },
                ]}
              >
                <Select
                  placeholder="Select Invoice"
                  onChange={handleInvoiceSelect}
                >
                  {invoices.map((invoice) => (
                    <Option key={invoice.invoice_no} value={invoice.invoice_no}>
                      {invoice.invoice_no}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="invoice_date"
                label="Invoice Date"
                rules={[{ required: true, message: "Please select a date!" }]}
              >
                <DatePicker format="YYYY-MM-DD" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="dealer_code"
                label="Dealer Name"
                rules={[{ required: true, message: "Please enter a dealer!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="distributor_cd"
                label="Distributor"
                rules={[
                  { required: true, message: "Please enter a distributor!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item
                name="invoice_type_code"
                label="Invoice Type"
                rules={[
                  { required: true, message: "Please select an invoice type!" },
                ]}
              >
                <Select placeholder="Select Invoice Type">
                  <Option value="1">Type 1</Option>
                  <Option value="2">Type 2</Option>
                </Select>
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item
                name="delivery_add"
                label="Delivery Address"
                rules={[
                  {
                    required: true,
                    message: "Please enter a delivery address!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Table
            dataSource={tableData}
            columns={columns}
            pagination={false}
            rowKey="key"
          />

          {/* <Row style={{ marginTop: 20 }}>
            <Col span={24} style={{ textAlign: "right", marginBottom: "20px" }}>
             
            </Col>
          </Row> */}
        </Form>
      </div>
    </div>
  );
};

export default SaleReturnForm;
