import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext, useRef } from "react";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import TaxDetail from "./TaxDetail";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import DummyTable from "./DummyTable"; // Import the dummy

const { TabPane } = Tabs;

const identifiers = ["purreqDetail"];

const RequisitionNew = (props) => {
  // console.log(props, "props in size detail");
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editDode, setEditDode] = useState(false);
  const sizeRef = useRef(null);
  const [netQty, setNetQty] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [editTode, setEditTode] = useState(true);
  const [allData, setAllData] = useState({
    poHdr: [
      {
        deal_date: dayjs(new Date()).format("DD-MM-YYYY"),
        deal_type_cd: null,
        currency_cd: null,
        currency_rate1: null,
        party_code: null,
        po_type1: null,
        po_ref: null,
        type: "w",
        req_code: null,
      },
    ],

    purreqDetail: [
      {
        item_code: null,
        uom_code: null,
        hsn: null,
        size_code: null,
        quality_code: null,
        total_qty: null,
        remarks: null,
        rate: null,
        amount: null,
        rate1: null,
        amount2: null,
        discount_on: null,
        discount: null,
        dis_type: null,
        remarks: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);
  const [isItemDetailVisible, setIsItemDetailVisible] = useState(true);

  const handleReqCodeChange = (newReqCode) => {
    if (newReqCode) {
      setIsItemDetailVisible(false);
    } else {
      setIsItemDetailVisible(true);
    }
  };

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/purchaseorder/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "wr", value: "With Requisition" },
                { key: "w", value: "Without Requisition" },
              ],
            },
            Dis_On: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "Rate" },
                { key: "ta", value: "Total Amt" },
              ],
            },
            Dis_Type: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "A" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //////////////////////////////////
  const calculateFinalRate = (rate, disOn, discount, disType) => {
    let rate1 = rate;

    if (disOn === "r") {
      // Discount on Rate
      if (disType === "p") {
        rate1 = rate * (1 - discount / 100); // Apply percentage discount to rate
      } else if (disType === "a") {
        rate1 = rate - discount; // Apply fixed amount discount to rate
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        rate1 = rate;
        // netAmount = qty * rate - (qty * rate * discount) / 100;
      } else if (disType === "a") {
        // netAmount = qty * rate - discount;
        rate1 = rate;
      }
    }

    return rate1 > 0 ? rate1 : 0; // Ensure final rate is non-negative
  };

  const calculateNetAmount = (
    amount,
    discount,
    disType,
    disOn,
    total_qty,
    frate
  ) => {
    // console.log(disType);
    // console.log(disType);
    let amount2 = amount;

    if (disOn === "r") {
      if (disType === "p") {
        amount2 = amount * (1 - discount / 100); // Apply percentage discount to amount
      } else if (disType === "a") {
        amount2 = frate * total_qty; // Apply fixed amount discount to amount
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        // finalRate = rate;
        amount2 = total_qty * frate - (total_qty * frate * discount) / 100;
      } else if (disType === "a") {
        amount2 = total_qty * frate - discount;
        // finalRate = rate;
      }
    }

    return amount2 > 0 ? amount2 : 0; // Ensure net amount is non-negative
  };

  const handleInputChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.purreqDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        // Ensure total_qty and value are valid numbers
        const totalQty = item.total_qty || 0;
        const rateValue = value || 0;

        const amount = rateValue * totalQty;

        const rate1 = calculateFinalRate(
          rateValue,
          item.discount_on,
          item.discount,
          item.dis_type
        );

        const amount2 = calculateNetAmount(
          amount,
          item.discount,
          item.dis_type,
          item.discount_on,
          totalQty,
          rate1
        );

        return {
          ...item,
          rate: rateValue,
          amount: amount,
          rate1: rate1,
          amount2: amount2,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      purreqDetail: newPurreqDetail,
    }));
  };

  const handleQuantityChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.purreqDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const rateValue = item.rate || 0; // Get the current rate value
        const amount = (value || 0) * rateValue; // Calculate amount with updated quantity

        const rate1 = calculateFinalRate(
          rateValue,
          item.discount_on,
          item.discount,
          item.dis_type
        );

        const amount2 = calculateNetAmount(
          amount,
          item.discount,
          item.dis_type,
          item.discount_on,
          value || 0, // Use the new quantity
          rate1
        );

        return {
          ...item,
          total_qty: value,
          amount: amount,
          rate1: rate1,
          amount2: amount2,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      purreqDetail: newPurreqDetail,
    }));
  };

  const handleSelectChange = (value, uniqueCode, field) => {
    const newPurreqDetail = allData.purreqDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const updatedItem = {
          ...item,
          [field]: value,
        };
        // console.log(updatedItem, "upppppppppppppppppppppppppp");
        // Recalculate finalRate and netAmount after select change
        const rate1 = calculateFinalRate(
          updatedItem.rate,
          updatedItem.discount_on,
          updatedItem.discount,
          updatedItem.dis_type
        );
        const amount2 = calculateNetAmount(
          updatedItem.amount,
          updatedItem.discount,
          updatedItem.dis_type,
          updatedItem.discount_on,
          updatedItem.total_qty,
          rate1
        );

        return {
          ...updatedItem,
          rate1: rate1,
          amount2: amount2,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      purreqDetail: newPurreqDetail,
    }));
  };

  const handleDiscountChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.purreqDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const amount = item.rate * item.total_qty;
        const rate1 = calculateFinalRate(
          item.rate,
          item.discount_on,
          value,
          item.dis_type
        );
        const amount2 = calculateNetAmount(
          amount,
          value,
          item.dis_type,
          item.discount_on,
          item.total_qty,
          rate1
        );

        return {
          ...item,
          discount: value,
          amount2: amount2,
          rate1: rate1,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      purreqDetail: newPurreqDetail,
    }));
  };

  const handleSave = (event) => {
    var formv = true;

    identifiers.forEach((val) => {
      let count = 0;
      allData[val].forEach((data) => {
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }

          count = count + 1;
        }
      });
    });

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
        purreqDetail: allData.purreqDetail.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);
      // Add validation logic here
      // if (
      //   allData.poHdr[0].deal_date == null ||
      //   allData.poHdr[0].deal_type_cd == null ||
      //   allData.poHdr[0].currency_cd == null ||
      //   allData.poHdr[0].currency_rate1 == null ||
      //   allData.poHdr[0].party_code == null ||
      //   allData.poHdr[0].po_type1 == null ||
      //   allData.poHdr[0].po_ref == null ||
      //   allData.poHdr[0].type == null
      // )

      // {
      //   message.error({
      //     content: "Please Fill The Mandatory Fields!!!",
      //     className: "custom-class",
      //     style: {
      //       marginTop: "1vh",
      //     },
      //   });
      //   setLoading(false);
      //   return; // Stop further execution if validation fails
      // }

      let missingFields = [];

      if (allData.poHdr[0].deal_date == null) {
        missingFields.push("Deal Date");
      }
      if (allData.poHdr[0].deal_type_cd == null) {
        missingFields.push("Deal Type");
      }
      if (allData.poHdr[0].currency_cd == null) {
        missingFields.push("Currency ");
      }
      if (allData.poHdr[0].currency_rate1 == null) {
        missingFields.push("Currency Rate");
      }
      if (allData.poHdr[0].party_code == null) {
        missingFields.push("Party");
      }
      if (allData.poHdr[0].po_type1 == null) {
        missingFields.push("PO Type");
      }
      if (allData.poHdr[0].po_ref == null) {
        missingFields.push("PO Reference");
      }
      if (allData.poHdr[0].type == null) {
        missingFields.push(" Po Type");
      }

      // If there are missing fields, display an error message
      if (missingFields.length > 0) {
        message.error({
          content: `Please Fill The Mandatory Fields: ${missingFields.join(
            ", "
          )}!!!`,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if validation fails
      }

      const allFieldsNull = allData.purreqDetail.every(
        (detail) =>
          detail.item_code === null &&
          detail.uom_code === null &&
          detail.size_code === null &&
          detail.quality_code === null &&
          detail.total_qty === null &&
          detail.hsn === null &&
          detail.rate === null &&
          detail.amount === null
      );

      if (allFieldsNull) {
        message.error({
          content: "Please Fill At Least One Row In The Item Detail Tab!!!",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if all fields are null
      }

      //////////////////////with level
      const fieldDisplayNameMap = {
        item_code: "Item",
        uom_code: "Uom",
        size_code: "Size",
        quality_code: "Grade",
        total_qty: "Quantity",
        hsn: "HSN",

        rate: "Basic Rate",
        amount: "Amount",
      };

      const invalidRows = allData.purreqDetail.filter((detail, index) => {
        const mandatoryFields = [
          "item_code",
          "uom_code",
          "size_code",
          "quality_code",

          "hsn",
          "total_qty",

          "rate",
          "amount",
        ];
        const missingFields = mandatoryFields.filter(
          (field) =>
            detail[field] === null ||
            detail[field] === undefined ||
            detail[field] === ""
        );
        if (missingFields.length > 0) {
          // Map field names to display names
          const displayNames = missingFields.map(
            (field) => fieldDisplayNameMap[field]
          );
          // Construct error message for this row
          const errorMessage = `Row ${
            index + 1
          }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
          // Display error message
          message.error({
            content: errorMessage,
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });

          setLoading(false);
          return true;
        }
        return false;
      });

      if (invalidRows.length > 0) {
        return; // Stop further execution if validation fails
      }

      // Move this line outside of the filter function
      if (!postData.chargedata) {
        // Display error message
        // alert("Please calculate tax in the tax detail tab.");
        message.error({
          content: "Please calculate tax in the tax detail tab.!!!",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return;
        // Prevent further execution
      }

      axios
        .post(employeeData.URL + "/api/v1/purchaseorder", postData, {
          withCredentials: true,
          credentials: "include",
        })

        .then((response) => {
          if (response.data.status === "fail") {
            message.error({
              content: response.data.message,
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });

            setLoading(false);
          } else {
            message.success({
              content: "Purchase Order Create Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/purchase/transaction/purchase-order");
          }
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/purchase/transaction/purchase-order");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col
            lg={24}
            md={24}
            className={classes["Col"]}
            // style={{ border: "2px solid red" }}
          >
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Purchase Order
                </span>

                <BasicDetail
                  create={true}
                  ad={ad}
                  netQty={netQty}
                  netAmount={netAmount}
                  setData={setAllData}
                  data={allData.poHdr[0]}
                  data1={allData.purreqDetail}
                  editMode={editMode}
                  onReqCodeChange={handleReqCodeChange}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
                // tabBarGutter="10"
                // id="tabs"
                // tabBarUnderlineStyle={{ borderWidth: 0 }} // Remove border from the tab bar
              >
                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Details
                    </span>
                  }
                  key="0"
                  // disabled={!isItemDetailVisible}
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {allData.poHdr[0].type === "w" &&
                    allData.poHdr[0].req_code === null ? (
                      <SizeDetail
                        ad={ad}
                        setData={setAllData}
                        data={allData.purreqDetail}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        sizeRef={sizeRef}
                        disableItemSelection={allData.poHdr[0].type === "wr"}
                      />
                    ) : (
                      <DummyTable
                        data={allData.purreqDetail}
                        onInputChange={handleInputChange}
                        onSelectChange={handleSelectChange}
                        onDiscountChange={handleDiscountChange}
                        onQuantityChage={handleQuantityChange}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Tax Details
                    </span>
                  }
                  key="1"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px", // Adjust the value as needed
                    marginBottom: "16px", // Adjust the value as needed,
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    <TaxDetail
                      create={true}
                      ad={ad}
                      setData={setAllData}
                      editMode={editMode}
                      netQty={netQty}
                      netAmount={netAmount}
                      setQty={setNetQty}
                      setAmount={setNetAmount}
                      editTode={editTode}
                      setEditTode={setEditTode}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RequisitionNew;
