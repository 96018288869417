// import React, { createContext, useState, useEffect } from "react";
// import runChat, { getRandomAvatar } from "../config/gemini";

// export const Context = createContext();

// const ContextProvider = (props) => {
//   const [input, setInput] = useState("");
//   const [recentPrompt, setRecentPrompt] = useState("");
//   const [showResult, setShowResult] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [resultData, setResultData] = useState("");
//   const [conversationHistory, setConversationHistory] = useState([]);
//   const [isDatabaseMode, setIsDatabaseMode] = useState(false);
//   const [prevPrompts, setPrevPrompts] = useState([]);
//   const [selectedAvatar, setSelectedAvatar] = useState(getRandomAvatar());

//   useEffect(() => {
//     // console.log("Updated History:", conversationHistory);
//   }, [conversationHistory]);

//   const delayPara = (index, nextWord) => {
//     setTimeout(() => {
//       setResultData((prev) => prev + nextWord);
//     }, 75 * index);
//   };

//   const newChat = () => {
//     setLoading(false);
//     setShowResult(false);
//     setResultData("");
//     setConversationHistory([]);
//     setPrevPrompts([]);
//     setSelectedAvatar(getRandomAvatar());
//     setRecentPrompt("");
//   };

//   const toggleDatabaseMode = () => {
//     setIsDatabaseMode((prevMode) => !prevMode);
//   };

//   const formatResponse = (response) => {
//     let formattedResponse = response.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
//     formattedResponse = formattedResponse.replace(/\*/g, "<br/>");

//     return formattedResponse;
//   };

//   const onSent = async (prompt = input) => {
//     setResultData("");
//     setLoading(true);
//     setShowResult(true);
//     setRecentPrompt(prompt);

//     try {
//       const responseObj = await runChat(
//         prompt,
//         conversationHistory,
//         isDatabaseMode,
//         selectedAvatar
//       );
//       const response = responseObj?.response || "";
//       setIsDatabaseMode(responseObj.isDatabaseMode);

//       if (response) {
//         const formattedResponse = formatResponse(response);
//         const newEntries = [
//           { role: "user", parts: [{ text: prompt }] },
//           { role: "model", parts: [{ text: formattedResponse }] },
//         ];

//         setConversationHistory((prevHistory) => [
//           ...prevHistory,
//           ...newEntries,
//         ]);
//         setPrevPrompts((prevPrompts) => [...prevPrompts, prompt]);

//         let responseArray = response.split("**");
//         let newResponse = "";
//         for (let i = 0; i < responseArray.length; i++) {
//           if (i === 0 || i % 2 !== 1) {
//             newResponse += responseArray[i];
//           } else {
//             newResponse += "<b>" + responseArray[i] + "</b>";
//           }
//         }
//         let newResponse2 = newResponse.split("*").join("</br>");
//         let newResponseArray = newResponse2.split(" ");
//         for (let i = 0; i < newResponseArray.length; i++) {
//           const nextWord = newResponseArray[i];
//           delayPara(i, nextWord + " ");
//         }
//       } else {
//         setResultData("No response was generated. Please try again.");
//       }
//     } catch (error) {
//       console.error("Error in onSent:", error);
//       setResultData(
//         "Resource exhausted or error occurred while generating the response. Please try again."
//       );
//     } finally {
//       setLoading(false);
//       setInput("");
//     }
//   };

//   const contextValue = {
//     onSent,
//     setRecentPrompt,
//     recentPrompt,
//     showResult,
//     loading,
//     resultData,
//     input,
//     setInput,
//     newChat,
//     conversationHistory,
//     setConversationHistory,
//     isDatabaseMode,
//     prevPrompts,
//     setPrevPrompts,
//     toggleDatabaseMode,
//     selectedAvatar,
//   };

//   return (
//     <Context.Provider value={contextValue}>{props.children}</Context.Provider>
//   );
// };

// export default ContextProvider;


import React, { createContext, useState, useEffect } from "react";
import runChat, { getRandomAvatar } from "../config/gemini"; 

export const Context = createContext();

const ContextProvider = (props) => {
  const [input, setInput] = useState("");
  const [recentPrompt, setRecentPrompt] = useState("");
  const [showResult, setShowResult] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resultData, setResultData] = useState("");
  const [conversationHistory, setConversationHistory] = useState([]);
  const [isDatabaseMode, setIsDatabaseMode] = useState(false);
  const [prevPrompts, setPrevPrompts] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState(getRandomAvatar()); 

  useEffect(() => {
    // console.log("Updated History:", conversationHistory);
  }, [conversationHistory]);

  const delayPara = (index, nextWord) => {
    setTimeout(() => {
      setResultData((prev) => prev + nextWord);
    }, 75 * index);
  };

  const newChat = () => {
    setLoading(false);
    setShowResult(false);
    setResultData("");
    setConversationHistory([]);
    setPrevPrompts([]);
    setSelectedAvatar(getRandomAvatar());
    setRecentPrompt("");
  };

  const toggleDatabaseMode = () => {
    setIsDatabaseMode((prevMode) => !prevMode);
  };

  const formatResponse = (response) => {
    let formattedResponse = response.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    formattedResponse = formattedResponse.replace(/\*/g, "<br/>");
    
    return formattedResponse;
  };

  const onSent = async (prompt = input) => {
    setResultData("");
    setLoading(true);
    setShowResult(true);
    setRecentPrompt(prompt);

    try {
      const responseObj = await runChat(prompt, conversationHistory, isDatabaseMode, selectedAvatar);
      const response = responseObj?.response || ""; 
      setIsDatabaseMode(responseObj.isDatabaseMode);

      if (response) {
        const formattedResponse = formatResponse(response);
        const newEntries = [
          { role: "user", parts: [{ text: prompt }] },
          { role: "model", parts: [{ text: formattedResponse }] },
        ];

        setConversationHistory((prevHistory) => [...prevHistory, ...newEntries]);
        setPrevPrompts((prevPrompts) => [...prevPrompts, prompt]);

        let responseArray = response.split("**");
        let newResponse = "";
        for (let i = 0; i < responseArray.length; i++) {
          if (i === 0 || i % 2 !== 1) {
            newResponse += responseArray[i];
          } else {
            newResponse += "<b>" + responseArray[i] + "</b>";
          }
        }
        let newResponse2 = newResponse.split("*").join("</br>");
        let newResponseArray = newResponse2.split(" ");
        for (let i = 0; i < newResponseArray.length; i++) {
          const nextWord = newResponseArray[i];
          delayPara(i, nextWord + " ");
        }
      } else {
        setResultData("No response was generated. Please try again.");
      }
    } catch (error) {
      console.error("Error in onSent:", error);
      setResultData("Resource exhausted or error occurred while generating the response. Please try again.");
    } finally {
      setLoading(false);
      setInput(""); 
    }
  };

  const contextValue = {
    onSent,
    setRecentPrompt,
    recentPrompt,
    showResult,
    loading,
    resultData,
    input,
    setInput,
    newChat,
    conversationHistory,
    setConversationHistory,
    isDatabaseMode,
    prevPrompts,
    setPrevPrompts,
    toggleDatabaseMode,
    selectedAvatar,
  };

  return (
    <Context.Provider value={contextValue}>
      {props.children}
    </Context.Provider>
  );
};

export default ContextProvider;