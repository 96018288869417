import React from "react";
import { Row, InputNumber } from "antd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";

const AdminTable = (props) => {
  const [data, setData] = useState(props.sizeData.custdetail);

  const onInputChange = (value, index) => {
    const updatedRows = data.rows.map((row, i) => {
      if (i === index) {
        const returnAmount = value * (row.rate1 || 0); // Use rate1 to calculate returnAmount
        return {
          ...row,
          return_qty: value,
          returnAmount, // Update returnAmount
        };
      }
      return row;
    });

    props.setMata((prevData) => ({
      ...prevData,
      DailyProductionDetail: updatedRows,
    }));

    setData((prevData) => ({
      ...prevData,
      rows: updatedRows,
    }));
  };

  const columns = [
    { key: "14", title: "Item", dataIndex: "item_name" },
    { key: "16", title: "uom", dataIndex: "uom" },
    { key: "15", title: "Size", dataIndex: "size_name" },
    { key: "17", title: "Grade", dataIndex: "grade" },
    { key: "27", title: "Rate", dataIndex: "rate1" },
    { key: "34", title: "Invoice Qty.", dataIndex: "total_qty" },
    {
      key: "28",
      title: "Return Qty",
      dataIndex: "return_qty",
      editable: true,
      render: (text, record, index) => (
        <InputNumber
          value={text}
          onChange={(e) => onInputChange(e, index)}
          style={{ textAlign: "left" }}
        />
      ),
    },
    { key: "24", title: "uniq", dataIndex: "uniq_code" },
    { key: "25", title: "Invoice Amount", dataIndex: "amount2" },
    {
      title: "Return Amount",
      dataIndex: "returnAmount",
      key: "returnAmount",
      render: (text, record) => {
        const returnAmount = (record.return_qty || 0) * (record.rate1 || 0); // Calculate returnAmount dynamically
        return returnAmount.toFixed(2); // Ensure proper formatting
      },
    },
  ];

  return (
    <Row>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.key}
                  style={{
                    color: "#071E54",
                    fontWeight: "bold",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {column.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.rows.map((record, index) => (
              <TableRow key={record.id}>
                {columns.map((column) => (
                  <TableCell key={column.key} style={{ whiteSpace: "nowrap" }}>
                    {column.render
                      ? column.render(record[column.dataIndex], record, index)
                      : record[column.dataIndex]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Row>
  );
};

export default AdminTable;
