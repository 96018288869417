import { Row, Col, message, Skeleton, Tooltip, Button } from "antd";
import { Link } from "react-router-dom";
import { Modal } from "antd";
import PropTypes from "prop-types";
import { BsFillArrowRightSquareFill } from "react-icons/bs";
import { useState, useEffect, useContext } from "react";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import axios from "axios";
import dayjs from "dayjs";
import EmployeeTable from "../EmployeeTable/EmployeeTable";
import AdminTable from "./AdminTable";
import ReqCodeModal from "./ReqcodeModel"; // Import the modal component
import CustomerModal from "./CustomerItem"; // Import the modal component

const BasicDetail = (props) => {
  // // console.log("props in basic", props);
  const [modalData, setModalData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState(null);
  const [itemdata, setItemdata] = useState(null);
  const [codalData, setCodalData] = useState([]);
  const [isModalForApiVisible, setIsModalForApiVisible] = useState(false);

  const openModal = (loadId) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleModalRowClick = async (record) => {
    // props.setEditMode1(false);
    // console.log("Selected Row:", record);

    // Construct the query parameters
    const params = {
      req_code: record.req_code,
    };

    try {
      // Send the GET request with the query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/dailyIssue/additional-data-of-detail`,
        { params, withCredentials: true }
      );

      console.log("API Response:", response.data.data.req2.rows);
      // Check if the response contains the expected data
      // const requisitionRows = response.data.data.Requisition.rows;

      // Optionally handle the response data here

      // Update the state with the selected value
      props.setData((data) => {
        const newdata = [...data["issueHdr"]];
        newdata[0]["req_code"] = record.req_code;
        newdata[0]["dept_code"] = record.dept_cd;
        newdata[0]["emp_cd"] = record.emp_cd;

        // newdata[0]["distributor_code"] = custCode;
        // Call the handleDealerChange function after setting dealer_name

        return {
          ...data,
          issueHdr: newdata,
          issueDetail: response.data.data.req2.rows,
        };
      });
    } catch (error) {
      console.error("Error fetching additional data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  // // // console.log(props.data.req_type, "basic");

  const handleButtonClick = async () => {
    try {
      console.log(props.data);
      // Destructure the required values from props
      if (!props.data.issue_date) {
        message.error("Please select the issue date first.");
        return; // Exit the function if issue_date is not set
      }

      // Make the API call, passing dealercode and customer_code as query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/dailyIssue/additional-data-of-req/`,
        {
          params: {
            issue_date: props.data.issue_date, // Pass issue_date as a query parameter
          },
          withCredentials: true,
        }
      );

      // Log the API response for debugging
      console.log("API Response:", response.data.data.AllReq.rows);

      // Update the modal data state with the API response
      setCodalData(response.data.data.AllReq.rows);

      // Show the modal
      setIsModalForApiVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleApiModalRowClick = async (record) => {
    console.log(record);
    props.setData((data) => {
      return {
        ...data,

        issueDetail: [...data.issueDetail, record],
      };
    });
  };

  const handleChange = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["issueHdr"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        issueHdr: newdata,
      };
    });
  };

  const handleReqCodeClick = async () => {
    try {
      console.log(props.data.issue_date);
      // Check if issue_date is null or undefined
      if (!props.data.issue_date) {
        message.error("Please select the issue date first.");
        return; // Exit the function if issue_date is not set
      }

      // Make the API call when Req Code is clicked
      const response = await axios.get(
        `http://sastetab.com/api/v1/dailyIssue/additional-data-of-req/`,
        {
          params: {
            issue_date: props.data.issue_date, // Pass issue_date as a query parameter
          },
          withCredentials: true,
        }
      );

      console.log("API Response:", response.data.data.AllReq.rows);

      // Update the modal data state with the API response
      setModalData(response.data.data.AllReq.rows);

      // Show the modal
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleSChange = (val, param) => {
    console.log(val, "Vallllllllllllll");
    console.log(param, "Parammmmmmmmmmmmmmm");
    // if (val == "wr") {
    //   // // console.log(document.getElementById("table_order"));
    //   document.getElementById("table_order").style.display = "none";
    //   // document.getElementById("tabpane").style.display = "none";
    // }

    // if (val == "wour") {
    //   // If val is not "wr", show the table_order element
    //   document.getElementById("table_order").style.display = "block"; // Assuming you want to set it to block display
    //   // document.getElementById("tabpane").style.display = "block";
    // }

    if (param === "req_type") {
      // Handle type change
      props.setData((data) => {
        const newdata = [...data["issueHdr"]];
        newdata[0][param] = val;
        console.log(val === "wour" && data.issueHdr[0].req_code);
        // If the new type is "w", set req_code to null
        if (val === "wour" && data.issueHdr[0].req_code) {
          newdata[0]["req_code"] = null;
        }

        return {
          ...data,
          issueHdr: newdata,
          issueDetail: [
            {
              item_code: null,
              uom_code: null,
              size_code: null,
              quality_code: null,
              no_of_pcs: null,
              cost_code: null,
              actual_bal: null,
              qty: null,
              rate: null,
            },
          ],
        };
      });
    } else {
      props.setData((data) => {
        const newdata = [...data["issueHdr"]];
        newdata[0][param] = val;
        return {
          ...data,
          issueHdr: newdata,
        };
      });
    }
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["issueHdr"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        issueHdr: newdata,
      };
    });
  };

  const handleTChange = (time, timeString, param) => {
    props.setData((data) => {
      const newdata = [...data["issueHdr"]];
      // Assuming `timeString` is in the format "HH:mm:ss"
      newdata[0][param] = timeString;
      return {
        ...data,
        issueHdr: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            name="Issue Code"
            param="issue_code"
            value={props.data.issue_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.REQ_TYPE}
          type="Select"
          name="Requisition Type"
          required="True"
          param="req_type"
          value={props.data.req_type}
          editDisable={props.editDisable}
        />

        {props.data.req_type !== "wour" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            onClick={handleReqCodeClick}
            // type="Date"
            id="req_code"
            // required="True"
            name="Requisition Code"
            param="req_code"
            value={props.data.req_code}
            temp={props.data.req_type}
            // onClick={props.data.req_type === "wr" ?  : null}
            rules={[{ message: "missing name" }]}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          id="issue_date"
          required="True"
          name="Issue Date"
          param="issue_date"
          value={props.data.issue_date}
          rules={[{ message: "missing name" }]}
        />

        {/* <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleTChange}
          type="Time"
          id="Req_time"
          required="True"
          name="REQUISITION TIME"
          param="Req_time"
          value={props.data.Req_time}
          rules={[{ message: "missing name" }]}
        /> */}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.DEPT_CODE}
          required="True"
          type="Select"
          name="Department"
          param="dept_code"
          value={props.data.dept_code}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.EMPLOYEE_CODE}
          required="True"
          type="Select"
          name="Requested By"
          param="emp_cd"
          value={props.data.emp_cd}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.D_CODE}
          type="Select"
          name="Division Code"
          param="d_code"
          value={props.data.d_code}
        />
        {props.data.req_type !== "wour" && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{ marginLeft: "15px", marginTop: "30px" }}
          >
            Add item
          </Button>
        )}
      </Row>

      <ReqCodeModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onRowClick={handleModalRowClick} // Function to handle when a row in the modal is clicked
        data={modalData} // Pass the data to be displayed in the modal
      />

      <CustomerModal
        title="API Data Modal"
        visible={isModalForApiVisible}
        onRowClick={handleApiModalRowClick}
        onClose={() => setIsModalForApiVisible(false)}
        // onCancel={handleApiModalClose}
        data1={codalData}
      />
    </div>
  );
};

export default BasicDetail;
