import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  Input,
  Button,
  Form,
  DatePicker,
  message,
  Select,
  Modal,
  Row,
  Col,
} from "antd";
import "antd/dist/reset.css";
import dayjs from "dayjs"; // Importing dayjs
import axios from "axios";

const getCurrentDate = () => {
  return dayjs().format("DD-MM-YYYY"); // Use dayjs to format the date
};

const CreatePacket = () => {
  const location = useLocation();
  const [form] = Form.useForm();

  const initialFormData = location.state || {
    packingDate: "",
    orderCode: "",
    dealer: "",
    dealerCode: "",
    customer: "",
    customerCode: "",
    packingIncharge: "",
    marking: "",
    truckNo: "",
    weighbridgeWt: "",
    items: [
      {
        orderNo: "",
        itemCode: "",
        itemName: "",
        sizeCode: "",
        size: "",
        quality: "",
        grade: "",
        pcs: "",
        orderQty: "",
        packingQty: "",
        avlStock: "",
        remarks: "",
      },
    ],
  };

  const [showModal, setShowModal] = useState(false);
  const [packingDate, setPackingDate] = useState(dayjs(getCurrentDate())); // Use dayjs for date handling
  const [orderData, setOrderData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [bookingCode, setBookingCode] = useState("");
  const [packingIncharges, setPackingIncharges] = useState([]);
  const [selectedIncharge, setSelectedIncharge] = useState("");
  const [tableData, setTableData] = useState([]);
  const navigate = useNavigate();
  const [finalData, setFinalData] = useState(null);
  const [formData, setFormData] = useState({
    packingDate: "",
    orderCode: "",
    dealer: "",
    dealerCode: "",
    customer: "",
    customerCode: "",
    packingIncharge: "",
    marking: "",
    truckNo: "",
    weighbridgeWt: "",
  });

  useEffect(() => {
    form.setFieldsValue(formData); // Initialize form values with formData
  }, [formData]);

  useEffect(() => {
    const fetchOrderData = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/packdata/packorderdat",
          {
            withCredentials: true,
          }
        );

        const fetchedData = response.data.data;
        // console.log(fetchedData, "1234567890");
        if (Array.isArray(fetchedData)) {
          setOrderData(fetchedData);
        } else {
          console.error("Expected array but received:", fetchedData);
          message.error("Unexpected data format received from the server");
        }
      } catch (error) {
        console.error("Error fetching order data:", error);
        message.error("Failed to fetch order data");
      }
    };

    fetchOrderData();
  }, []);

  useEffect(() => {
    const fetchPackingIncharges = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/packdata/packdat",
          {
            withCredentials: true,
          }
        );

        const fetchedData = response.data.data.empname;
        // console.log(fetchedData, "Packing Incharges");

        if (Array.isArray(fetchedData)) {
          // Map the fetched data to be used in Select component
          const incharges = fetchedData.map((item) => ({
            id: item.employee_code, // Use employee_code as the value
            name: item.employee_name, // Use employee_name to display
          }));
          setPackingIncharges(incharges);
        } else {
          console.error("Expected array but received:", fetchedData);
          message.error("Unexpected data format received from the server");
        }
      } catch (error) {
        console.error("Error fetching packing incharges:", error);
        message.error("Failed to fetch packing incharges");
      }
    };

    fetchPackingIncharges();
  }, []);

  // useEffect(() => {
  //   const fetchTableData = async () => {
  //     if (bookingCode) {
  //       try {
  //         // console.log(`Fetching data for booking code: ${bookingCode}`); // Debugging log
  //         const response = await axios.get(
  //           `http://sastetab.com/api/v1/packdata/packtabdat/${bookingCode}`,
  //           {
  //             withCredentials: true,
  //           }

  //         // console.log(`Fetching data for booking code: ${bookingCode}`); // Debugging log
  //         // const response = await axios.get(
  //         //   `http://sastetab.com/api/v1/packdata/packtabdat/${bookingCode}`,
  //         //   {
  //         //     withCredentials: true,
  //         //   }
  //         // );
  //         )

  //     }

  // }, [bookingCode]);

  useEffect(() => {
    setFinalData({
      ...formData,
      tableData: tableData,
    });
  }, [formData, tableData]);

  const handleRowClick = (row) => {
    setBookingCode(row.booking_code);
    setSelectedRow(row);
    setFormData({
      ...formData,
      orderCode: row.booking_code,
      dealer: row.get_dealer_name,
      dealerCode: row.dealer_name,
      customer: row.get_distributor,
      customerCode: row.distributor_code,
    });
    setShowModal(false);
  };

  const handleSave = async () => {
    try {
      const payload = {
        ...formData,
        tableData: tableData,
      };

      // console.log(formData, "sdshdhdhjdhsjhjdshdjshdsjdh1121212121212121");
      const response = await axios.post(
        "http://sastetab.com/api/v1/packdata/saved",
        payload,
        {
          withCredentials: true,
        }
      );

      if (!response.data || response.status !== 201) {
        throw new Error("Failed to save packing data");
      }

      message.success("Packing data saved successfully");
      navigate("/view/packing");
    } catch (error) {
      message.error("Failed to save packing data");
      console.error("Error saving data:", error);
    }
  };

  const columns = [
    { title: "Order No", dataIndex: "booking_code", key: "orderNo" },
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Size", dataIndex: "sz", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Pcs", dataIndex: "no_pcs", key: "pcs" },
    { title: "Order Qty", dataIndex: "qty", key: "orderQty" },
    {
      title: "Packing Qty",
      dataIndex: "packingQty",
      key: "packingQty",
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => {
            const newData = [...tableData];
            newData[index].packingQty = e.target.value;
            setTableData(newData);
          }}
        />
      ),
    },
    {
      title: "AVL Stock",
      dataIndex: "pending_wt",
      key: "avlStock",
      render: (text) => <Input value={text} readOnly />, // Assuming this field is readonly
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) => {
            const newData = [...tableData];
            newData[index].remarks = e.target.value;
            setTableData(newData);
          }}
        />
      ),
    },
  ];

  return (
    <div className="packing-format">
      <h1>Packing Format</h1>
      <Form
        form={form}
        layout="vertical"
        onValuesChange={(changedValues) => {
          setFormData({
            ...formData,
            ...changedValues,
          });
        }}
      >
        <Row gutter={16}>
          {/* <Col span={6}>
            <Form.Item label="Packing Code">
              <Input placeholder="Auto generated" readOnly />
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item
              label="Packing Date"
              required
              rules={[{ required: true, message: "Please input this field!" }]}
            >
              <DatePicker
                format="DD-MM-YYYY"
                value={packingDate}
                style={{ width: "100%" }}
                onChange={(date) => {
                  setPackingDate(date);
                  setFormData((prev) => ({
                    ...prev,
                    packingDate: date.format("DD-MM-YYYY"),
                  }));
                }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Order Code"
              required
              rules={[
                { required: true, message: "Please input order code field!" },
              ]}
            >
              <Input
                value={formData.orderCode}
                onClick={() => setShowModal(true)}
                readOnly
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item label="Dealer">
              <Input value={formData.dealer} readOnly />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item label="Dealer Code">
              <Input value={formData.dealerCode} readOnly />
            </Form.Item>
          </Col> */}
          <Col span={6}>
            <Form.Item label="Customer">
              <Input value={formData.customer} readOnly />
            </Form.Item>
          </Col>
          {/* <Col span={6}>
            <Form.Item label="Customer Code">
              <Input value={formData.customerCode} readOnly />
            </Form.Item>
          </Col> */}
        </Row>

        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Packing Incharge">
              <Select
                value={formData.packingIncharge}
                onChange={(value) =>
                  setFormData((prev) => ({ ...prev, packingIncharge: value }))
                }
              >
                {packingIncharges.map((incharge) => (
                  <Select.Option key={incharge.id} value={incharge.id}>
                    {incharge.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Marking">
              <Input
                value={formData.marking}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, marking: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Truck No">
              <Input
                value={formData.truckNo}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, truckNo: e.target.value }))
                }
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label="Weighbridge WT">
              <Input
                value={formData.weighbridgeWt}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    weighbridgeWt: e.target.value,
                  }))
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={tableData}
          rowKey="booking_code"
          pagination={false}
        />

        <Row justify="end" style={{ marginTop: "16px" }}>
          <Col>
            <Button type="primary" onClick={handleSave}>
              Save
            </Button>
          </Col>
        </Row>
      </Form>

      <Modal
        title="Select Order"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        maskClosable={false}
      >
        <Table
          dataSource={orderData}
          columns={[
            {
              title: "Booking Code",
              dataIndex: "booking_code",
              key: "booking_code",
            },
            {
              title: "Customer",
              dataIndex: "get_distributor",
              key: "get_distributor",
            },
            {
              title: "Dealer",
              dataIndex: "get_dealer_name",
              key: "get_dealer_name",
            },
          ]}
          rowKey="booking_code"
          onRow={(record) => ({
            onClick: () => handleRowClick(record),
          })}
        />
      </Modal>
    </div>
  );
};

export default CreatePacket;
