import { Row } from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
import axios from "axios";
import React from "react";
import { useState, useEffect, useContext } from "react";

const BasicDetail = (props) => {
  // console.log(props);
  // console.log(props);
  const {
    create,
    ad,
    setData,
    data,
    editMode,
    invoiceData,
    setInvoiceData,
    viewinvoice,
    vad,
    noedit,
  } = props;
  // // console.log(invoiceData);
  // Debugging: Log invoiceData whenever it changes
  useEffect(() => {
    // console.log("Updated invoiceData:", invoiceData);
  }, [invoiceData]);
  // console.log(vad);

  const [invoiceOptions, setInvoiceOptions] = useState([]);

  // Update invoiceOptions when invoiceData changes
  useEffect(() => {
    // console.log("Effect run, invoiceData:", invoiceData);
    // console.log("Effect run, invoiceData:", invoiceData);

    const ong3Rows = invoiceData?.ONGATE3;
    const opt = ong3Rows;
    setInvoiceOptions(opt);
  }, [invoiceData]);

  const handleChange = (e, param) => {
    props.setData((data) => {
      // console.log(param);
      const newdata = [...data["BevHeader"]];
      newdata[0][param] = e.target.value;
      // Calculate net_wt if both in_wt and out_wt are available
      if (param === "in_wt" || param === "out_wt") {
        const inWt = param === "in_wt" ? e.target.value : newdata[0]["in_wt"];
        const outWt =
          param === "out_wt" ? e.target.value : newdata[0]["out_wt"];

        if (inWt && outWt) {
          newdata[0]["net_wt"] = parseFloat(inWt) - parseFloat(outWt);
        }
      }

      //  // console.log(newdata);
      return {
        ...data,
        BevHeader: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    // console.log(val);

    if (param === "wb_type") {
      props.setData((data) => {
        const newdata = [...data["BevHeader"]];
        newdata[0].gate_no = null;
        newdata[0].invoice_code = null;
        newdata[0].cust_party_type = null;
        newdata[0].cust_party_code = null;
        newdata[0].truck_no = null;
        // newdata[0].in_time = dayjs(new Date()).format("HH:mm:ss");
        newdata[0].out_time = null;
        newdata[0].in_wt = null;
        newdata[0].out_wt = null;
        newdata[0].rmk = null;
        newdata[0].net_wt = null;
        newdata[0].acid_wt = null;
        newdata[0].party_display_name = null;
        newdata[0].customer_display_name = null;

        return {
          ...data,
          BevHeader: newdata,
        };
      });
    }

    // let flag = props.flag;

    let gn = null;
    let Array1data = null;
    let filter1data = null;

    let Array2data = null;
    let filter2data = null;

    // let Array3data = null;
    // let filter3data = null;

    if (param === "gate_no") {
      gn = val;
      Array1data = props.ad.GATE1.rows;
      filter1data = Array1data.filter((row) => row.factory_arrival_code === gn);

      Array2data = props.ad.GATE2.rows;
      filter2data = Array2data.filter((row) => row.factory_arrival_code === gn);
    }

    if (param === "gate_no" && props.data.wb_type === "O") {
      // console.log("abcd");
      axios
        .get(`http://sastetab.com/api/v1/weighbridge/invoice-data/${gn}`, {
          withCredentials: true,
        })
        .then((response) => {
          console.log(response);
          props.setInvoiceData(response.data.data);
          // // console.log("abcd", invoiceData);
        })
        .catch((error) => {
          console.error("Error fetching customer list:", error);
        });
    }

    props.setData((data) => {
      const newdata = [...data["BevHeader"]];
      // // console.log(filter2data);

      if (param === "gate_no" && props.data.wb_type === "I") {
        newdata[0].party_display_name = filter1data[0].party_display_name;
        newdata[0].cust_party_type = filter1data[0].party_code;
        newdata[0].truck_no = filter1data[0].truck_no;
      } else if (param === "gate_no" && props.data.wb_type === "O") {
        newdata[0].truck_no = filter2data[0].truck_no;
      }

      if (param === "invoice_code" && props.data.wb_type === "O") {
        newdata[0].customer_display_name =
          invoiceData.GATE3.rows[0].customer_display_name;
        newdata[0].cust_party_code = invoiceData.GATE3.rows[0].entity_code;

        // console.log(invoiceData);
        // console.log(invoiceData);
        // console.log(invoiceData);
      }

      newdata[0][param] = val;
      return {
        ...data,
        BevHeader: newdata,
      };
    });
  };
  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["BevHeader"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        BevHeader: newdata,
      };
    });
  };

  const handleTChange = (time, timeString, param) => {
    props.setData((data) => {
      const newdata = [...data["BevHeader"]];
      // Assuming `timeString` is in the format "HH:mm:ss"
      newdata[0][param] = timeString;
      return {
        ...data,
        BevHeader: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="Weight Code"
            param="wt_code"
            value={props.data.wt_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          // options={props.ad.BEV_TYPE}
          options={editMode ? props.ad.BEV_TYPE : props.vad.BEV_TYPE}
          name="Weighbridge Type"
          param="wb_type"
          type="Select"
          required={true}
          value={props.data.wb_type}
          readOnly={editMode && props.noedit}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleDChange}
          type="Date"
          name="Date"
          param="dt"
          required={true}
          value={props.data.dt}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={
            props.editMode
              ? props.data.wb_type === "I"
                ? props.ad.ONGATE1
                : props.ad.ONGATE2
              : props.data.wb_type === "I"
              ? props.vad.VIEWONGATE1
              : props.vad.VIEWONGATE2
          }
          name="Gate Pass Number"
          param="gate_no"
          type="Select"
          required={true}
          value={props.data.gate_no}
        />
        {props.data.wb_type !== "I" && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleSChange}
            // options={props.invoiceData?.ONGATE3?.length ? props.invoiceData.ONGATE3 : [{value: "No Data", label: "No Data"}]}
            // options={editMode ? invoiceOptions: props.viewinvoice.ONGATE3}

            options={editMode ? invoiceOptions : props.viewinvoice.ONGATE3}
            name="Invoice Number"
            param="invoice_code"
            type="Select"
            // required={true}
            value={props.data.invoice_code}
          />
        )}

        {props.data.wb_type !== "O" && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            // options={props.ad.PARTY_NAME}
            name="Vendor"
            param="cust_party_type"
            type="Input"
            readOnly={true}
            value={props.data.party_display_name}
          />
        )}

        {props.data.wb_type !== "I" && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            // options={props.ad.CUST_NAME}
            name="Customer"
            param="cust_party_code"
            type="Input"
            readOnly={true}
            value={props.data.customer_display_name}
          />
        )}
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "ACCOUNT NAME" param = "account_name" value = {props.data.account_name}/>    */}
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Vechicle Number"
          type="Input"
          param="truck_no"
          required={true}
          readOnly={true}
          value={props.data.truck_no}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleTChange}
          type="Input"
          name="In Time"
          param="in_time"
          required={true}
          // disabled={true}
          value={props.data.in_time}
          readOnly={true}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleTChange}
          type="Time"
          name="Out Time"
          param="out_time"
          value={props.data.out_time}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          type="Input"
          name="In Weight"
          param="in_wt"
          required={true}
          value={props.data.in_wt}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Out Weight"
          param="out_wt"
          type="Input"
          // readOnly={true}
          value={props.data.out_wt}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Net Weight"
          param="net_wt"
          type="Input"
          value={props.data.net_wt}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          type="Input"
          name="Deduction Weight"
          param="acid_wt"
          value={props.data.acid_wt}
        />

        <DataField
          editMode={props.editMode}
          lg={24}
          md={24}
          handleChange={handleChange}
          name="Remark"
          type="Input"
          param="rmk"
          value={props.data.rmk}
        />
      </Row>
    </div>
  );
};

export default BasicDetail;
