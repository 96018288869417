import React from "react";
import { Form, Input, Select, Button } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import classes from "../Pages.module.css";
const { Option } = Select;

const VDetailPart = (props) => {
  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => ({
      ...data,
      VoucherDetail: allValues.VoucherDetail,
    }));
  };

  return props.editMode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="VoucherDetail" initialValue={props.data}>
        {(fields, { add, remove }, i) => (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ padding: "4px", width: "14%" }}>Entity Type</th>
                <th style={{ padding: "4px", width: "14%" }}>Account</th>
                <th style={{ padding: "4px", width: "14%" }}>Amount</th>
                <th style={{ padding: "4px", width: "14%" }}>Cost</th>
                <th style={{ padding: "4px", width: "14%" }}>Department</th>
                <th style={{ padding: "4px", width: "14%" }}>Budget</th>
                <th style={{ padding: "4px", width: "8%" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <tr key={key} style={{ height: "auto" }}>
                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "entry_type"]}
                      fieldKey={[fieldKey, "entry_type"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Entity Type"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.ENTRY_TYPE.rows.map((option) => (
                          <Option
                            key={option[props.ad.ENTRY_TYPE.fields[0].name]}
                            value={option[props.ad.ENTRY_TYPE.fields[0].name]}
                          >
                            {option[props.ad.ENTRY_TYPE.fields[1].name]}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "account_code"]}
                      fieldKey={[fieldKey, "account_code"]}
                      rules={[{ required: true, message: "Missing Name" }]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Account"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {props.ad.ACCOUNT_CODEH.rows.map((option) => (
                          <Option
                            key={option[props.ad.ACCOUNT_CODEH.fields[0].name]}
                            value={
                              option[props.ad.ACCOUNT_CODEH.fields[2].name]
                            }
                          >
                            {`${
                              option[props.ad.ACCOUNT_CODEH.fields[0].name]
                            } - ${
                              option[props.ad.ACCOUNT_CODEH.fields[1].name]
                            }`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "amount"]}
                      fieldKey={[fieldKey, "amount"]}
                      rules={[
                        {
                          required: true,
                          message: "Field should not be blank!",
                        },
                        {
                          type: "number",
                          transform: (value) => Number(value),
                          message: "Please enter only numeric value",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        type="number"
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Amount"
                      />
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "cost_code"]}
                      fieldKey={[fieldKey, "cost_code"]}
                      rules={[
                        {
                          required: true,
                          message: "Field should not be blank!",
                        },
                        {
                          type: "number",
                          transform: (value) => Number(value),
                          message: "Please enter only numeric value",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        type="number"
                        placeholder="Enter Cost Code"
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                      />
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "department_name"]}
                      fieldKey={[fieldKey, "department_name"]}
                      rules={[
                        {
                          required: true,
                          message: "Field should not be blank!",
                        },
                        {
                          type: "string",
                          message: "Please enter a valid value",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        placeholder="Select Department"
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        optionFilterProp="children"
                      >
                        {/* Add department options here */}
                        <Option value="account">Account</Option>
                        <Option value="purchase">Purchase</Option>
                        {/* Add more options as needed */}
                      </Select>
                    </Form.Item>
                  </td>

                  <td style={{ padding: "4px" }}>
                    <Form.Item
                      {...restField}
                      name={[name, "budget_code"]}
                      fieldKey={[fieldKey, "budget_code"]}
                      rules={[
                        {
                          required: true,
                          message: "Field should not be blank!",
                        },
                        {
                          type: "number",
                          transform: (value) => Number(value),
                          message: "Please enter only numeric value",
                        },
                      ]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        type="number"
                        style={{
                          width: "100%",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Amount"
                      />
                    </Form.Item>
                  </td>

                  {/* Icons for adding/removing rows */}
                  <td style={{ textAlign: "center", paddingTop: "10px" }}>
                    <MinusCircleOutlined
                      className={classes["Remove"]}
                      onClick={() => remove(name)}
                    />
                    &nbsp;
                    <PlusCircleOutlined
                      className={classes["Add"]}
                      onClick={() => {
                        add();
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </Form.List>
    </Form>
  ) : (
    props.data.map((data, index) => (
      // Display data in read-only mode
      // Add display logic for all fields here
      <>
        {/* Add more fields as needed */}
        <span>{data.entry_type}</span>
      </>
    ))
  );
};

export default VDetailPart;
