//import {Row} from 'antd';
import {
  Row,
  Col,
  Form,
  Input,
  Space,
  Button,
  DatePicker,
  Select,
  InputNumber,
} from "antd";
import classes from "../Pages.module.css";
import DataField from "./DataField";
//import BoonkigSizeEdit from './BookingSizeEdit';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import TaxNew from "./TaxNew";
//import { useState, useEffect, useContext} from 'react';
const { Option } = Select;

const ChargeType = (props) => {
  const [disabledRows, setDisabledRows] = useState([]);
  console.log(props, "yytytyt");

  const DisableFunction = (index, value) => {
    setDisabledRows((prevDisabled) => {
      // If charge_code is 1, add index to disabledRows; otherwise, remove it
      console.log(props.data[index]?.charge_code);
      if (value === 1) {
        return [...prevDisabled, index];
      } else {
        return prevDisabled.filter((i) => i !== index);
      }
    });
  };

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      return {
        ...data,
        taxDet: allValues.taxDet,
      };
    });
    // console.log( allValues.taxDet);
  };

  return props.editMode ? (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="taxDet" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                <Row gutter={8}>
                  <Col lg={2} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "charge_code"]}
                      fieldKey={[fieldKey, "charge_code"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            // className={classes["Label"]}
                          >
                            Charge Name <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        onChange={(value) => DisableFunction(index, value)}
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.CHARGE_CODE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.CHARGE_CODE.fields[0].name]}
                              value={
                                option[props.ad.CHARGE_CODE.fields[0].name]
                              }
                            >
                              {option[props.ad.CHARGE_CODE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                    {/* Log the charge_code for testing */}
                    {console.log(
                      "Charge Code at index",
                      index,
                      "is",
                      props.data[index]?.charge_code
                    )}
                  </Col>

                  <Col
                    lg={2}
                    md={24}
                    // style={{ display: "flex", flex: "0 0 7.5%" }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "charge_value"]}
                      fieldKey={[fieldKey, "charge_value"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              // padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Charge Val. <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                    >
                      <Input
                        style={{
                          width: "100%",
                          textAlign: "right",
                          backgroundColor: "white",
                          // color: "#1777C4",
                          // fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        bordered={true}
                        placeholder="Enter Tax Value"
                        type="number"
                        disabled={
                          String(props.data[index]?.charge_code) === "1"
                        }
                      />
                    </Form.Item>
                    {console.log(
                      "Charge Code at index",
                      index,
                      "is",
                      props.data[index]?.charge_code
                    )}
                  </Col>

                  <Col
                    lg={2}
                    md={24}
                    // style={{ display: "flex", flex: "0 0 7.5%" }}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "charge_type"]}
                      fieldKey={[fieldKey, "charge_type"]}
                      label={
                        name === 0 && (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className={classes["Label"]}
                          >
                            Charge Type <span style={{ color: "red" }}> *</span>
                          </div>
                        )
                      }
                      // rules={[{ required: true, message: "Missing Name" }]}
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                        disabled={
                          String(props.data[index]?.charge_code) === "1"
                        }
                      >
                        {props.ad.CHARGE_TYPE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.CHARGE_TYPE.fields[0].name]}
                              value={
                                option[props.ad.CHARGE_TYPE.fields[0].name]
                              }
                            >
                              {option[props.ad.CHARGE_TYPE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={3} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "charge_type_on"]}
                      fieldKey={[fieldKey, "charge_type_on"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            On Qty/Amt. <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        disabled={
                          String(props.data[index]?.charge_code) === "1"
                        }
                        optionFilterProp="children"
                      >
                        {props.ad.CHARGE_TYPE_ON.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={
                                option[props.ad.CHARGE_TYPE_ON.fields[0].name]
                              }
                              value={
                                option[props.ad.CHARGE_TYPE_ON.fields[0].name]
                              }
                            >
                              {option[props.ad.CHARGE_TYPE_ON.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={3} md={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "ref_charge"]}
                      fieldKey={[fieldKey, "ref_charge"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className={classes["Label"]}
                          >
                            Refered Charge{" "}
                            <span style={{ color: "red" }}>*</span>
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        disabled={
                          String(props.data[index]?.charge_code) === "1"
                        }
                        optionFilterProp="children"
                      >
                        {props.ad.REF_CHARGE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.REF_CHARGE.fields[0].name]}
                              value={option[props.ad.REF_CHARGE.fields[0].name]}
                            >
                              {option[props.ad.REF_CHARGE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={3} md={12}>
                    <Form.Item
                      {...restField}
                      name={[name, "ref_on"]}
                      fieldKey={[fieldKey, "ref_on"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className={classes["Label"]}
                          >
                            {" "}
                            On Running/Amount{" "}
                            <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.REF_ON.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.REF_ON.fields[0].name]}
                              value={option[props.ad.REF_ON.fields[0].name]}
                            >
                              {option[props.ad.REF_ON.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={2} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "use_for"]}
                      fieldKey={[fieldKey, "use_for"]}
                      label={
                        name == 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Used For <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.USE_FOR.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.USE_FOR.fields[0].name]}
                              value={option[props.ad.USE_FOR.fields[0].name]}
                            >
                              {option[props.ad.USE_FOR.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={3} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "deal_type"]}
                      fieldKey={[fieldKey, "deal_type"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Deal Type <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.DEAL_TYPE.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.DEAL_TYPE.fields[0].name]}
                              value={option[props.ad.DEAL_TYPE.fields[0].name]}
                            >
                              {option[props.ad.DEAL_TYPE.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={2} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "include_cost"]}
                      fieldKey={[fieldKey, "include_cost"]}
                      label={
                        name === 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                              whiteSpace: "nowrap",
                            }}
                            className={classes["Label"]}
                          >
                            Include In Cost
                          </div>
                        ) : null
                      }
                    >
                      <Select
                        bordered={true}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          float: "left",
                          backgroundColor: "white",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Select"
                        optionFilterProp="children"
                      >
                        {props.ad.INCLUDE_COST.rows.map((option) => {
                          return (
                            <Option
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.ad.INCLUDE_COST.fields[0].name]}
                              value={
                                option[props.ad.INCLUDE_COST.fields[0].name]
                              }
                            >
                              {option[props.ad.INCLUDE_COST.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col lg={1} md={24}>
                    {name !== 0 && (
                      <MinusCircleOutlined
                        className={classes["Remove"]}
                        onClick={() => remove(name)}
                      />
                    )}
                  </Col>

                  <Col lg={1} md={24}>
                    <PlusCircleOutlined
                      className={classes["Add"]}
                      onClick={() => {
                        add();
                      }}
                    />
                  </Col>
                </Row>
              ))}
            </>
          );
        }}
      </Form.List>
    </Form>
  ) : (
    <>
      {props.data.map((data, index) => {
        return (
          <div key={index}>
            <p></p>
            <Row
              className={props.editMode ? classes["RowDEX"] : classes["RowD"]}
            >
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.CHARGE_CODE}
                type="Select"
                name="CHARGE NAME"
                value={data.charge_code}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                name="CHARGE VALUE"
                value={data.igst}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.CHARGE_TYPE}
                type="Select"
                name="CHARGE TYPE"
                value={data.charge_type}
              />
              <DataField
                editMode={false}
                lg={12}
                md={24}
                options={props.ad.CHARGE_TYPE_ON}
                type="Select"
                name="ON"
                value={data.charge_type_on}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.REF_CHARGE}
                type="Select"
                name="REFFRED CHARGE"
                value={data.ref_charge}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.REF_ON}
                type="Select"
                name="ON"
                value={data.ref_on}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.USE_FOR}
                type="Select"
                name="USED FOR"
                value={data.use_for}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.DEAL_TYPE}
                type="Select"
                name="DEAL TYPE"
                value={data.deal_type}
              />
              <DataField
                editMode={false}
                lg={8}
                md={24}
                options={props.ad.INCLUDE_COST}
                type="Select"
                name="INCLUDE IN COST"
                value={data.include_cost}
              />
            </Row>
            <p></p>
          </div>
        );
      })}
    </>
  );
};

export default ChargeType;
