import { Row, Col, Form, Input, Space, Button, Select } from "antd";
import classes from "../Pages.module.css";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";

const { Option } = Select;

const GradeDetailsEdit = (props) => {
  const [selectedSizeCodes, setSelectedSizeCodes] = useState([]);

  useEffect(() => {
    if (props.update && props.data) {
      // Extract size_code values from props.data and set as selectedSizeCodes
      const initialSelectedSizeCodes = props.data.map(
        (item) => item.quality_code
      );
      setSelectedSizeCodes(initialSelectedSizeCodes);
    }
  }, [props.update, props.data]);

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => {
      return {
        ...data,
        gradeDetails: allValues.gradeDetails,
      };
    });
  };

  const handleSelectChange = (value, index) => {
    setSelectedSizeCodes((prevSelected) => {
      const updated = [...prevSelected];
      updated[index] = value;
      return updated;
    });
  };

  return (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="gradeDetails" initialValue={props.data}>
        {(fields, { add, remove }) => {
          return (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                // <Space key={key} className = {classes['Space']} align="center">
                <Row key={key} gutter={8}>
                  <p></p>
                  <Col lg={18} md={24}>
                    <Form.Item
                      {...restField}
                      name={[name, "quality_code"]}
                      fieldKey={[fieldKey, "quality_code"]}
                      label={
                        name == 0 ? (
                          <div
                            style={{
                              padding: "0rem 0.5rem",
                              fontSize: "0.7rem",
                              fontWeight: "bold",
                            }}
                            className={classes["Label"]}
                          >
                            Grade Details{" "}
                            <span style={{ color: "red" }}> *</span>
                          </div>
                        ) : null
                      }
                      // rules={[{ required: true, message: "Missing City" }]}
                    >
                      <Select
                        showSearch
                        bordered={true}
                        dropdownStyle={{ textTransform: "capitalize" }}
                        style={{
                          width: "100%",
                          textAlign: "left",
                          backgroundColor: "white",
                          textTransform: "capitalize",
                          color: "#1777C4",
                          fontWeight: "bold",
                          boxShadow:
                            "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                        }}
                        placeholder="Search to Select"
                        optionFilterProp="children"
                        onChange={(value) => handleSelectChange(value, index)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                      >
                        {props.options.rows.map((option) => {
                          return (
                            <Option
                              disabled={selectedSizeCodes.includes(
                                option[props.options.fields[0].name]
                              )}
                              style={{
                                textTransform: "capitalize",
                                color: "#1777C4",
                              }}
                              key={option[props.options.fields[0].name]}
                              value={option[props.options.fields[0].name]}
                            >
                              {option[props.options.fields[1].name]}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col
                    lg={1}
                    md={24}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "8px",
                    }}
                  >
                    {name !== 0 && ( // Conditionally render the icon
                      <MinusCircleOutlined
                        className={classes["Remove"]}
                        onClick={() => {
                          remove(name);
                        }}
                      />
                    )}
                  </Col>
                  <Col
                    lg={1}
                    md={24}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <PlusCircleOutlined
                      className={classes["Add"]}
                      onClick={() => {
                        add();
                      }}
                    />
                  </Col>
                </Row>
                // </Space>
              ))}
            </>
          );
        }}
      </Form.List>
    </Form>
  );
};

export default GradeDetailsEdit;
