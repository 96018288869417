import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import BasicDetail from "./BasicDetail";
import ChargeType from "./ChargeType";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";

const { TabPane } = Tabs;
const identifiers = ["taxDet"];

const TaxNew = (props) => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [allData, setAllData] = useState({
    itemTax: [
      {
        hsn: null,
        norm_date: dayjs(new Date()).format("DD-MM-YYYY"),

        f_date: dayjs(new Date()).format("DD-MM-YYYY"),
        t_date: null,
      },
    ],

    taxDet: [
      {
        charge_code: null,
        charge_value: null,
        charge_type: null,
        charge_type_on: null,
        ref_charge: null,
        ref_on: null,
        use_for: null,
        deal_type: null,
        include_cost: null,
      },
    ],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);

    axios
      .get(employeeData.URL + "/api/v1/tax/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,

            HSN_STATUS: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "o", value: "open" },
                { key: "s", value: "suspend" },
              ],
            },
            CHARGE_TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "amount" },
              ],
            },
            CHARGE_TYPE_ON: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "o", value: "on qty" },
                { key: "t", value: "total" },
              ],
            },
            REF_ON: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "a", value: "amount" },
                { key: "r", value: "running total" },
              ],
            },
            USE_FOR: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "a", value: "all" },
                { key: "s", value: "single" },
              ],
            },
            INCLUDE_COST: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "y", value: "yes" },
                { key: "n", value: "no" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleSave = (event) => {
    // console.log("ytu467676");
    var formv = true;

    // Validate itemTax fields
    for (const [index, item] of allData.itemTax.entries()) {
      if (!item.hsn || !item.norm_date || !item.f_date || !item.t_date) {
        formv = false;
        message.error({
          content: `Missing required fields in itemTax Basic tab at row`,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        return; // Exit the function on first validation error
      }
    }

    // Validate taxDet fields
    for (const [index, item] of allData.taxDet.entries()) {
      // console.log(item.charge_code === 1);
      if (Number(item.charge_code) === 1) {
        // If charge_code is 1, ref_on, use_for, and deal_type are mandatory
        if (!item.ref_on || !item.use_for || !item.deal_type) {
          formv = false;
          message.error({
            content: `Missing required fields in Charge Type Details tab at row ${
              index + 1
            }: 'ref_on', 'use_for', and 'deal_type' are required when 'charge name' is basic.`,
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });
          return; // Exit the function on first validation error
        }
      } else {
        // If charge_code is not 1, other fields are mandatory
        if (
          !item.charge_value ||
          !item.charge_type ||
          !item.charge_type_on ||
          !item.ref_charge ||
          !item.ref_on ||
          !item.use_for ||
          !item.deal_type
        ) {
          formv = false;
          message.error({
            content: `Missing required fields in Charge Type Details tab at row ${
              index + 1
            }: 'charge_value', 'charge_type', 'charge_type_on', 'ref_charge', 'ref_on', 'use_for', and 'deal_type' are required when 'charge name' is not basic.`,
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });
          return; // Exit the function on first validation error
        }
      }
    }

    // If form is valid, proceed with submission

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
      };

      // console.log(postData);

      axios
        .post(employeeData.URL + "/api/v1/tax/create-tax", postData, {
          withCredentials: true,
          credentials: "include",
        })
        .then((response) => {
          message.success({
            content: "Tax Created Successfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/sales/norms/tax-details");
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/norms/tax-details");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes["Col"]}>
            <div className={classes["EditView"]}>
              <BasicDetail
                create={true}
                ad={ad}
                setData={setAllData}
                data={allData.itemTax[0]}
                editMode={editMode}
              />

              <Tabs
                defaultActiveKey="0"
                centered
                tabPosition={"top"}
                type="Card"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Charge Type Details</span>} key="0">
                  <ChargeType
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.taxDet}
                    editMode={editMode}
                  />
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default TaxNew;
