import React, { useState } from "react";
import { assets } from "../../assets/assets";
import { useNavigate } from "react-router-dom";

const AdminChat = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const navigate = useNavigate();

  const handleIconClick = () => {
    if (!isChatOpen) {
      navigate("/stock/transaction/Purchase/Chatbot");
    } else {
      navigate("/stock");
    }
    setIsChatOpen(!isChatOpen);
  };

  return (
    <div>
      <img
        src={assets.Chat}
        alt="Chat"
        onClick={handleIconClick}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "80px",
          height: "80px",
          cursor: "pointer",
          borderRadius: "50%",
          zIndex: 1000,
        }}
      />
    </div>
  );
};

export default AdminChat;
