import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext, useRef } from "react";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import TaxDetail from "./TaxDetail";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate, useLocation } from "react-router-dom";
import DummyTable from "./DummyTable"; // Import the dummy

const { TabPane } = Tabs;

const identifiers = ["invoiceSize"];

const RequisitionNew = (props) => {
  // console.log(props, "props in size detail");
  const employeeData = useContext(DataContext);
  const history = useNavigate();
  const location = useLocation(); // Get current location
  const [previousRoute, setPreviousRoute] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editMode1, setEditMode1] = useState(true);
  // const [editinv, setEditInv] = useState(false);
  const [editTode, setEditTode] = useState(true);
  const [editDode, setEditDode] = useState(false);
  const sizeRef = useRef(null);
  const [netQty, setNetQty] = useState(0);
  const [netAmount, setNetAmount] = useState(0);

  const [allData, setAllData] = useState({
    salesInvoice: [
      {
        invoice_type: "w",
        dealtype_code: null,
        booking_date: null,
        dispatch_date: null,
        booking_no: null,
        tax_type: null,
        invoice_date: dayjs(new Date()).format("DD-MM-YYYY"),
        distributor_code: null,
        distributor_name: null,
        dealer_code: null,
        external_entity_code: null,
        truck_number: null,
        gross_wt: null,
        tare_wt: null,
        broker_code: null,
        net_wt: null,
        factory_weight: null,
        party_weight: null,
        grn_no: null,
        invoice_no: null,
        order_type: null,
        del_add: null,
        del_site_code: null,
        city_code: null,
        state_code: null,
        locality: null,
        voucher_code: null,
        eway_bill_date: null,
        eway_bill: null,
        ship_to_cd: null,
        trans_type: null,
      },
    ],

    invoiceSize: [
      {
        item_code: null,
        hsn: null,
        uom_for_items: null,
        size_code: null,
        quality: null,
        no_pcs: null,
        qty: null,
        booking_rate: null,
        discount_on: null,
        discount_amt: null,
        dis_type: null,
        bk_rate: null,
        itemqtyamount: null,
      },
    ],
  });

  const [ad, setAD] = useState(null);
  const [isItemDetailVisible, setIsItemDetailVisible] = useState(true);

  const handleReqCodeChange = (newReqCode) => {
    if (newReqCode) {
      setIsItemDetailVisible(false);
    } else {
      setIsItemDetailVisible(true);
    }
  };

  useEffect(() => {
    console.log("Location:", location); // Log the entire location object
  }, [location]);

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/salesInvoice/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            INVOICE_TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "wo", value: "With Order" },
                { key: "w", value: "Without Order" },
              ],
            },
            Dis_On: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "Rate" },
                { key: "ta", value: "Total Amt" },
              ],
            },
            Dis_Type: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "A" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //////////////////////////////////
  // const calculateFinalRate = (rate, disOn, discount, disType) => {
  //   let finalRate = rate;

  //   if (disOn === "r") {
  //     // Discount on Rate
  //     if (disType === "p") {
  //       finalRate = rate * (1 - discount / 100); // Apply percentage discount to rate
  //     } else if (disType === "a") {
  //       finalRate = rate - discount; // Apply fixed amount discount to rate
  //     }
  //   } else if (disOn === "ta") {
  //     // console.log(true, "mmm");
  //     if (disType === "p") {
  //       // console.log(disType);
  //       // console.log(disType);
  //       finalRate = rate;
  //       // netAmount = qty * rate - (qty * rate * discount) / 100;
  //     } else if (disType === "a") {
  //       // netAmount = qty * rate - discount;
  //       finalRate = rate;
  //     }
  //   }

  //   return finalRate > 0 ? finalRate : 0; // Ensure final rate is non-negative
  // };

  // const calculateNetAmount = (amount, discount, disType, disOn, qty, frate) => {
  //   // console.log(disType);
  //   // console.log(disType);
  //   let netAmount = amount;

  //   if (disOn === "r") {
  //     if (disType === "p") {
  //       netAmount = amount * (1 - discount / 100); // Apply percentage discount to amount
  //     } else if (disType === "a") {
  //       netAmount = frate * qty; // Apply fixed amount discount to amount
  //     }
  //   } else if (disOn === "ta") {
  //     // console.log(true, "mmm");
  //     if (disType === "p") {
  //       // console.log(disType);
  //       // console.log(disType);
  //       // finalRate = rate;
  //       netAmount = qty * frate - (qty * frate * discount) / 100;
  //     } else if (disType === "a") {
  //       netAmount = qty * frate - discount;
  //       // finalRate = rate;
  //     }
  //   }

  //   return netAmount > 0 ? netAmount : 0; // Ensure net amount is non-negative
  // };

  // const handleInputChange = (value, uniqueCode) => {
  //   // console.log(value);
  //   // // console.log(value);

  //   const newPurreqDetail = allData.invoiceSize.map((item) => {
  //     if (item.unique_id === uniqueCode) {
  //       const amount = (value || 0) * item.total_qty;
  //       // const finalRate = calculateFinalRate(
  //       //   value,
  //       //   item.discount_on,
  //       //   item.discount,
  //       //   item.dis_type
  //       // );
  //       const netAmount = calculateNetAmount(
  //         amount,
  //         item.discount,
  //         item.dis_type,
  //         item.discount_on,
  //         item.total_qty,
  //         finalRate
  //       );

  //       return {
  //         ...item,
  //         rate: value,
  //         amount: amount,
  //         finalRate: finalRate,
  //         netAmount: netAmount,
  //       };
  //     }
  //     return item;
  //   });

  //   setAllData((prevData) => ({
  //     ...prevData,
  //     invoiceSize: newPurreqDetail,
  //   }));
  // };

  // const handleSelectChange = (value, uniqueCode, field) => {
  //   const newPurreqDetail = allData.invoiceSize.map((item) => {
  //     if (item.unique_id === uniqueCode) {
  //       const updatedItem = {
  //         ...item,
  //         [field]: value,
  //       };
  //       // console.log(updatedItem, "upppppppppppppppppppppppppp");
  //       // Recalculate finalRate and netAmount after select change
  //       const finalRate = calculateFinalRate(
  //         updatedItem.rate,
  //         updatedItem.discount_on,
  //         updatedItem.discount,
  //         updatedItem.dis_type
  //       );
  //       const netAmount = calculateNetAmount(
  //         updatedItem.amount,
  //         updatedItem.discount,
  //         updatedItem.dis_type,
  //         updatedItem.discount_on,
  //         updatedItem.total_qty,
  //         finalRate
  //       );

  //       return {
  //         ...updatedItem,
  //         finalRate: finalRate,
  //         netAmount: netAmount,
  //       };
  //     }
  //     return item;
  //   });

  //   setAllData((prevData) => ({
  //     ...prevData,
  //     invoiceSize: newPurreqDetail,
  //   }));
  // };

  // const handleDiscountChange = (value, uniqueCode) => {
  //   const newPurreqDetail = allData.invoiceSize.map((item) => {
  //     if (item.unique_id === uniqueCode) {
  //       const amount = item.rate * item.total_qty;
  //       const finalRate = calculateFinalRate(
  //         item.rate,
  //         item.discount_on,
  //         value,
  //         item.dis_type
  //       );
  //       const netAmount = calculateNetAmount(
  //         amount,
  //         value,
  //         item.dis_type,
  //         item.discount_on,
  //         item.total_qty,
  //         finalRate
  //       );

  //       return {
  //         ...item,
  //         discount: value,
  //         amount: amount,
  //         finalRate: finalRate,
  //         netAmount: netAmount,
  //       };
  //     }
  //     return item;
  //   });

  //   setAllData((prevData) => ({
  //     ...prevData,
  //     invoiceSize: newPurreqDetail,
  //   }));
  // };

  //////////////////////////Invoice Qty Change
  // const calculateFinalRate = (rate, disOn, discount, disType) => {
  //   let finalRate = rate;

  //   if (disOn === "r") {
  //     // Discount on Rate
  //     if (disType === "p") {
  //       finalRate = rate * (1 - discount / 100); // Apply percentage discount to rate
  //     } else if (disType === "a") {
  //       finalRate = rate - discount; // Apply fixed amount discount to rate
  //     }
  //   } else if (disOn === "ta") {
  //     // console.log(true, "mmm");
  //     if (disType === "p") {
  //       // console.log(disType);
  //       // console.log(disType);
  //       finalRate = rate;
  //       // netAmount = qty * rate - (qty * rate * discount) / 100;
  //     } else if (disType === "a") {
  //       // netAmount = qty * rate - discount;
  //       finalRate = rate;
  //     }
  //   }

  //   return finalRate > 0 ? finalRate : 0; // Ensure final rate is non-negative
  // };

  const calculateNetAmount = (amount, discount, disType, disOn, qty, frate) => {
    // console.log(disType);
    // console.log(disType);
    // console.log(disType);
    // console.log(disType);
    let netAmount = amount;

    if (disOn === "r") {
      if (disType === "p") {
        netAmount = amount * (1 - discount / 100); // Apply percentage discount to amount
      } else if (disType === "a") {
        netAmount = frate * qty; // Apply fixed amount discount to amount
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        // finalRate = rate;
        netAmount = qty * frate - (qty * frate * discount) / 100;
      } else if (disType === "a") {
        netAmount = qty * frate - discount;
        // finalRate = rate;
      }
    }

    return netAmount > 0 ? netAmount : 0; // Ensure net amount is non-negative
  };

  const handleInputChange = (value, uniqueCode) => {
    // console.log(value);
    // // console.log(value);

    const newPurreqDetail = allData.invoiceSize.map((item) => {
      if (item.unique_id === uniqueCode) {
        const amount = (value || 0) * item.total_qty;
        // const finalRate = calculateFinalRate(
        //   value,
        //   item.discount_on,
        //   item.discount,
        //   item.dis_type
        // );
        const netAmount = calculateNetAmount(
          amount,
          item.discount,
          item.dis_type,
          item.discount_on,
          value,
          item.rate1
        );
        // console.log(netAmount, "net amount");
        return {
          ...item,
          total_qty: value,
          // amount: amount,
          // finalRate: finalRate,
          amount2: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      invoiceSize: newPurreqDetail,
    }));
  };

  const handleSave = (event) => {
    setIsSubmitted(true); // Mark the form as submitted
    var formv = true;

    identifiers.forEach((val) => {
      let count = 0;
      allData[val].forEach((data) => {
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }

          count = count + 1;
        }
      });
    });

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
        invoiceSize: allData.invoiceSize.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);
      // Add validation logic here

      // if (
      //   allData.salesInvoice[0].invoice_type == null ||
      //   allData.salesInvoice[0].dealtype_code == null ||
      //   allData.salesInvoice[0].invoice_date == null ||
      //   allData.salesInvoice[0].dealer_code == null ||
      //   allData.salesInvoice[0].distributor_code == null ||
      //   allData.salesInvoice[0].del_add == null ||
      //   allData.salesInvoice[0].truck_number == null ||
      //   allData.salesInvoice[0].tare_wt == null ||
      //   allData.salesInvoice[0].gross_wt == null ||
      //   allData.salesInvoice[0].net_wt == null ||
      //   allData.salesInvoice[0].eway_bill_date == null ||
      //   allData.salesInvoice[0].eway_bill == null ||
      //   allData.salesInvoice[0].ship_to_cd == null
      // ) {
      //   message.error({
      //     content: "Please Fill The Mandatory Fields!!!",
      //     className: "custom-class",
      //     style: {
      //       marginTop: "1vh",
      //     },
      //   });
      //   setLoading(false);
      //   return; // Stop further execution if validation fails
      // }
      let missingFields = [];

      if (allData.salesInvoice[0].invoice_type == null) {
        missingFields.push("Invoice Type");
      }
      if (allData.salesInvoice[0].dealtype_code == null) {
        missingFields.push("Deal Type");
      }
      if (allData.salesInvoice[0].invoice_date == null) {
        missingFields.push("Invoice Date");
      }
      if (allData.salesInvoice[0].dealer_code == null) {
        missingFields.push("Dealer Name");
      }
      if (allData.salesInvoice[0].distributor_code == null) {
        missingFields.push("Customer Name");
      }
      if (allData.salesInvoice[0].del_add == null) {
        missingFields.push("Delivery Address");
      }
      if (allData.salesInvoice[0].truck_number == null) {
        missingFields.push("Truck Number");
      }
      if (allData.salesInvoice[0].tare_wt == null) {
        missingFields.push("Tare Weight");
      }
      if (allData.salesInvoice[0].gross_wt == null) {
        missingFields.push("Gross Weight");
      }
      if (allData.salesInvoice[0].net_wt == null) {
        missingFields.push("Net Weight");
      }
      if (allData.salesInvoice[0].eway_bill_date == null) {
        missingFields.push("E-way Bill Date");
      }
      if (allData.salesInvoice[0].eway_bill == null) {
        missingFields.push("E-way Bill No");
      }
      // if (allData.salesInvoice[0].ship_to_cd == null) {
      //   missingFields.push("Ship To ");
      // }

      if (missingFields.length > 0) {
        message.error({
          content: `Please Fill The Mandatory Fields: ${missingFields.join(
            ", "
          )}!!!`,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if validation fails
      }
      // amount, amount2, dis_type, discount, discount_on, hsn, item_code, quality_code,
      // rate, rate1, remarks, size_code, total_qty, uom_code

      const allFieldsNull = allData.invoiceSize.every(
        (detail) =>
          detail.item_code === null &&
          detail.uom_code === null &&
          detail.size_code === null &&
          detail.quality_code === null &&
          detail.total_qty === null &&
          detail.hsn === null &&
          detail.remarks === null &&
          detail.rate === null &&
          detail.amount === null &&
          detail.rate1 === null &&
          detail.amount2 === null &&
          detail.discount_on === null &&
          detail.discount === null &&
          detail.dis_type === null
      );

      if (allFieldsNull) {
        message.error({
          content: "Please Fill At Least One Row In The Item Detail Tab!!!",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if all fields are null
      }

      // //////////////////////with level
      const fieldDisplayNameMap = {
        item_code: "Item",
        uom_code: "Uom",
        size_code: "Size",
        quality_code: "Grade",
        total_qty: "Quantity",
        hsn: "HSN",

        rate: "Basic Rate",
        amount: "Amount",
        rate1: "Rate1",
        amount2: "Gross Amount",
      };

      const invalidRows = allData.invoiceSize.filter((detail, index) => {
        const mandatoryFields = [
          "item_code",
          "uom_code",
          "size_code",
          "quality_code",

          "hsn",
          "total_qty",

          "rate",
          "amount",
          "rate1",
          "amount2",
        ];
        const missingFields = mandatoryFields.filter(
          (field) =>
            detail[field] === null ||
            detail[field] === undefined ||
            detail[field] === ""
        );
        if (missingFields.length > 0) {
          // Map field names to display names
          const displayNames = missingFields.map(
            (field) => fieldDisplayNameMap[field]
          );
          // Construct error message for this row
          const errorMessage = `Row ${
            index + 1
          }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
          // Display error message
          message.error({
            content: errorMessage,
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });

          setLoading(false);
          return true;
        }
        return false;
      });

      if (invalidRows.length > 0) {
        return; // Stop further execution if validation fails
      }

      // Move this line outside of the filter function
      console.log(postData, "TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT");

      if (!postData.chargedata) {
        // Display error message
        // alert("Please calculate tax in the tax detail tab.");
        message.error({
          content: "Please calculate tax in the tax detail tab.!!!",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return;
        // Prevent further execution
      }

      // console.log(postData.hsn, "postdata in innnnnnnnnnnnnnnnnnnnnnnn");

      axios
        .post(
          employeeData.URL + "/api/v1/salesInvoice/create-invoice",
          postData,
          {
            withCredentials: true,
            credentials: "include",
          }
        )

        .then((response) => {
          if (response.data.status === "fail") {
            message.error({
              content: response.data.message,
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });

            setLoading(false);
          } else {
            message.success({
              content: "Invoice Create Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/sales/transaction/salesInvoice-detail");
          }
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    // Set loading to false if you have a loading state
    // setLoading(false);

    // Check if the current location's search is "hi"
    if (location.search === "?Invoice-create") {
      // Navigate to /sales/dashboard
      history("/sales/dashboard");
    } else {
      // Fallback to another route if needed
      history("/sales/transaction/salesInvoice-detail");
    }
  };
  // const handleCancel = (event) => {
  //   setLoading(false);
  //   history("/sales/transaction/salesInvoice-create");
  // };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col
            lg={24}
            md={24}
            className={classes["Col"]}
            // style={{ border: "2px solid red" }}
          >
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Sales Invoice
                </span>

                <BasicDetail
                  create={true}
                  ad={ad}
                  setData={setAllData}
                  data={allData.salesInvoice[0]}
                  data1={allData.invoiceSize}
                  netQty={netQty}
                  netAmount={netAmount}
                  editMode={editMode}
                  editMode1={editMode1}
                  setEditMode1={setEditMode1}
                  onReqCodeChange={handleReqCodeChange}
                  isSubmitted={isSubmitted}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
                // tabBarGutter="10"
                // id="tabs"
                // tabBarUnderlineStyle={{ borderWidth: 0 }} // Remove border from the tab bar
              >
                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Details
                    </span>
                  }
                  key="0"
                  // disabled={!isItemDetailVisible}
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {allData.salesInvoice[0].invoice_type === "w" &&
                    allData.salesInvoice[0].booking_no === null ? (
                      <SizeDetail
                        ad={ad}
                        setData={setAllData}
                        data={allData.invoiceSize}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        sizeRef={sizeRef}
                        // disableItemSelection={
                        //   allData.salesOrder[0].type === "wr"
                        // }
                      />
                    ) : (
                      <DummyTable
                        data={allData.invoiceSize}
                        onInputChange={handleInputChange}
                        // onSelectChange={handleSelectChange}
                        // onDiscountChange={handleDiscountChange}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Tax Details
                    </span>
                  }
                  key="1"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px", // Adjust the value as needed
                    marginBottom: "16px", // Adjust the value as needed,
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    <TaxDetail
                      create={true}
                      ad={ad}
                      setData={setAllData}
                      editMode={editMode}
                      netQty={netQty}
                      netAmount={netAmount}
                      setQty={setNetQty}
                      setAmount={setNetAmount}
                      editTode={editTode}
                      setEditDode={setEditDode}
                      setEditTode={setEditTode}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RequisitionNew;
