import React from "react";
import { Modal, Table } from "antd";

const CostomerItem = ({ visible, onClose, onRowClick, data1 }) => {
  console.log(data1);
  const columns = [
    {
      title: "Gate Pass No",
      dataIndex: "factory_arrival_code",
      key: "factory_arrival_code",
    },
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom", key: "uom" },
    { title: "Size Name", dataIndex: "size", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    // { title: "Quality Code", dataIndex: "quality_code", key: "quality_code" },
    {
      title: "Quantity",
      key: "total_qty",
      dataIndex: "total_qty",

      // render: (text, record) => (
      //   <InputNumber
      //     placeholder="Enter quantity"
      //     value={text}
      //     onChange={
      //       (value) => onQuantityChage(value, record.unique_code) // Use the new handler
      //     }
      //     min={0}
      //   />
      // ),
    },
    { title: "Uniq Id", dataIndex: "unique_code", key: "unique_code" },
    {
      title: "Rate",
      key: "rate",
      dataIndex: "rate",
    },

    {
      title: "Dis On",
      key: "discount_on",
      dataIndex: "discount_on",
    },

    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Dis Type",
      key: "dis_type",
      dataIndex: "dis_type",
    },

    {
      title: "Final Rate",
      dataIndex: "rate1",
      key: "rate1",
    },

    {
      title: "Net Amount",
      dataIndex: "amount2",
      key: "amount2",
    },
  ];

  return (
    <Modal
      title="Select Here"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={1120}
    >
      <Table
        dataSource={data1}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              onRowClick(record);
              onClose();
            },
          };
        }}
      />
    </Modal>
  );
};

export default CostomerItem;
