import { Row, Col, Card, Flex } from "antd";
import classes from "./Dashboard.module.css";

const DataCards = (props) => {
  return (
    <Row gutter={16}>
      <Col lg={11} md={11} xs={24}>
        <Card
          style={{
            backgroundColor: "#daebf336", // Light sky blue with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Row style={{ width: "100%" }}>
              <Col lg={18} className={classes["text"]}>
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Month To Date Inward Gate Pass
                </span>{" "}
              </Col>
              <Col
                lg={6}
                className={classes["text-value"]}
                // style={{
                //   border: "2px solid red", // Keep the existing border style
                // }}
              >
                {/* Heading */}
                <div
                  className={classes["Rata1"]}
                  style={
                    {
                      // border: "2px solid red", // Keep the existing border style
                      // display: "Flex",
                      // justifyContent: "right",
                      // margin: "0 auto",
                      // Add some padding for the data box
                    }
                  }
                >
                  {props.data.TotalSaudaMonthlyValue !== null
                    ? props.data.TotalSaudaMonthlyValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col lg={18} className={classes["text"]}>
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Year To Date Inward Gate Pass
                </span>{" "}
              </Col>
              <Col lg={6} className={classes["text-value"]}>
                {/* Heading */}
                <div
                  className={classes["Rata1"]}
                  style={{
                    // color: "white", // Keep existing white color for the text
                    padding: "2px", // Add some padding for better appearance
                  }}
                >
                  {props.data.TotalSaudaYearlyValue !== null
                    ? props.data.TotalSaudaYearlyValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col
                lg={18}
                style={{
                  textAlign: "left",
                }}
              >
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Current Pending Inward Gate Pass
                </span>{" "}
                {/* Heading */}
              </Col>
              <Col lg={6} className={classes["text-value"]}>
                <div className={classes["Rata1"]}>
                  {props.data.TotalPendingSaudaValue !== null
                    ? props.data.TotalPendingSaudaValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>

      <Col lg={11} md={11} xs={24}>
        <Card
          style={{
            background: "#daebf336",
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Row style={{ width: "100%" }}>
              <Col lg={18} className={classes["text"]}>
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Month To Date Total Outward Gate Pass
                </span>{" "}
                {/* Heading */}
              </Col>
              <Col lg={6} className={classes["text-value"]}>
                <div className={classes["Rata1"]}>
                  {props.data.TotalMonthlyOrderValue !== null
                    ? props.data.TotalMonthlyOrderValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>

            <Row style={{ width: "100%" }}>
              <Col lg={18} className={classes["text"]}>
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "17px",
                    fontWeight: 600,
                  }}
                >
                  Year To Date Outward Gate Pass
                </span>{" "}
                {/* Heading */}
              </Col>
              <Col lg={6} className={classes["text-value"]}>
                <div
                  className={classes["Rata1"]}
                  style={{
                    // color: "white", // Keep existing white color for the text
                    padding: "2px", // Add some padding for better appearance
                  }}
                >
                  {props.data.TotalYearlyOrderValue !== null
                    ? props.data.TotalYearlyOrderValue
                    : null}{" "}
                  {/* Data */}
                </div>
              </Col>
            </Row>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DataCards;
