import React, { useState } from "react";

const Chat = (props) => {
  // console.log(props, "rrtrtt");

  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChatOpen, setIsChatOpen] = useState(false);

  const handleSend = async () => {
    if (input.trim()) {
      const newMessages = [...messages, { role: "user", text: input }];
      setMessages(newMessages);

      try {
        const response = await fetch("http://localhost:5000/dataresponse", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ msg: input, user: props.user }),
          credentials: "include",
        });

        if (response.ok) {
          const data = await response.json();
          setMessages([
            ...newMessages,
            { role: "bot", text: data.response[0] },
          ]);
        } else {
          setMessages([
            ...newMessages,
            { role: "bot", text: "Error: Could not fetch response" },
          ]);
        }
      } catch (error) {
        setMessages([
          ...newMessages,
          { role: "bot", text: "Error: Could not fetch response" },
        ]);
      }

      setInput("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  return (
    <>
      <div
        style={{
          display: isChatOpen ? "block" : "none",
          position: "fixed",
          bottom: "80px", // Adjusted to avoid overlap with the toggle button
          right: "20px",
          width: "300px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 0 15px rgba(0, 0, 0, 0.2)",
          zIndex: 1000,
          // border: "2px solid red",
        }}
      >
        <div
          style={{
            padding: "10px",

            display: "flex",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: "10px" }}>
            <img
              src="https://img.icons8.com/?size=100&id=79UfeEN6JkZ8&format=png&color=000000"
              alt="Chat Avatar"
              style={{ width: "40px", height: "40px" }}
            />
          </div>
          <div>
            <h4 style={{ margin: 0 }}>Birbal</h4>
            <p style={{ margin: 0, fontSize: "12px", color: "#888" }}>
              Hi, My name is Birbal. How can I help you?
            </p>
          </div>
        </div>
        <div style={{ padding: "10px", maxHeight: "200px", overflowY: "auto" }}>
          {messages.map((message, index) => (
            <div
              key={index}
              style={{
                backgroundColor:
                  message.role === "user" ? "#DCF8C6" : "#f1f1f1",
                padding: "8px",
                borderRadius: "5px",
                marginBottom: "8px",
                textAlign: message.role === "user" ? "right" : "left",
              }}
            >
              <p style={{ margin: 0 }}>{message.text}</p>
            </div>
          ))}
        </div>
        <div
          style={{
            padding: "10px",
            borderTop: "1px solid #ddd",
            display: "flex",
            alignItems: "center",
          }}
        >
          <textarea
            placeholder="Write a message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            rows="1"
            style={{
              flex: 1,
              resize: "none",
              padding: "5px",
              borderRadius: "4px",
              border: "1px solid #ddd",
              marginRight: "5px",
            }}
          />
          <button
            onClick={handleSend}
            style={{
              padding: "5px 10px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            Send
          </button>
        </div>
      </div>
      <div
        onClick={toggleChat}
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          width: "50px",
          height: "50px",
          backgroundColor: "#007bff",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          zIndex: 1000,
        }}
      >
        <img
          src="https://img.icons8.com/ios-filled/50/ffffff/chat.png"
          alt="Chat Icon"
          style={{ width: "24px", height: "24px" }}
        />
      </div>
    </>
  );
};

export default Chat;
