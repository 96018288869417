import React from "react";
import { Table, InputNumber } from "antd";
import "./style.css"; // Import your custom styles

const DummyTable = ({
  data,
  onInputChange,
  onSelectChange,
  onDiscountChange,
}) => {
  const columns = [
    { title: "Order Code", dataIndex: "booking_no", key: "booking_no" },
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom_nm", key: "uom_nm" },
    { title: "Size", dataIndex: "size", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    {
      title: "Quantity",
      dataIndex: "pending_qty",
      key: "pending_qty",
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) =>
            onInputChange(value, record.unique_id, "total_qty")
          }
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Dis On",
      key: "discount_on",
      render: (text, record) => (
        <span>{record.discount_on === "r" ? "Rate" : "Total Amt"}</span>
      ),
    },
    {
      title: "Discount",
      key: "discount",
      render: (text, record) => <span>{record.discount}</span>,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{(record.total_qty * (record.rate || 0)).toFixed(2)}</span>
      ),
    },
    {
      title: "Dis Type",
      key: "dis_type",
      render: (text, record) => (
        <span>{record.dis_type === "p" ? "%" : "Amount"}</span>
      ),
    },
    {
      title: "Final Rate",
      dataIndex: "rate1",
      key: "rate1",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Net Amount",
      dataIndex: "amount2",
      key: "amount2",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Table
      className="custom-table"
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_id}
    />
  );
};

export default DummyTable;
