import { Row, Input, message, Select, InputNumber } from "antd";
import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Tooltip } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import classes from "../Pages.module.css";
import DataField from "./datafieldir";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import IssueDetails from "./Issuedetail";
import dayjs from "dayjs";

const Issueheaderdetails = (props) => {
  // // console.log(props, "12345678909654323456789");
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deletedRowKeys, setDeletedRowKeys] = useState([]);
  const [isModal2Visible, setIsModal2Visible] = useState(false);
  const [codalData, setCodalData] = useState([]);
  // useEffect(() => {
  //   if (
  //     props.ad.ACCOUNT_CODE.fields &&
  //     Array.isArray(props.ad.ACCOUNT_CODE.fields)
  //   ) {
  //     const newCols = props.ad.ACCOUNT_CODE.fields.map((col) => ({
  //       title: col.name.split("_").join(" ").toLowerCase(),
  //       dataIndex: col.name,
  //       key: col.name,
  //     }));

  //     setColumns(newCols);

  //     if (
  //       props.ad.ACCOUNT_CODE.rows &&
  //       Array.isArray(props.ad.ACCOUNT_CODE.rows)
  //     ) {
  //       const newRows = props.ad.ACCOUNT_CODE.rows.map((row, index) => ({
  //         key: uuidv4(), // Use uuid for unique key
  //         SNO: tableData.length + 1,
  //         ...row,
  //       }));
  //       setRows(newRows);
  //     } else {
  //       console.error("Expected rows to be an array");
  //     }
  //   } else {
  //     console.error("Expected fields to be an array");
  //   }
  // }, [props.ad.ACCOUNT_CODE]);

  // Static columns configuration
  const columns = [
    { dataIndex: "SNO", title: "SNo" },
    { dataIndex: "rmd_code", title: "PO No" },
    { dataIndex: "po_ref", title: "PO Ref" },
    { dataIndex: "party", title: "Party" },
    // { dataIndex: "item_name", title: "Item" },

    // { dataIndex: "uom_desc", title: "Uom" },
    // { dataIndex: "size_desc", title: "Size" },
    // { dataIndex: "total_qty", title: "Quantity" },
    // Add as many static columns as needed
  ];

  useEffect(() => {
    console.log(props);
    const { dealtype_cd, party_code } = props.data;

    if (dealtype_cd && party_code) {
      axios
        .get("http://sastetab.com/api/v1/inwarddatagp/ingpdat", {
          params: {
            deal_type_cd: dealtype_cd,
            party_code: party_code,
          },
          withCredentials: true,
        })
        .then((response) => {
          console.log(response);
          // setColumns((columns) => {
          //   let newCols = response.data.data.tableData.fields.map((col) => {
          //     return {
          //       dataIndex: col.name, // Make sure dataIndex is set
          //       title: col.name.split("_").join(" ").toLowerCase(),
          //     };
          //   });
          //   const newNewCols = [{ dataIndex: "SNO", title: "SNo" }, ...newCols];
          //   return newNewCols;
          // });

          setRows((rows) => {
            let newRows = response.data.data.tableData.rows.map(
              (row, index) => {
                return {
                  SNO: index + 1,
                  ...row,
                };
              }
            );
            return newRows;
          });
        });

      // const fetchedData = response.data.data.tableData;
      // console.log("Received data:", fetchedData);

      // if (Array.isArray(fetchedData)) {
      //   setTableData(fetchedData);
      // } else {
      //   console.error("Expected array but received:", fetchedData);
      //   message.error("Unexpected data format received from the server");
      // }

      // .catch((error) => message.error("Failed to fetch table data"));
    }
  }, [props.data.dealtype_cd, props.data.party_code]);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleRowClick = async (record) => {
    console.log("Clicked Row Data:", record);
    const val = record.rmd_code; // The code from the selected row

    if (val) {
      console.log(val, "Truck number from row clicked");

      try {
        // Trigger the API call with the selected rmd_code
        const response = await axios.get(
          `http://sastetab.com/api/v1/inwarddatagp/inwarddetail-detailtable/${val}`,
          {
            withCredentials: true, // Include credentials (cookies, authorization headers, etc.)
          }
        );
        console.log(response);
        setCodalData(response.data.data.INWARD.rows);
        // Update the state with the API response data
        props.setData((data) => {
          // Update InWard and InDetail properties based on API response and record data
          const updatedInWard = [
            {
              ...data.InWard[0],
              po_code: record.rmd_code, // Update based on the selected row
            },
          ];

          return {
            ...data,
            InWard: updatedInWard,
            InDetail: response.data.data.INWARD.rows, // Update with API response rows
          };
        });

        // Close the modal after updating data
        handleCloseModal();
      } catch (error) {
        console.error("Error fetching data:", error);
        // Optionally, show a message to the user if there's an error
        message.error("Failed to fetch data for the selected row.");
      }
    }
  };

  const handleButtonClick = async () => {
    try {
      console.log(props.data);
      // Destructure the required values from props
      const { dealtype_cd, party_code, po_code } = props.data;

      // Make the API call, passing dealercode and customer_code as query parameters
      const response = await axios.get(
        `http://sastetab.com/api/v1/inwarddatagp/additional-data-of-po?dealTypecode=${dealtype_cd}&partyCode=${party_code}&poCode=${po_code}`,
        { withCredentials: true }
      );

      // Log the API response for debugging
      console.log("API Response:", response.data.data.Requisition.rows);
      setRows2((rows) => {
        let newRows = response.data.data.Requisition.rows.map((row, index) => {
          return {
            ...row,
          };
        });
        return newRows;
      });
      // Update the modal data state with the API response
      // setCodalData(response.data.data.Requisition.rows);

      // Show the modal
      setIsModal2Visible(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle the error as needed (e.g., set an error state or show a message)
    }
  };

  const handleOpenModal2 = () => {
    setIsModal2Visible(true);
  };

  const handleCloseModal2 = () => setIsModal2Visible(false);

  const handleRowClickModal2 = async (record) => {
    console.log("Row clicked in Modal2:", record);

    try {
      // Update codalData by appending the new record to the existing array
      setCodalData((prevCodalData) => [...prevCodalData, record]);

      // Update InDetail using props.setData
      props.setData((prevData) => {
        const updatedInDetail = [...prevData.InDetail, record]; // Append the new record to InDetail
        return {
          ...prevData, // Spread the previous state
          InDetail: updatedInDetail, // Update InDetail with the new array
        };
      });

      // Close the modal after selection
      handleCloseModal2();
    } catch (apiError) {
      console.error("Error updating codalData or InDetail:", apiError);
    }
  };

  const columns2 = [
    { title: "PO Code", dataIndex: "rmd_code", key: "rmd_code" },
    { title: "Item Name", dataIndex: "item_name", key: "item_name" },
    { title: "Size Name", dataIndex: "size_desc", key: "size_desc" },
    { title: "Grade", dataIndex: "quality_desc", key: "quality_desc" },
    { title: "Deal Type", dataIndex: "deal_desc", key: "deal_desc" },
    { title: "Uom Name", dataIndex: "uom_desc", key: "uom_desc" },
    { title: "Qty", dataIndex: "total_qty", key: "total_qty" },
  ];

  // const rows2 = [
  //   { id: 3, columnA: "Data A", columnB: "Data B" },
  //   { id: 4, columnA: "Data C", columnB: "Data D" },
  // ];

  const handleDeleteRow = (key) => {
    setDeletedRowKeys((prevKeys) => {
      const newDeletedKeys = [...prevKeys, key];

      // Update the IssueDetail in the parent component
      props.setData((prevData) => {
        const updatedIssueDetail = prevData.IssueDetail.filter(
          (item) => item.key !== key
        );

        return {
          ...prevData,
          IssueDetail: updatedIssueDetail,
        };
      });

      // Notify parent about the change in deleted rows
      //   if (onDeletedRowsChange) {
      //     onDeletedRowsChange(newDeletedKeys);
      //   }

      return newDeletedKeys;
    });
  };

  const handleChange = (e, param) => {
    const value = e.target.value;
    props.setData((data) => {
      const newdata = [...data["InWard"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        InWard: newdata,
      };
    });
    // If truckNo is being updated, make the API call
    // if (param === "truckNo") {
    //   console.log(value, "hiiiiiiiiiiiiiiiiiiiiiiii");
    //   try {
    //     const response = axios.get(`/your-api-endpoint/${value}`); // Replace `/your-api-endpoint` with your actual API endpoint
    //     const { driver_name, pending_inward } = response.data; // Adjust according to the actual response structure

    //     // Update additional fields (driverName, pendingInward) after receiving data from API
    //     props.setData((data) => {
    //       const updatedOutWard = [
    //         {
    //           ...data.OutWard[0],
    //           driverName: driver_name,
    //           pendingInward: pending_inward,
    //         },
    //       ];
    //       return {
    //         ...data,
    //         OutWard: updatedOutWard,
    //       };
    //     });
    //   } catch (error) {
    //     console.error("Error fetching truck details:", error);
    //   }
    // }
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["InWard"]];
      newdata[0][param] = val;
      return {
        ...data,
        InWard: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["InWard"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        InWard: newdata,
      };
    });
  };

  const handleDTChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["InWard"]];
      newdata[0][param] = dateString;

      // Calculate the total time if both from_req_time and to_req_time are filled
      // const fromTime =
      //   param === "from_time" ? dateString : data.prodHdr[0].from_time;
      // const toTime = param === "to_time" ? dateString : data.prodHdr[0].to_time;

      // if (fromTime && toTime) {
      //   const from = dayjs(fromTime, "DD-MM-YYYY HH:mm:ss");
      //   const to = dayjs(toTime, "DD-MM-YYYY HH:mm:ss");

      //   if (to.isAfter(from)) {
      //     const diff = to.diff(from, "hour", true); // Calculate the difference in hours
      //     newdata[0]["no_of_hrs"] = diff.toFixed(2); // Update the total with the calculated difference
      //   } else {
      //     message.error("To Time should be after From Time");
      //     newdata[0]["no_of_hrs"] = "";
      //   }
      // }

      return {
        ...data,
        InWard: newdata,
      };
    });
  };
  // Handler for changing quantity
  const handleQtyChange = (value, uniqueCode) => {
    // First, ensure that we update the codalData state
    setCodalData((prevCodalData) =>
      prevCodalData.map(
        (dataItem) =>
          dataItem.unique_code === uniqueCode
            ? { ...dataItem, total_qty: value } // Update the correct row
            : { ...dataItem } // Return a copy of the other rows
      )
    );

    // Next, update the InDetail state using props.setData
    props.setData((prevData) => {
      const updatedInDetail = prevData.InDetail.map(
        (dataItem) =>
          dataItem.unique_code === uniqueCode
            ? { ...dataItem, total_qty: value } // Update the correct row in InDetail
            : { ...dataItem } // Return a copy of the other rows
      );

      return {
        ...prevData,
        InDetail: updatedInDetail, // Set the updated InDetail
      };
    });
  };

  // Handler for changing convert_qty
  const handleConvertQtyChange = (value, uniqueCode) => {
    setCodalData((prevCodalData) =>
      prevCodalData.map((dataItem) =>
        dataItem.unique_code === uniqueCode
          ? { ...dataItem, convert_qty: value } // Update convert_qty for the specific row
          : dataItem
      )
    );

    // Update InDetail (if needed)
    props.setData((prevData) => {
      const updatedInDetail = prevData.InDetail.map((dataItem) =>
        dataItem.unique_code === uniqueCode
          ? { ...dataItem, convert_qty: value } // Update in InDetail as well
          : dataItem
      );
      return { ...prevData, InDetail: updatedInDetail };
    });
  };

  // Handler for changing convert_uom
  const handleConvertUOMChange = (value, uniqueCode) => {
    setCodalData((prevCodalData) =>
      prevCodalData.map((dataItem) =>
        dataItem.unique_code === uniqueCode
          ? { ...dataItem, convert_uom: value } // Update convert_uom for the specific row
          : dataItem
      )
    );

    // Update InDetail (if needed)
    props.setData((prevData) => {
      const updatedInDetail = prevData.InDetail.map((dataItem) =>
        dataItem.unique_code === uniqueCode
          ? { ...dataItem, convert_uom: value } // Update in InDetail as well
          : dataItem
      );
      return { ...prevData, InDetail: updatedInDetail };
    });
  };

  const tableColumns = [
    { title: "Item Name", dataIndex: "item_name", key: "item_name" },
    { title: "Size Name", dataIndex: "size_desc", key: "size_desc" },
    { title: "Grade", dataIndex: "quality_desc", key: "quality_desc" },
    // { title: "Deal Type", dataIndex: "deal_desc", key: "deal_desc" },
    { title: "Uom Name", dataIndex: "uom_desc", key: "uom_desc" },
    // New: convert_uom as Select field with options from props.ad.convUom.rows.map
    {
      title: "Convert UOM",
      dataIndex: "convert_uom",
      key: "convert_uom",
      render: (text, record) => (
        <Select
          value={record.convert_uom}
          onChange={(value) =>
            handleConvertUOMChange(value, record.unique_code)
          } // Update the UOM based on unique code
          style={{ width: "100%" }} // Ensures the field fits properly
        >
          {props.ad.convUom.rows.map((uom) => (
            <Select.Option key={uom.uom_code} value={uom.uom_code}>
              {uom.uom}
            </Select.Option>
          ))}
        </Select>
      ),
    },

    {
      title: "Convert Qty",
      dataIndex: "convert_qty",
      key: "convert_qty",
      render: (text, record) => (
        <InputNumber
          value={record.convert_qty}
          min={0}
          onChange={(value) =>
            handleConvertQtyChange(value, record.unique_code)
          } // Update the qty based on unique code
          style={{ width: "100%" }} // Ensures the field fits properly
        />
      ),
    },
    {
      title: "Qty",
      dataIndex: "total_qty",
      key: "total_qty",
      render: (text, record) => (
        <Input
          value={record.total_qty}
          onChange={(e) => handleQtyChange(e.target.value, record.unique_code)}
        />
      ),
    },
  ];

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="Issue Code"
            param="issue_code"
            value={props.data.issue_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          name="Gate Pass Type"
          options={props.ad.gatePassType}
          type="Select"
          param="g_type_cd"
          required="True"
          value={props.data.g_type_cd}
        />

        {/* <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          name="Pending Inward"
          type="Input"
          param="pendingInward"
          value={props.data.pendingInward}
          onClick={handleOpenModal}
        /> */}

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.Dis_On}
          name="Against Of"
          type="Select"
          required="True"
          param="against_of"
          value={props.data.against_of}
        />
        {props.data.against_of !== "D" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            handleChange={handleSChange}
            options={props.ad.dealType}
            name="Deal Type"
            type="Select"
            required="True"
            param="dealtype_cd"
            value={props.data.dealtype_cd}
          />
        )}

        {props.data.against_of !== "D" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            handleChange={handleSChange}
            options={props.ad.vender}
            name="Party"
            type="Select"
            required="True"
            param="party_code"
            value={props.data.party_code}
          />
        )}
        {props.data.against_of == "P" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            name="Po Number"
            type="Input"
            param="po_code"
            value={props.data.po_code}
            onClick={handleOpenModal}
          />
        )}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          name="Truck Number"
          type="Input"
          param="truck_no"
          required="True"
          value={props.data.truck_no}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleChange}
          // options={}
          type="Input"
          name="Driver"
          required="True"
          param="driver_name"
          value={props.data.driver_name}
        />
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleSChange}
          options={props.ad.gateKeeperName}
          name="Gate Keeper Name"
          type="Select"
          required="True"
          param="gatekeeper_cd"
          value={props.data.gatekeeper_cd}
        />
        {props.data.against_of !== "D" && (
          <DataField
            editMode={props.editMode}
            lg={4}
            md={24}
            handleChange={handleSChange}
            options={props.ad.orderType}
            name="Order Type"
            type="Select"
            required="True"
            param="po_type1"
            value={props.data.po_type1}
          />
        )}
        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          required="True"
          name="Start Date"
          param="start_date"
          value={props.data.start_date}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          required="True"
          name="Arrival Date"
          param="start_date"
          value={props.data.arrival_date}
        />

        <DataField
          editMode={props.editMode}
          lg={4}
          md={24}
          handleChange={handleDChange}
          type="Date"
          required="True"
          name="Challan Date"
          param="challan_date"
          value={props.data.challan_date}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleDTChange}
          type="DateTime"
          id="from_time"
          required="True"
          name="From Time"
          param="timein"
          value={props.data.timein}
          rules={[{ message: "missing name" }]}
        />

        <DataField
          editMode={props.editMode}
          lg={5}
          md={24}
          handleChange={handleDTChange}
          type="DateTime"
          id="to_time"
          required="True"
          name="To Time"
          param="timeout"
          value={props.data.timeout}
          rules={[{ message: "missing name" }]}
        />
        {props.data.against_of == "P" && (
          <Button
            type="primary"
            onClick={handleButtonClick}
            style={{ marginLeft: "15px", marginTop: "30px" }}
          >
            Add item
          </Button>
        )}
      </Row>

      <Modal
        title="List Of Po's"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        width={800}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={rows}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record);
            },
          })}
          rowKey={(record) => record.id}
        />
      </Modal>

      <Modal
        title="List Of Po's"
        visible={isModal2Visible}
        onCancel={handleCloseModal2}
        width={800}
        footer={[
          <Button key="close" onClick={handleCloseModal2}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={columns2}
          dataSource={rows2}
          onRow={(record) => ({
            onClick: () => handleRowClickModal2(record),
          })}
          rowKey={(record) => record.id}
        />
      </Modal>

      <Table
        columns={tableColumns}
        dataSource={codalData}
        rowKey="unique_code"
      />
    </div>
  );
};

export default Issueheaderdetails;
