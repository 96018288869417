import { Row, Input } from "antd";
import React, { useState, useEffect } from "react";
import { Modal, Table, Button, Tooltip } from "antd";
import { AiFillDelete } from "react-icons/ai";
import { RiUserSearchFill } from "react-icons/ri";
import classes from "../Pages.module.css";
import DataField from "./datafieldir";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import IssueDetails from "./Issuedetail";

const Issueheaderdetails = (props) => {
  // // console.log(props, "12345678909654323456789");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [rows2, setRows2] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deletedRowKeys, setDeletedRowKeys] = useState([]);
  const [isModal2Visible, setIsModal2Visible] = useState(false);

  useEffect(() => {
    if (
      props.ad.ACCOUNT_CODE.fields &&
      Array.isArray(props.ad.ACCOUNT_CODE.fields)
    ) {
      const newCols = props.ad.ACCOUNT_CODE.fields.map((col) => ({
        title: col.name.split("_").join(" ").toLowerCase(),
        dataIndex: col.name,
        key: col.name,
      }));

      setColumns(newCols);

      if (
        props.ad.ACCOUNT_CODE.rows &&
        Array.isArray(props.ad.ACCOUNT_CODE.rows)
      ) {
        const newRows = props.ad.ACCOUNT_CODE.rows.map((row, index) => ({
          key: uuidv4(), // Use uuid for unique key
          SNO: tableData.length + 1,
          ...row,
        }));
        setRows(newRows);
      } else {
        console.error("Expected rows to be an array");
      }
    } else {
      console.error("Expected fields to be an array");
    }
  }, [props.ad.ACCOUNT_CODE]);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleRowClick = async (record) => {
    console.log("Clicked Row Data:", record);
    // // console.log("Clicked Row Data:", record);
    // const val = record.issue_code;
    try {
      props.setData((data) => {
        // Update IssueReturnHeader
        const updatedIssueReturnHeader = [
          {
            ...data.OutWard[0],
            pendingInward: record.factory_arrival_code, // Map factory_arrival_code to pendingInward
            truckNo: record.truck_no, // Map truck_no to truckNo
            driverName: record.driver_name,
          },
        ];

        return {
          ...data,
          OutWard: updatedIssueReturnHeader,
        };
      });

      // Now manually trigger the API call for the truckNo assigned programmatically
      const val = record.truck_no; // The truck number from the selected record
      const gpDate = props.data.gpDate;
      // console.log(props.data.challanDate);
      if (val) {
        console.log(val, "Truck number from row clicked");

        try {
          // Trigger API call for the truckNo
          const response = await axios.get(
            `http://sastetab.com/api/v1/outwardgatepassdata/table-data/${val}?gpdate=${gpDate}`,
            {
              withCredentials: true, // Include credentials (cookies, authorization headers, etc.)
            }
          );
          console.log(response);
          // setSelectedRow(response.data.ACCOUNT_CODE.rows);
          setRows2(response.data.ACCOUNT_CODE.rows);
          // const { driver_name, pending_inward } = response.data; // Adjust response structure

          // Update driverName and pendingInward fields after receiving API response
          // props.setData((data) => {
          //   const updatedOutWard = [
          //     {
          //       ...data.OutWard[0],
          //       driverName: driver_name,
          //       pendingInward: pending_inward,
          //     },
          //   ];

          //   return {
          //     ...data,
          //     OutWard: updatedOutWard,
          //   };
          // });
        } catch (apiError) {
          console.error("Error fetching truck details from API:", apiError);
        }
      }

      setIsModalVisible(false); // Close the modal after selection
    } catch (error) {
      // console.error("Error fetching data:", error);
      // Handle error (e.g., show a message to the user)
    }
  };

  const handleOpenModal2 = () => {
    setIsModal2Visible(true);
  };
  const handleCloseModal2 = () => setIsModal2Visible(false);

  const handleRowClickModal2 = async (record) => {
    console.log("Row clicked in Modal2:", record);

    try {
      props.setData((data) => {
        // Update IssueReturnHeader
        const updatedIssueReturnHeader = [
          {
            ...data.OutWard[0],
            invoiceCode: record.invoice_no, // Map factory_arrival_code to pendingInward
            customer: record.customer, // Map truck_no to truckNo
            cust_code: record.distributor_code,
            challanDate: record.invoice_date,
          },
        ];

        return {
          ...data,
          OutWard: updatedIssueReturnHeader,
        };
      });

      // Now manually trigger the API call for the truckNo assigned programmatically
      const val = record.invoice_no; // The truck number from the selected record
      // const gpDate = props.data.challanDate;
      // console.log(props.data.challanDate);
      if (val) {
        console.log(val, "Truck number from row clicked");

        try {
          // Trigger API call for the truckNo
          const response = await axios.get(
            `http://sastetab.com/api/v1/outwardgatepassdata/table-item-data/${val}`,
            {
              withCredentials: true, // Include credentials (cookies, authorization headers, etc.)
            }
          );
          console.log(response);
          setTableData(response.data.ACCOUNT_CODE.rows);
          props.setData((data) => {
            const updatedOutDetail = response.data.ACCOUNT_CODE.rows;

            return {
              ...data,
              OutDetail: updatedOutDetail, // Set table data to OutDetail
            };
          });
        } catch (apiError) {
          console.error("Error fetching truck details from API:", apiError);
        }
      }

      handleCloseModal2(); // Close the modal after selection
    } catch (apiError) {
      console.error("Error fetching truck details from API:", apiError);
    }
  };

  const columns2 = [
    { title: "Invoice No", dataIndex: "invoice_no", key: "invoice_no" },
    { title: "Customer", dataIndex: "customer", key: "customer" },
    { title: "Invoice Date", dataIndex: "invoice_date", key: "invoice_date" },
  ];

  // const rows2 = [
  //   { id: 3, columnA: "Data A", columnB: "Data B" },
  //   { id: 4, columnA: "Data C", columnB: "Data D" },
  // ];

  const handleDeleteRow = (key) => {
    setDeletedRowKeys((prevKeys) => {
      const newDeletedKeys = [...prevKeys, key];

      // Update the IssueDetail in the parent component
      props.setData((prevData) => {
        const updatedIssueDetail = prevData.IssueDetail.filter(
          (item) => item.key !== key
        );

        return {
          ...prevData,
          IssueDetail: updatedIssueDetail,
        };
      });

      // Notify parent about the change in deleted rows
      //   if (onDeletedRowsChange) {
      //     onDeletedRowsChange(newDeletedKeys);
      //   }

      return newDeletedKeys;
    });
  };

  const handleChange = (e, param) => {
    const value = e.target.value;
    props.setData((data) => {
      const newdata = [...data["OutWard"]];
      newdata[0][param] = e.target.value;
      return {
        ...data,
        OutWard: newdata,
      };
    });
    // If truckNo is being updated, make the API call
    // if (param === "truckNo") {
    //   console.log(value, "hiiiiiiiiiiiiiiiiiiiiiiii");
    //   try {
    //     const response = axios.get(`/your-api-endpoint/${value}`); // Replace `/your-api-endpoint` with your actual API endpoint
    //     const { driver_name, pending_inward } = response.data; // Adjust according to the actual response structure

    //     // Update additional fields (driverName, pendingInward) after receiving data from API
    //     props.setData((data) => {
    //       const updatedOutWard = [
    //         {
    //           ...data.OutWard[0],
    //           driverName: driver_name,
    //           pendingInward: pending_inward,
    //         },
    //       ];
    //       return {
    //         ...data,
    //         OutWard: updatedOutWard,
    //       };
    //     });
    //   } catch (error) {
    //     console.error("Error fetching truck details:", error);
    //   }
    // }
  };

  // Function to make API call when truck number is filled
  const fetchTruckDetails = async (truckNo) => {
    console.log(truckNo);
    try {
      const response = await axios.get(`/api/truck/details?truckNo=${truckNo}`);
      const truckData = response.data;

      props.setData((data) => {
        const newdata = [...data["OutWard"]];
        newdata[0].driverName = truckData.driverName || ""; // Update driver name based on API response
        return {
          ...data,
          OutWard: newdata,
        };
      });

      // message.success("Truck details fetched successfully!");
    } catch (error) {
      console.error("Error fetching truck details:", error);
      // message.error("Failed to fetch truck details.");
    }
  };

  const handleInputChange = (key, param, value) => {
    // // console.log("Input value:", value);
    // // console.log("Input value:", value);

    props.setData((prevData) => {
      const updatedIssueDetail = prevData.outDetail.map((item) => {
        if (item.key === key) {
          // // console.log("Updating item:", item);
          return { ...item, [param]: value };
        }
        return item;
      });

      return {
        ...prevData,
        outDetail: updatedIssueDetail,
      };
    });

    // // console.log("Updated data:", props.data); // Note: This may not immediately reflect the new state due to React's asynchronous state updates
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["OutWard"]];
      newdata[0][param] = val;
      return {
        ...data,
        OutWard: newdata,
      };
    });
  };

  const handleChangegross = (e, param) => {
    props.setData((data) => {
      const newdata = [...data["OutWard"]];
      // console.log(data);
      newdata[0][param] = e.target.value;
      props.data.netWeight =
        data.OutWard[0].totalWeight - data.OutWard[0].tareWeight;

      //    props.data['salesInvoice'][0]['net_wt'] = data['salesInvoice'][0]['gross_wt'] - data['salesInvoice'][0]['tare_wt']

      // console.log(newdata);
      return {
        ...data,
        OutWard: newdata,
      };
    });
  };

  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["OutWard"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        OutWard: newdata,
      };
    });
  };

  const tableColumns = [
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Size Name", dataIndex: "size", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    { title: "Cost Center", dataIndex: "store_name", key: "store_name" },
    // {
    //   title: "Return Qty",
    //   dataIndex: "return_qty",
    //   key: "return_qty",
    //   render: (_, record) => (
    //     <Input
    //       value={record.return_qty}
    //       onChange={(e) =>
    //         handleInputChange(record.key, "return_qty", e.target.value)
    //       }
    //     />
    //   ),
    // },
    { title: "Qty", dataIndex: "total_qty", key: "total_qty" },
    // { title: "UOM Code", dataIndex: "uom_code", key: "uom_code" },
    // { title: "Rate", dataIndex: "rate", key: "rate" },
    // {
    //   title: "Action",
    //   key: "action",
    //   render: (_, record) => (
    //     <Button type="link" onClick={() => handleDeleteRow(record.key)}>
    //       <AiFillDelete />
    //     </Button>
    //   ),
    // },
  ];

  // const handleTableDataChange = (e, index, field) => {
  //     const newTableData = [...tableData];
  //     newTableData[index][field] = e.target.value;
  //     setTableData(newTableData);
  // };

  const filteredTableData = tableData.filter(
    (row) => !deletedRowKeys.includes(row.key)
  );

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="Issue Code"
            param="issue_code"
            value={props.data.issue_code}
          />
        )}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleDChange}
          name="Outward Date"
          type="Date"
          required="true"
          param="gpDate"
          value={props.data.gpDate}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          name="Pending Inward"
          required="true"
          type="Input"
          param="pendingInward"
          value={props.data.pendingInward}
          onClick={handleOpenModal}
        />

        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange}  name = "Return Code" type="Input" param = "return_code" value = {props.data.return_code}/>   */}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Truck Number"
          type="Input"
          param="truckNo"
          required="true"
          value={props.data.truckNo}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Driver Name"
          required="true"
          type="Input"
          param="driverName"
          value={props.data.driverName}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          onClick={handleOpenModal2}
          // options={}
          type="Input"
          name="Invoice No"
          required="true"
          param="invoiceCode"
          value={props.data.invoiceCode}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Customer"
          type="Input"
          required="true"
          param="customer"
          value={props.data.customer}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChangegross}
          name="Total Weight"
          type="Input"
          param="totalWeight"
          value={props.data.totalWeight}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChangegross}
          name="Tare Weight"
          type="Input"
          param="tareWeight"
          value={props.data.tareWeight}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Net Weight"
          type="Input"
          disable={true}
          param="netWeight"
          value={props.data.netWeight}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Gate Slip No."
          type="Input"
          required="true"
          param="gateSlipNo"
          value={props.data.gateSlipNo}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleDChange}
          type="Date"
          name="Challan Date"
          param="challanDate"
          value={props.data.challanDate}
        />

        {/* <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="Challan Number"
          type="Input"
          param="challanNo"
          value={props.data.challanNo}
        /> */}
      </Row>

      <Modal
        title="List Of Pending Inward's"
        visible={isModalVisible}
        onCancel={handleCloseModal}
        footer={[
          <Button key="close" onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={columns}
          dataSource={rows}
          onRow={(record) => ({
            onClick: () => {
              handleRowClick(record);
            },
          })}
          rowKey={(record) => record.id}
        />
      </Modal>

      <Modal
        title="List Of Invoice's"
        visible={isModal2Visible}
        onCancel={handleCloseModal2}
        footer={[
          <Button key="close" onClick={handleCloseModal2}>
            Close
          </Button>,
        ]}
      >
        <Table
          columns={columns2}
          dataSource={rows2}
          onRow={(record) => ({
            onClick: () => handleRowClickModal2(record),
          })}
          rowKey={(record) => record.id}
        />
      </Modal>

      <Table
        columns={tableColumns}
        dataSource={filteredTableData}
        rowKey="key"
      />
    </div>
  );
};

export default Issueheaderdetails;
