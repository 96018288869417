import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext, useRef } from "react";
import BasicDetail from "./BasicDetail";
import SizeDetail from "./SizeDetail";
import TaxDetail from "./TaxDetail";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import DummyTable from "./DummyTable"; // Import the dummy

const { TabPane } = Tabs;

const identifiers = ["mrirDetail"];

const RequisitionNew = (props) => {
  // console.log(props, "props in size detail");
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [editDode, setEditDode] = useState(false);
  const [netQty, setNetQty] = useState(0);
  const [netAmount, setNetAmount] = useState(0);
  const [editTode, setEditTode] = useState(true);
  const sizeRef = useRef(null);
  const [allData, setAllData] = useState({
    mrirHdr: [
      {
        mrir_date: dayjs(new Date()).format("DD-MM-YYYY"),
        deal_code: null,
        m_type: "w",
        truck_no: null,
        party_code: null,
        gate_pass: null,
        currency_cd: null,
        currency_rate1: null,
        grass_weight: null,
        taire_weight: null,
        received_weight: null,
        party_wt: null,
        left_qty: null,
        po_ref: null,
        bill_no: null,
        bill_date: dayjs(new Date()).format("DD-MM-YYYY"),
      },
    ],

    mrirDetail: [
      {
        item_code: null,
        size_code: null,
        hsn: null,
        quality_code: null,

        item_acc_code: null,
        ordered_qty: null,
        deal_rate: null,
        discount: null,
        discount_on: null,
        amount: null,
        dis_type: null,
        deal_rate1: null,
        con_qty: null,
        con_uom: null,
        gross_p: null,
        received_qty: null,
        amount2: null,
        dis_type: null,
        remarks: null,
        h_tax: null,
      },
    ],
  });

  const [ad, setAD] = useState(null);
  const [isItemDetailVisible, setIsItemDetailVisible] = useState(true);

  const handleReqCodeChange = (newReqCode) => {
    if (newReqCode) {
      setIsItemDetailVisible(false);
    } else {
      setIsItemDetailVisible(true);
    }
  };

  useEffect(() => {
    setEditMode(true);
    setAD(null);
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/mrir/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            TYPE: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "wm", value: "With Gate pass" },
                { key: "w", value: "Without Gate Pass" },
              ],
            },
            Dis_On: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "r", value: "Rate" },
                { key: "ta", value: "Total Amt" },
              ],
            },
            Dis_Type: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "p", value: "%" },
                { key: "a", value: "A" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //////////////////////////////////
  const calculateFinalRate = (rate, disOn, discount, disType) => {
    let finalRate = rate;

    if (disOn === "r") {
      // Discount on Rate
      if (disType === "p") {
        finalRate = rate * (1 - discount / 100); // Apply percentage discount to rate
      } else if (disType === "a") {
        finalRate = rate - discount; // Apply fixed amount discount to rate
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        finalRate = rate;
        // netAmount = qty * rate - (qty * rate * discount) / 100;
      } else if (disType === "a") {
        // netAmount = qty * rate - discount;
        finalRate = rate;
      }
    }

    return finalRate > 0 ? finalRate : 0; // Ensure final rate is non-negative
  };

  const calculateNetAmount = (amount, discount, disType, disOn, qty, frate) => {
    console.log(disType);
    console.log(disOn);
    let netAmount = amount;

    if (disOn === "r") {
      if (disType === "p") {
        netAmount = qty * frate; // Apply percentage discount to amount
      } else if (disType === "a") {
        netAmount = frate * qty; // Apply fixed amount discount to amount
      }
    } else if (disOn === "ta") {
      // console.log(true, "mmm");
      if (disType === "p") {
        // console.log(disType);
        // console.log(disType);
        // finalRate = rate;
        netAmount = qty * frate;
      } else if (disType === "a") {
        netAmount = qty * frate;
        // finalRate = rate;
      }
    }

    return netAmount > 0 ? netAmount : 0; // Ensure net amount is non-negative
  };

  const handleInputChange = (value, uniqueCode) => {
    // console.log(value);
    // // console.log(value);

    const newPurreqDetail = allData.mrirDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const amount = (value || 0) * item.total_qty;
        // const finalRate = calculateFinalRate(
        //   value,
        //   item.discount_on,
        //   item.discount,
        //   item.dis_type
        // );
        const netAmount = calculateNetAmount(
          amount,
          item.discount,
          item.dis_type,
          item.discount_on,
          value,
          item.rate1
        );
        console.log(netAmount, "net amount");
        return {
          ...item,
          total_qty: value,
          // amount: amount,
          // finalRate: finalRate,
          amount2: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      mrirDetail: newPurreqDetail,
    }));
  };

  // const handleInputChange = (value, uniq_id) => {
  //   const newPurreqDetail = allData.mrirDetail.map((item) => {
  //     if (item.unique_code === uniq_id) {
  //       const amount = (value || 0) * item.quantity;
  //       const finalRate = calculateFinalRate(
  //         value,
  //         item.disOn,
  //         item.discount,
  //         item.distype
  //       );
  //       const netAmount = calculateNetAmount(
  //         amount,
  //         item.discount,
  //         item.distype,
  //         item.disOn,
  //         item.quantity,
  //         finalRate
  //       );

  //       return {
  //         ...item,
  //         rate: value,
  //         amount: amount,
  //         finalRate: finalRate,
  //         netAmount: netAmount,
  //       };
  //     }
  //     return item;
  //   });

  //   setAllData((prevData) => ({
  //     ...prevData,
  //     mrirDetail: newPurreqDetail,
  //   }));
  // };

  const handleQuantityChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.purreqDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const rateValue = item.rate || 0; // Get the current rate value
        const amount = (value || 0) * rateValue; // Calculate amount with updated quantity

        const rate1 = calculateFinalRate(
          rateValue,
          item.discount_on,
          item.discount,
          item.dis_type
        );

        const amount2 = calculateNetAmount(
          amount,
          item.discount,
          item.dis_type,
          item.discount_on,
          value || 0, // Use the new quantity
          rate1
        );

        return {
          ...item,
          total_qty: value,
          amount: amount,
          rate1: rate1,
          amount2: amount2,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      purreqDetail: newPurreqDetail,
    }));
  };

  const handleSelectChange = (value, uniqueCode, field) => {
    const newPurreqDetail = allData.mrirDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const updatedItem = {
          ...item,
          [field]: value,
        };
        // console.log(updatedItem, "upppppppppppppppppppppppppp");
        // Recalculate finalRate and netAmount after select change
        const finalRate = calculateFinalRate(
          updatedItem.item_rate,
          updatedItem.disOn,
          updatedItem.discount,
          updatedItem.distype
        );

        const netAmount = calculateNetAmount(
          updatedItem.amount,
          updatedItem.discount,
          updatedItem.distype,
          updatedItem.disOn,
          updatedItem.quantity,
          finalRate
        );

        return {
          ...updatedItem,
          finalRate: finalRate,
          netAmount: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      mrirDetail: newPurreqDetail,
    }));
  };

  const handleDiscountChange = (value, uniqueCode) => {
    const newPurreqDetail = allData.mrirDetail.map((item) => {
      if (item.unique_code === uniqueCode) {
        const amount = item.item_rate * item.quantity;
        const finalRate = calculateFinalRate(
          item.item_rate,
          item.disOn,
          value,
          item.distype
        );
        const netAmount = calculateNetAmount(
          amount,
          value,
          item.distype,
          item.disOn,
          item.quantity,
          finalRate
        );

        return {
          ...item,
          discount: value,
          amount: amount,
          finalRate: finalRate,
          netAmount: netAmount,
        };
      }
      return item;
    });

    setAllData((prevData) => ({
      ...prevData,
      mrirDetail: newPurreqDetail,
    }));
  };

  const handleSave = (event) => {
    var formv = true;

    identifiers.forEach((val) => {
      let count = 0;
      allData[val].forEach((data) => {
        if (!data) {
          formv = formv && false;
          if (count === 0) {
            message.error({
              content: "Empty Fields In " + val.toUpperCase() + " Tab!!!",
              className: "custom-class",
              style: {
                marginTop: "1vh",
              },
            });
          }

          count = count + 1;
        }
      });
    });

    if (formv) {
      setLoading(true);
      const postData = {
        ...allData,
        mrirDetail: allData.mrirDetail.map((dep) => {
          return {
            ...dep,
          };
        }),
      };

      console.log(postData);
      // Add validation logic here
      // if (
      //   allData.mrirHdr[0].party_code == null ||
      //   allData.mrirHdr[0].currency_cd == null ||
      //   allData.mrirHdr[0].deal_code == null ||
      //   allData.mrirHdr[0].mrir_date == null ||
      //   allData.mrirHdr[0].bill_date == null ||
      //   allData.mrirHdr[0].bill_no == null ||
      //   allData.mrirHdr[0].truck_no == null ||
      //   allData.mrirHdr[0].m_type == null
      // ) {
      //   message.error({
      //     content: "Please Fill The Mandatory Fields!!!",
      //     className: "custom-class",
      //     style: {
      //       marginTop: "1vh",
      //     },
      //   });
      //   setLoading(false);
      //   return; // Stop further execution if validation fails
      // }

      let missingFields = [];

      if (allData.mrirHdr[0].party_code == null) {
        missingFields.push("Vendor");
      }
      if (allData.mrirHdr[0].currency_cd == null) {
        missingFields.push("Currency");
      }

      if (
        allData.mrirHdr[0].currency_rate1 == null ||
        allData.mrirHdr[0].currency_rate1 == ""
      ) {
        missingFields.push("Currency Rate");
      }

      if (allData.mrirHdr[0].deal_code == null) {
        missingFields.push("DealType");
      }
      if (allData.mrirHdr[0].mrir_date == null) {
        missingFields.push("Mrir Date");
      }

      if (
        allData.mrirHdr[0].truck_no == null ||
        allData.mrirHdr[0].truck_no == ""
      ) {
        missingFields.push("Truck Number");
      }
      if (allData.mrirHdr[0].m_type == null) {
        missingFields.push("Gate Pass");
      }
      // if (allData.salesInvoice[0].gross_wt == null) {
      //   missingFields.push("Gross Weight");
      // }
      // if (allData.salesInvoice[0].net_wt == null) {
      //   missingFields.push("Net Weight");
      // }
      // if (allData.salesInvoice[0].eway_bill_date == null) {
      //   missingFields.push("E-way Bill Date");
      // }
      // if (allData.salesInvoice[0].eway_bill == null) {
      //   missingFields.push("E-way Bill No");
      // }
      // if (allData.salesInvoice[0].ship_to_cd == null) {
      //   missingFields.push("Ship To ");
      // }

      if (missingFields.length > 0) {
        message.error({
          content: `Please Fill The Mandatory Fields: ${missingFields.join(
            ", "
          )}!!!`,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if validation fails
      }

      const fieldDisplayNameMap = {
        item_code: "Item",
        uom_code: "Uom",
        size_code: "Size",
        quality_code: "Grade",
        total_qty: "Quantity",
        hsn: "HSN",

        rate: "Basic Rate",
        amount: "Amount",
        rate1: "Rate1",
        amount2: "Gross Amount",
      };

      const invalidRows = allData.mrirDetail.filter((detail, index) => {
        const mandatoryFields = [
          "item_code",
          "uom_code",
          "size_code",
          "quality_code",

          "hsn",
          "total_qty",

          "rate",
          "amount",
          "rate1",
          "amount2",
        ];
        const missingFields = mandatoryFields.filter(
          (field) =>
            detail[field] === null ||
            detail[field] === undefined ||
            detail[field] === ""
        );
        if (missingFields.length > 0) {
          // Map field names to display names
          const displayNames = missingFields.map(
            (field) => fieldDisplayNameMap[field]
          );
          // Construct error message for this row
          const errorMessage = `Row ${
            index + 1
          }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
          // Display error message
          message.error({
            content: errorMessage,
            className: "custom-class",
            style: {
              marginTop: "1vh",
            },
          });

          setLoading(false);
          return true;
        }
        return false;
      });

      if (invalidRows.length > 0) {
        return; // Stop further execution if validation fails
      }

      // Move this line outside of the filter function
      console.log(postData, "TTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT");

      if (!postData.chargedata) {
        // Display error message
        // alert("Please calculate tax in the tax detail tab.");
        message.error({
          content: "Please calculate tax in the tax detail tab.!!!",
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return;
        // Prevent further execution
      }

      // const allFieldsNull = allData.mrirDetail.every(
      //   (detail) =>
      //     detail.item_code === null &&
      //     detail.uom_code === null &&
      //     detail.size_code === null &&
      //     detail.quality_code === null &&
      //     detail.total_qty === null &&
      //     detail.hsn === null &&
      //     detail.remarks === null &&
      //     detail.rate === null &&
      //     detail.amount === null &&
      //     detail.rate1 === null &&
      //     detail.amount2 === null &&
      //     detail.discount_on === null &&
      //     detail.discount === null &&
      //     detail.dis_type === null &&
      //     detail.qty === null
      // );

      // if (allFieldsNull) {
      //   message.error({
      //     content: "Please Fill At Least One Row In The Item Detail Tab!!!",
      //     className: "custom-class",
      //     style: {
      //       marginTop: "1vh",
      //     },
      //   });
      //   setLoading(false);
      //   return; // Stop further execution if all fields are null
      // }

      // //////////////////////with level
      // const fieldDisplayNameMap = {
      //   item_code: "Item",
      //   uom_code: "Uom",
      //   size_code: "Size",
      //   quality_code: "Grade",
      //   total_qty: "Quantity",
      //   hsn: "HSN",

      //   remarks: "Remarks",
      //   rate: "Basic Rate",
      //   amount: "Amount",
      //   rate1: "Rate1",
      //   amount2: "Gross Amount",
      //   discount_on: "Discount On",
      //   discount: "Discount Value",
      //   dis_type: "Discount Type",
      // };

      // const invalidRows = allData.mrirDetail.filter((detail, index) => {
      //   const mandatoryFields = [
      //     "item_code",
      //     "uom_code",
      //     "size_code",
      //     "quality_code",

      //     "hsn",
      //     "total_qty",
      //     "remarks",
      //     "rate",
      //     "amount",
      //     "rate1",
      //     "amount2",
      //     "discount_on",
      //     "discount",
      //     "dis_type",
      //   ];
      //   const missingFields = mandatoryFields.filter(
      //     (field) =>
      //       detail[field] === null ||
      //       detail[field] === undefined ||
      //       detail[field] === ""
      //   );
      //   if (missingFields.length > 0) {
      //     // Map field names to display names
      //     const displayNames = missingFields.map(
      //       (field) => fieldDisplayNameMap[field]
      //     );
      //     // Construct error message for this row
      //     const errorMessage = `Row ${
      //       index + 1
      //     }: Please Fill All Mandatory Fields (${displayNames.join(", ")})`;
      //     // Display error message
      //     message.error({
      //       content: errorMessage,
      //       className: "custom-class",
      //       style: {
      //         marginTop: "1vh",
      //       },
      //     });

      //     setLoading(false);
      //     return true;
      //   }
      //   return false;
      // });

      // if (invalidRows.length > 0) {
      //   return; // Stop further execution if validation fails
      // }

      // Move this line outside of the filter function

      axios
        .post(employeeData.URL + "/api/v1/mrir", postData, {
          withCredentials: true,
          credentials: "include",
        })

        .then((response) => {
          if (response.data.status === "fail") {
            message.error({
              content: response.data.message,
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });

            setLoading(false);
          } else {
            message.success({
              content: "Mrir Create Successfully!!!",
              className: "custom-class",
              style: {
                marginTop: "2vh",
              },
            });
            setLoading(false);
            history("/purchase/transaction/mrir-detail");
          }
        })
        .catch((err) => {
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/purchase/transaction/mrir-detail");
  };

  return (
    <>
      {ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col
            lg={24}
            md={24}
            className={classes["Col"]}
            // style={{ border: "2px solid red" }}
          >
            <div className={classes["EditView"]}>
              <Col
                style={{
                  // border: "2px solid red",
                  marginTop: "5px",
                  marginBottom: "-28px",
                }}
              >
                <span style={{ fontWeight: "bold", color: "#1677ff" }}>
                  Material Receipt (MRIR)
                </span>

                <BasicDetail
                  create={true}
                  ad={ad}
                  netQty={netQty}
                  netAmount={netAmount}
                  setData={setAllData}
                  data={allData.mrirHdr[0]}
                  editMode={editMode}
                  onReqCodeChange={handleReqCodeChange}
                />
              </Col>
              <Tabs
                defaultActiveKey="0"
                centered // Center the Tabs
                type="line" // Set the type to "card"
                // tabBarGutter="10"
                // id="tabs"
                // tabBarUnderlineStyle={{ borderWidth: 0 }} // Remove border from the tab bar
              >
                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Details
                    </span>
                  }
                  key="0"
                  // disabled={!isItemDetailVisible}
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px",
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    {allData.mrirHdr[0].m_type === "w" &&
                    allData.mrirHdr[0].gate_pass === null ? (
                      <SizeDetail
                        ad={ad}
                        setData={setAllData}
                        data={allData.mrirDetail}
                        editMode={editMode}
                        editDode={editDode}
                        setEditDode={setEditDode}
                        sizeRef={sizeRef}
                        // disableItemSelection={
                        //   allData.mrirHdr[0].m_type === "wr"
                        // }
                      />
                    ) : (
                      <DummyTable
                        data={allData.mrirDetail}
                        onInputChange={handleInputChange}
                        onSelectChange={handleSelectChange}
                        onDiscountChange={handleDiscountChange}
                        onQuantityChage={handleQuantityChange}
                      />
                    )}
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span style={{ fontWeight: "bold", color: "000000" }}>
                      Item Tax Details
                    </span>
                  }
                  key="1"
                  style={{
                    // border: "none !important",
                    // border: "2px solid black",
                    marginTop: "-16px", // Adjust the value as needed
                    marginBottom: "16px", // Adjust the value as needed,
                  }}
                >
                  <div
                    style={{
                      overflowX: "scroll",
                      // padding: "10px",
                      // border: "2px solid red",
                    }}
                  >
                    <TaxDetail
                      create={true}
                      ad={ad}
                      setData={setAllData}
                      editMode={editMode}
                      netQty={netQty}
                      netAmount={netAmount}
                      setQty={setNetQty}
                      setAmount={setNetAmount}
                      editTode={editTode}
                      setEditTode={setEditTode}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default RequisitionNew;
