import { Row, Col, Tabs, message } from "antd";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DataContext from "../../../Context/dataContext";
import BasicDetails from "./Issueheaderdetails";
import SyncLoader from "react-spinners/SyncLoader";
import classes from "../Pages.module.css";
import dayjs from "dayjs";
// import dayjs from "dayjs";

const { TabPane } = Tabs;
const identifiers = ["VoucherDetail"];

const IssueReturnNew = () => {
  const employeeData = useContext(DataContext);
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [deletedRows, setDeletedRows] = useState([]);
  const [allData, setAllData] = useState({
    InWard: [
      {
        g_type_cd: null,
        against_of: null,
        start_date: null,
        arrival_date: dayjs(new Date()).format("DD-MM-YYYY"),
        challan_date: null,
        gatekeeper_cd: null,
        dealtype_cd: null,
        party_code: null,
        po_code: null,
        driver_name: null,
        truck_no: null,
        transporter_cd: null,
        po_type1: null,
        timein: null,
        timeout: null,
      },
    ],
    InDetail: [],
  });
  const [ad, setAD] = useState(null);

  useEffect(() => {
    fetchAdditionalData();
  }, []);

  const fetchAdditionalData = () => {
    setLoading(false);
    axios
      .get(employeeData.URL + "/api/v1/inwarddatagp/gpdatain", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response.data);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
            Dis_On: {
              fields: [{ name: "key" }, { name: "value" }],
              rows: [
                { key: "P", value: "PO" },
                { key: "D", value: "Dispatch" },
              ],
            },
          };
        });
      })
      .catch((error) => {
        console.error("Error fetching additional data:", error);
        setLoading(false);
      });
  };

  const handleSave = () => {
    let formValid = true;

    // identifiers.forEach((key) => {
    //   if (!allData[key].length) {
    //     formValid = false;
    //     message.error({
    //       content: `Empty Fields in ${key.toUpperCase()} Tab!!!`,
    //       className: "custom-class",
    //       style: {
    //         marginTop: "1vh",
    //       },
    //     });
    //   }
    // });

    if (formValid) {
      setLoading(true);

      // const filteredIssueDetails = allData.IssueDetail.filter(
      //   (item) => !deletedRows.includes(item.key)
      // );

      // console.log
      const postData = {
        ...allData,
        // IssueDetail: filteredIssueDetails,
        // VoucherDetail: allData.IssueDetail.map((item) => ({ ...item })),
      };
      console.log("asdfghjk", postData);
      const missingFields = [];

      // Check for missing or empty fields in allData.salesOrder
      if (
        postData.InWard[0].g_type_cd == null ||
        postData.InWard[0].g_type_cd === ""
      ) {
        missingFields.push("Gate Pass Type");
      }
      if (
        postData.InWard[0].against_of == null ||
        postData.InWard[0].against_of === ""
      ) {
        missingFields.push("Against Of");
      }
      if (
        (postData.InWard[0].dealtype_cd == null ||
          postData.InWard[0].dealtype_cd === "") &&
        postData.InWard[0].against_of !== "D"
      ) {
        missingFields.push("Deal Type");
      }
      if (
        (postData.InWard[0].party_code == null ||
          postData.InWard[0].party_code === "") &&
        postData.InWard[0].against_of !== "D"
      ) {
        missingFields.push("Party ");
      }
      if (
        postData.InWard[0].truck_no == null ||
        postData.InWard[0].truck_no === ""
      ) {
        missingFields.push("Truck No ");
      }
      if (
        postData.InWard[0].driver_name == null ||
        postData.InWard[0].driver_name === ""
      ) {
        missingFields.push("Driver");
      }
      if (
        postData.InWard[0].gatekeeper_cd == null ||
        postData.InWard[0].gatekeeper_cd === ""
      ) {
        missingFields.push("GateKeeper");
      }

      if (
        (postData.InWard[0].po_type1 == null ||
          postData.InWard[0].po_type1 === "") &&
        postData.InWard[0].against_of !== "D" // Only check if against_of is not "D"
      ) {
        missingFields.push("Order Type");
      }

      if (
        postData.InWard[0].start_date == null ||
        postData.InWard[0].start_date === ""
      ) {
        missingFields.push("Start Date");
      }

      if (
        postData.InWard[0].arrival_date == null ||
        postData.InWard[0].arrival_date === ""
      ) {
        missingFields.push("Arrival Date");
      }

      if (
        postData.InWard[0].challan_date == null ||
        postData.InWard[0].challan_date === ""
      ) {
        missingFields.push("Challan Date");
      }

      if (
        postData.InWard[0].timein == null ||
        postData.InWard[0].timein === ""
      ) {
        missingFields.push("From Time");
      }

      if (
        postData.InWard[0].timeout == null ||
        postData.InWard[0].timeout === ""
      ) {
        missingFields.push("To Time");
      }

      // If there are any missing fields, show an error message and stop further execution
      if (missingFields.length > 0) {
        message.error({
          content: `Please Fill The Mandatory Fields: ${missingFields.join(
            ", "
          )}`,
          className: "custom-class",
          style: {
            marginTop: "1vh",
          },
        });
        setLoading(false);
        return; // Stop further execution if validation fails
      }

      axios
        .post(employeeData.URL + "/api/v1/inwarddatagp/saved", postData, {
          withCredentials: true,
          credentials: "include",
        })
        .then((response) => {
          message.success({
            content: "Inward saved Succesfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/gate/transaction/inward-view");
        })
        .catch((error) => {
          console.error("Error creating Return:", error);
          message.error({
            content: "An Error Occurred!!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
        });
    }
  };

  const handleCancel = () => {
    setLoading(false);
    history("/gate/transaction/inward-view");
  };

  return (
    <>
      {ad ? (
        <Row className={classes.RowP}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes.Col}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>

              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes.ProfileButton}
              >
                Edit Details
              </button>
            </Col>
          )}
          <Col lg={1} md={1}></Col>
          <Col lg={24} md={24} className={classes.Col}>
            <div className={classes.EditView}>
              <Tabs
                defaultActiveKey="0"
                centered
                style={{ height: "100%" }}
                tabPosition="left"
                type="line"
                tabBarGutter="0"
              >
                <TabPane tab={<span>Gate Pass Inward</span>} key="0">
                  <BasicDetails
                    create={true}
                    ad={ad}
                    setData={setAllData}
                    data={allData.InWard[0]}
                    editMode={editMode}
                  />
                </TabPane>
                {/* Uncomment this when VDetailPart component is implemented */}
                {/* <TabPane tab={<span>Issue Detail</span>} key="1">
                  <VDetailPart
                    ad={ad}
                    setData={setAllData}
                    data={allData.IssueDetail}
                    editMode={editMode}
                  />
                </TabPane> */}
              </Tabs>
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
          <SyncLoader color={"rgba(255, 163, 77, 0.8)"} size={10} />
        </>
      )}
    </>
  );
};

export default IssueReturnNew;
