import React from "react";
import { Table, InputNumber, Select } from "antd";
import styles from "./DummyTable.module.css"; // Your custom CSS file

const { Option } = Select;

const DummyTable = ({
  data,
  meta,
  onInputChange,
  onSelectChange,
  onDiscountChange,
  onSizeChange,
  onOrderQtyChange,
}) => {
  console.log(meta);
  console.log(data);

  const getSizeOptions = (uniqueCode) => {
    // Ensure meta is defined and is an array
    if (Array.isArray(meta)) {
      const metaItem = meta.find((item) => item.unique_code === uniqueCode);
      return metaItem ? metaItem.sizeOptions : []; // Return empty array if no sizeOptions found
    }
    return []; // Return empty array if meta is undefined or not an array
  };

  const columns = [
    { title: "Contract Code", dataIndex: "sauda_code", key: "sauda_code" },
    { title: "Item Name", dataIndex: "item_desc", key: "item_desc" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom", key: "uom" },

    {
      title: "Size",
      key: "meta",
      render: (text, record) => {
        const sizeOptions = getSizeOptions(record.unique_code); // Get size options based on unique_code

        return (
          <Select
            value={record.size_code || undefined} // Ensure each row's size is selected independently
            onChange={(value) => onSizeChange(value, record.unique_code)} // Pass the unique identifier of the row
            style={{ width: 120 }}
            placeholder="Select Size"
          >
            {sizeOptions.map((s) => (
              <Option key={s.size_code} value={s.size_code}>
                {s.size_nm}
              </Option>
            ))}
          </Select>
        );
      },
    },

    { title: "Grade", dataIndex: "quality_desc", key: "quality_desc" },
    {
      title: " Contract Quantity",
      dataIndex: "pending_qty",
      key: "pending_qty",
    },
    {
      title: "Order Qty", // New column for Order Qty
      key: "order_qty",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter order quantity"
          value={record.order_qty}
          onChange={(value) => onOrderQtyChange(value, record.unique_code)}
          min={0}
          max={record.pending_qty}
        />
      ),
    },
    {
      title: "Rate",
      dataIndex: "sauda_rate",
      key: "sauda_rate",
    },
    {
      title: "Dis On",
      key: "dison",
      render: (text, record) => (
        <Select
          value={record.disOn}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "disOn")
          }
          style={{ width: 120 }}
        >
          <Option value="r">Rate</Option>
          <Option value="ta">Total Amt</Option>
        </Select>
      ),
    },
    {
      title: "Discount",
      key: "discount",
      render: (text, record) => (
        <InputNumber
          placeholder="Enter discount"
          value={record.discount}
          onChange={(value) => onDiscountChange(value, record.unique_code)}
          min={0}
        />
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => <span>{record.amount}</span>,
    },

    {
      title: "Dis Type",
      key: "distype",
      render: (text, record) => (
        <Select
          value={record.distype}
          onChange={(value) =>
            onSelectChange(value, record.unique_code, "distype")
          }
          style={{ width: 120 }}
        >
          <Option value="p">%</Option>
          <Option value="a">A</Option>
        </Select>
      ),
    },

    {
      title: "Final Rate",
      dataIndex: "finalRate",
      key: "finalRate",
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
    },
  ];

  return (
    <div className={styles.tableContainer}>
      <Table
        bordered
        columns={columns}
        dataSource={data}
        rowKey={(record) => record.unique_code}
        pagination={true}
        // className={styles.tableHeaderBold}
      />
    </div>
  );
};

export default DummyTable;
