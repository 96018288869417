import { Row, Col, Card } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import classes from "./Dashboard.module.css";
import { FaHandsHelping, FaFileInvoice, FaReceipt } from "react-icons/fa";
import { useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { GiSellCard } from "react-icons/gi";
import DataContext from "../../../Context/dataContext";
const DataCards = (props) => {
  // // console.log(props.employeeData.analysis);

  // // console.log(employeeData.analysis);
  // console.log(employeeData.analysis);
  // console.log(employeeData.analysis);
  return (
    <Row>
      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            backgroundColor: "#daebf336",
            // border: "2px solid red",
          }}
          bordered={true}
          className={classes["Card"]}
        >
          {/* Row 1: Add New Contract */}
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Link
                to="/sales/salescontract/create-sales-contract?contract"
                style={{ textDecoration: "none" }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    marginRight: "8px",
                  }}
                />
                <span
                  style={{
                    color: "#45b6fe",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  Add New Contract
                </span>
              </Link>
            </Col>
          </Row>

          {/* Row 2: Total Contract Monthly */}
          <Row>
            {/* Text Column */}
            <Col
              lg={18}
              md={16}
              xs={14}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  color: "#45b6fe",
                  fontSize: "16px",
                  fontWeight: 600,
                  whiteSpace: "nowrap", // Prevents text from wrapping
                }}
              >
                Month To Date Contract
              </span>
            </Col>

            {/* Number Column */}
            <Col
              lg={6}
              md={8}
              xs={10}
              style={{
                display: "flex",
                justifyContent: "flex-end", // Aligns number to the right
                alignItems: "center",
              }}
            >
              <div className={classes["Rata1"]} style={{ textAlign: "right" }}>
                {props.data.TotalSaudaMonthlyValue ?? null}
              </div>
            </Col>
          </Row>

          {/* Row 3: Total Contract Yearly */}
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Year To Date Contract
              </span>
              <div className={classes["Rata1"]}>
                {props.data.TotalSaudaYearlyValue ?? null}
              </div>
            </Col>
          </Row>

          {/* Row 4: Pending Contract */}
          <Row>
            <Col
              span={17}
              style={{
                display: "flex",
                justifyContent: "space-between",
                // alignItems: "center",
                alignItems: "left",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Current Pending Contract
              </span>
            </Col>
            <Col span={7}>
              <span
                style={{
                  display: "flex",
                  justifyContent: "flex-end", // Aligns the content to the right within the flex container

                  color: "#45b6fe",
                  fontSize: "1.8rem", // Corrected to camelCase
                  fontWeight: "400", // Corrected to camelCase
                }}
              >
                {" "}
                {props.data.TotalPendingSaudaValue ?? null}
              </span>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            background: "#daebf336",
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              md={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center", // Align items vertically in the center
                // marginTop: "10px",
              }}
            >
              <Link
                to={{
                  pathname: "/sales/transaction/salesOrder-create",
                  search: "Order-detail", // Example state // Pass state if needed
                }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    cursor: "pointer",
                    marginRight: "8px", // Add some space between the icon and text
                  }}
                  // onClick={() => (window.location.href = "/add-new-contract")}
                />
              </Link>

              <span
                style={{
                  color: "#45b6fe",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600, // Make the text bold
                }}
                // onClick={() => (window.location.href = "/add-new-contract")}
              >
                Add New Order
              </span>
            </Col>
          </Row>

          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Month To Date Order
              </span>{" "}
              <div className={classes["Rata1"]} style={{ textAlign: "right" }}>
                {props.data.TotalMonthlyOrderValue !== null
                  ? props.data.TotalMonthlyOrderValue
                  : null}{" "}
              </div>
              {/* Data */}
            </Col>
          </Row>

          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Year to Date Order
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalYearlyOrderValue !== null
                  ? props.data.TotalYearlyOrderValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Current Pending Orders
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalPendingOrderValue !== null
                  ? props.data.TotalPendingOrderValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col
        lg={{ span: 8, offset: 0 }}
        md={11}
        xs={24}
        className={classes["Col"]}
      >
        <Card
          style={{
            backgroundColor: "#daebf336", // Very light gray with 0.9 opacity
          }}
          bordered={false}
          className={classes["Card"]}
        >
          <Row>
            <Col
              md={24}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center", // Align items vertically in the center
                // marginTop: "10px",
              }}
            >
              <Link
                to={{
                  pathname: "/sales/transaction/salesInvoice-create",
                  search: "Invoice-create",

                  // Example state // Pass state if needed
                }}
              >
                <PlusCircleOutlined
                  style={{
                    fontSize: "24px",
                    color: "#45b6fe",
                    cursor: "pointer",
                    marginRight: "8px", // Add some space between the icon and text
                  }}
                  // onClick={() => (window.location.href = "/add-new-contract")}
                />
              </Link>

              <span
                style={{
                  color: "#45b6fe",
                  fontSize: "16px",
                  cursor: "pointer",
                  fontWeight: 600, // Make the text bold
                }}
                // onClick={() =>
                //   (window.location.href =
                //     "/sales/transaction/salesInvoice-create")
                // }
              >
                Add New Invoice
              </span>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Month To Date Invoice
              </span>
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={
                  {
                    // border: "2px solid red", // Keep the existing border style
                    // padding: "2px", // Add some padding for the data box
                  }
                }
              >
                {props.data.TotalInvoiceMonthlyValue !== null
                  ? props.data.TotalInvoiceMonthlyValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                style={{ color: "#45b6fe", fontSize: "16px", fontWeight: 600 }}
              >
                Year To Date Invoice
              </span>{" "}
              {/* Heading */}
              <div
                className={classes["Rata1"]}
                style={{
                  // color: "white", // Keep existing white color for the text
                  padding: "2px", // Add some padding for better appearance
                }}
              >
                {props.data.TotalInvoiceYearlyValue !== null
                  ? props.data.TotalInvoiceYearlyValue
                  : null}{" "}
                {/* Data */}
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default DataCards;
