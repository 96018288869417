import React from "react";
import { Modal, Table } from "antd";

const CostomerItem = ({ visible, onClose, onRowClick, data1 }) => {
  console.log(data1);
  const columns = [
    { title: "Indent No", dataIndex: "rq_code", key: "rq_code" },
    {
      title: "Item",
      dataIndex: "item_name",
      key: "item_name",
    },
    { title: "Size", dataIndex: "sz", key: "sz" },
    // { title: "Contract Qty", dataIndex: "sauda_qty", key: "sauda_qty" },
    // { title: "Contract Rate", dataIndex: "sauda_rate", key: "sauda_rate" },
  ];

  return (
    <Modal
      title="Select Here"
      visible={visible}
      onCancel={onClose}
      footer={null}
      width={900}
    >
      <Table
        dataSource={data1}
        columns={columns}
        onRow={(record) => {
          return {
            onClick: () => {
              onRowClick(record);
              onClose();
            },
          };
        }}
      />
    </Modal>
  );
};

export default CostomerItem;
