import { Col, Card, Input, Form, DatePicker, Select, InputNumber } from "antd";
import classes from "../Pages.module.css";
import dayjs from "dayjs";
import { useEffect } from "react";
const { Option } = Select;

const disabledDate = (current) => {
  // Disable days after today (can't select future dates)
  return current && current > dayjs().endOf("day");
};

const { TextArea } = Input; // Import TextArea from Ant Design

const capitalizeFirstLetter = (text) => {
  return text
    .toLowerCase() // Convert the whole string to lowercase first
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
};

const DataField = (props) => {
  console.log(props);
  // Auto-select the first customer if "cust_code" and no value is set
  useEffect(() => {
    if (props.param === "cust_code" && props.customers && !props.value) {
      if (props.customers.length > 0) {
        const firstCustomer = props.customers[0].distributor_code;
        props.handleChange(firstCustomer, props.param); // Auto-select the first customer
      }
    }
  }, [props.param, props.customers, props.value, props.handleChange]);

  return (
    <Col lg={props.lg} md={props.md} className={classes["Col"]}>
      <Form layout="vertical">
        <Form.Item
          colon={false}
          style={{ margin: "0", padding: "0" }}
          label={
            <div
              style={{
                padding: "0rem 0.5rem",
                fontSize: "0.7rem",
                fontWeight: "bold",
              }}
              className={classes["Label"]}
            >
              {capitalizeFirstLetter(props.name)}{" "}
              {/* Capitalize first letter */}
              {props.required ? <span style={{ color: "red" }}> *</span> : ""}
            </div>
          }
        >
          {props.type === "Date" ? (
            <DatePicker
              value={props.value ? dayjs(props.value, "DD-MM-YYYY") : null} // Ensure value is either a valid date or null
              format="DD-MM-YYYY"
              style={{
                textAlign: "left",
                width: "100%",
                backgroundColor: "white",
                color: "#1777C4",
                fontWeight: "bold",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              allowClear={true}
              bordered={true}
              onChange={(date, dateString) =>
                props.handleChange(date, dateString, props.param)
              }
              disabledDate={disabledDate}
              onClear={() => props.handleChange(null, null, props.param)} // Handle clearing of the date
            />
          ) : props.type === "Select" ? (
            <Select
              value={props.value === null ? null : props.value}
              defaultValue={props.defaultValue} // Use the defaultValue
              showSearch
              onChange={(value) => props.handleChange(value, props.param)}
              bordered={true}
              dropdownStyle={{ textTransform: "capitalize" }}
              style={{
                width: "100%",
                textAlign: "left",
                backgroundColor: "white",
                textTransform: "capitalize",
                color: "#1777C4",
                fontWeight: "bold",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={
                (input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase()) // Filter based on the label value
              }
              filterSort={
                (optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase()) // Sort options alphabetically
              }
            >
              {props.param !== "cust_code" &&
                props.param !== "item_code" &&
                props.param !== "sauda_quality" &&
                props.param !== "uom_code" &&
                props.options.rows.map((option) => {
                  return (
                    <Option
                      style={{
                        textTransform: "capitalize",
                        color: "#1777C4",
                      }}
                      key={option[props.options.fields[0].name]}
                      value={option[props.options.fields[0].name]}
                    >
                      {option[props.options.fields[1].name]}
                    </Option>
                  );
                })}

              {props.param === "cust_code" &&
                props.customers &&
                props.customers.map((customer) => (
                  <Option
                    style={{
                      textTransform: "capitalize",
                      color: "#1777C4",
                    }}
                    key={customer.distributor_code}
                    value={customer.distributor_code}
                  >
                    {customer.cust_name}
                  </Option>
                ))}

              {props.param === "item_code" &&
                props.items &&
                props.items.map((items) => (
                  <Option
                    style={{
                      textTransform: "capitalize",
                      color: "#1777C4",
                    }}
                    key={items.item_code}
                    value={items.item_code}
                  >
                    {items.item_name}
                  </Option>
                ))}

              {props.param === "sauda_quality" &&
                props.cats &&
                props.cats.map((cats) => (
                  <Option
                    style={{
                      textTransform: "capitalize",
                      color: "#1777C4",
                    }}
                    key={cats.quality_code}
                    value={cats.quality_code}
                  >
                    {cats.quality_name}
                  </Option>
                ))}

              {props.param === "uom_code" &&
                props.uom &&
                props.uom.map((cats) => (
                  <Option
                    style={{
                      textTransform: "capitalize",
                      color: "#1777C4",
                    }}
                    key={cats.item_uom}
                    value={cats.item_uom}
                  >
                    {cats.uom_name}
                  </Option>
                ))}
            </Select>
          ) : props.type === "textarea" ? ( // Check for textarea type
            <TextArea
              placeholder="Enter remarks"
              value={props.value ? props.value : ""}
              bordered={true}
              rows={2} // You can set the number of rows for the textarea
              onChange={(e) => props.handleChange(e, props.param)}
              style={{
                width: "100%",
                backgroundColor: "white",
                textTransform: "capitalize",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            />
          ) : (
            <Input
              placeholder="Enter"
              value={props.value ? props.value : ""}
              bordered={true}
              onChange={(e) => props.handleChange(e, props.param, props.id)}
              // onKeyPress={(e) => {
              //   if (
              //     props.param === "actual_qty" ||
              //     props.param === "sauda_rate" ||
              //     props.param === "sauda_qty"
              //   ) {
              //     const regex = /^[0-9.]$/; // Allow only numbers and decimal points

              //     // Prevent key press if it's not a number or a period
              //     if (!regex.test(e.key)) {
              //       e.preventDefault();
              //     }
              //   }
              // }}
              // style={{
              //   width: "100%",
              //   textAlign:
              //     props.param === "actual_qty" ||
              //     props.param === "sauda_rate" ||
              //     props.param === "sauda_qty"
              //       ? "right"
              //       : "left", // Conditionally set text align
              //   backgroundColor: "white",
              //   textTransform: "capitalize",
              //   boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              // }}
            />
          )}
          {props.error && <span style={{ color: "red" }}>{props.error}</span>}
        </Form.Item>
      </Form>

      {/* // (
      //   <Card
      //     bodyStyle={{ padding: "0.5rem 1rem" }}
      //     bordered={false}
      //     className={classes["Card"]}
      //   >
      //     {props.type === "Select" ? (
      //       <p className={classes["Label"]}>
      //         {props.name}
      //         <span className={classes["Data"]}>
      //           {props.value
      //             ? props.options.rows[
      //                 props.options.rows.findIndex(
      //                   (element) =>
      //                     element[props.options.fields[0].name] === props.value
      //                 )
      //               ][props.options.fields[1].name]
      //             : null}
      //         </span>
      //       </p>
      //     ) : (
      //       <p className={classes["Label"]}>
      //         {props.name}{" "}
      //         <span className={classes["Data"]}>
      //           {props.value ? props.value : null}
      //         </span>
      //       </p>
      //     )}
      //   </Card>
      // )} */}
    </Col>
  );
};

export default DataField;
