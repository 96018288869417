import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import "./SaleComponent.css"; // Make sure to import your CSS file

const SaleComponent = (props) => {
  console.log(props);
  const componentRef = useRef(); // Ref to hold the printable content
  const actionRef = useRef(); // This should be actionsRef to match usage

  // useEffect(() => {
  //   const fetchprops.pdfResponse = async () => {
  //     try {
  //       const response = await fetch(`http://localhost:3001/sale-order/${bookingCode}`);
  //       const data = await response.json();
  //       setprops.pdfResponse(data);
  //     } catch (error) {
  //       console.error("Error fetching sale data:", error);
  //     }
  //   };

  //   fetchprops.pdfResponse();
  // }, [bookingCode]);

  const numberToWords = (num) => {
    const a = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    const numToWords = (n) => {
      if (n < 20) return a[n];
      if (n < 100)
        return b[Math.floor(n / 10)] + (n % 10 ? "-" + a[n % 10] : "");
      if (n < 1000)
        return (
          a[Math.floor(n / 100)] +
          " hundred" +
          (n % 100 ? " and " + numToWords(n % 100) : "")
        );
      if (n < 1000000)
        return (
          numToWords(Math.floor(n / 1000)) +
          " thousand" +
          (n % 1000 ? " " + numToWords(n % 1000) : "")
        );
      return "";
    };

    // Check if the input is a valid number
    if (isNaN(num) || num < 0) return "zero rupees only";

    // Split the number into whole and decimal parts
    const [whole, decimal] = num.toString().split(".");
    let result = "";

    // Convert the whole part
    if (whole) {
      result += numToWords(parseInt(whole)) + " rupees";
    } else {
      result += "zero rupees";
    }

    // Convert the decimal part if it exists
    if (decimal) {
      const paisa = parseInt(decimal.slice(0, 2)); // Get the first two digits for paise
      if (paisa) {
        result += " and " + numToWords(paisa) + " paise";
      }
    }

    return result + " only";
  };

  // Native Print functionality
  const handlePrint = () => {
    const printContents = componentRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // Reload the page after printing
  };

  const handleDownloadPdf = () => {
    const element = componentRef.current;

    // Hide the buttons using the ref
    const actions = actionRef.current;
    if (actions) {
      actions.style.display = "none"; // Hide the buttons
    }

    const options = {
      filename: `sale.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: {
        unit: "in",
        format: "letter", // Adjust to 'a4' if needed
        orientation: "portrait",
        // margins: { top: 20, bottom: 20, left: 20, right: 20 }, // Set 20px margins
      },
    };

    html2pdf()
      .set(options)
      .from(element)
      .save()
      .then(() => {
        // Show the buttons again
        if (actions) {
          actions.style.display = "block"; // Restore buttons after download
        }
      })
      .catch((err) => {
        console.error("Error generating PDF:", err);
        // Restore buttons even if an error occurs
        if (actions) {
          actions.style.display = "block";
        }
      });
  };

  // if (!props.pdfResponse) {
  //   return <p>Loading...</p>;
  // }

  const totalAmount = props.pdfResponse.saleOrderItems
    .reduce((total, item) => {
      return total + parseFloat(item.amount2 || 0);
    }, 0)
    .toFixed(2);

  return (
    <div className="contain" ref={componentRef}>
      <div className="sale-actions" ref={actionRef}>
        <button onClick={handlePrint}>Print</button>
        <button onClick={handleDownloadPdf}>Download as PDF</button>
      </div>
      {/* The printable content should be referenced by componentRef */}
      <div className="size-page">
        <table
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <tbody>
            <tr>
              <td rowSpan={5} className="border">
                <div className="center">
                  <h1 style={{ fontSize: "24px" }}>SALE ORDER</h1>
                  <p>
                    {props.pdfResponse.itemlogo.site_desc}&nbsp;&nbsp;
                    {props.pdfResponse.itemlogo.add1}
                  </p>
                  {
                    <p>
                      {props.pdfResponse.itemlogo.ph1}&nbsp;&nbsp;
                      {props.pdfResponse.itemlogo.email}
                    </p>
                  }
                </div>
              </td>
            </tr>
            <tr>
              <td className="right border">Original for Recipient</td>
            </tr>
            <tr>
              <td className="right border">Duplicate for Transporter</td>
            </tr>
            <tr>
              <td className="right border">Triplicate for Supplier</td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td
                style={{ borderTop: "1px solid black" }}
                colSpan={2}
                className="center border"
              >
                {/* <img style={{ width: "300px", height: "50px" }} src="../logo (1).png" alt="logo" /> */}
              </td>
            </tr>
            <tr>
              <td className="border">
                <div>
                  {
                    <div style={{ marginBottom: "5px" }}>
                      Customer :{" "}
                      <strong>
                        {props.pdfResponse.saleOrderHeader.customer}
                      </strong>
                    </div>
                  }
                  {
                    <div style={{ marginBottom: "5px" }}>
                      Dealer:{" "}
                      <strong>
                        {props.pdfResponse.saleOrderHeader.dealer}
                      </strong>
                    </div>
                  }
                  {
                    <div style={{ marginBottom: "5px" }}>
                      GSTIN:{" "}
                      <strong>
                        {props.pdfResponse.saleOrderHeader.gst_number}
                      </strong>
                    </div>
                  }
                  {
                    <div style={{ marginBottom: "5px" }}>
                      Delivery Site :{" "}
                      <strong>
                        {" "}
                        {props.pdfResponse.saleOrderHeader.delivery_site}
                      </strong>
                    </div>
                  }
                </div>
              </td>
              <td className="border">
                <div>
                  <div style={{ marginBottom: "5px" }}>
                    Order Date:{" "}
                    <strong>
                      {" "}
                      {props.pdfResponse.saleOrderHeader.booking_date}
                    </strong>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    Order No:{" "}
                    <strong>
                      {" "}
                      {props.pdfResponse.saleOrderHeader.booking_code}
                    </strong>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    Customer Po No :{" "}
                    <strong>
                      {" "}
                      {props.pdfResponse.saleOrderHeader.customer_po_no}
                    </strong>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    Customer Po Date:{" "}
                    <strong>
                      {" "}
                      {props.pdfResponse.saleOrderHeader.customer_po_date}
                    </strong>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table style={{ borderBottom: "1px solid black" }}>
          <thead>
            <tr>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                S.NO
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                Item
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                Size
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                Grade
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                Qty(kg)
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                UOM
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                Rate
              </th>
              <th className="border" style={{ borderBottom: "1px solid" }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {props.pdfResponse.saleOrderItems.map((item, index) => (
              <tr key={index}>
                <td className="right border">{index + 1}</td>
                <td className="right border">{item.item}</td>
                <td className="right border">{item.sz}</td>
                <td className="right border">{item.grade}</td>
                <td className="right border">{item.total_qty}</td>
                <td className="right border">{item.uom_desc}</td>
                <td className="right border">{item.rate1}</td>
                <td className="right border">{item.amount2}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table>
          <thead>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td className="border">
                <div style={{ marginBottom: "10px" }}>
                  {" "}
                  <strong>Total Value (In Words) :</strong>{" "}
                </div>
                <span>{numberToWords(totalAmount)}</span>
              </td>
              <td className="border">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div>Total Value:</div>
                  <div style={{ textAlign: "right" }}>
                    <strong>{totalAmount}</strong>
                  </div>
                </div>
              </td>
            </tr>
            <tr style={{ borderBottom: "1px solid black" }}>
              <td className="border"></td>
              <td className="border" style={{ textAlign: "right" }}>
                <div>
                  Certified that the particulars given above are true and
                  correct
                </div>
                <div>
                  <strong>{props.pdfResponse.company.company_name}</strong>
                </div>
                <div style={{ marginBottom: "30px" }}></div>
                <div>Authorised Signatory</div>
                <div>E.&O.E</div>
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default SaleComponent;
