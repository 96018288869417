import ItemNew from "../ItemMaster/ItemNew";

import React from "react";
import { Modal, Table } from "antd";

const ReqCodeModal = ({ visible, onClose, onRowClick, data }) => {
  const columns = [
    { title: "Order No", dataIndex: "booking_code", key: "booking_code" },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    { title: "Order Date", dataIndex: "booking_date", key: "booking_date" },
    { title: "Dealer Name", dataIndex: "dealer", key: "dealer" },
    { title: "Remarks", dataIndex: "remarks", key: "remarks" },
  ];

  return <ItemNew />;
};

export default ReqCodeModal;
