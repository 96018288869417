import React, { useState, useEffect, useRef } from "react";
import html2pdf from "html2pdf.js";
import "./InvoiceComponent.css"; // Make sure to import your CSS file
import logo from "../../../../src/logo.png";

const InvoiceComponent = (props) => {
  console.log(props);
  const componentRef = useRef(); // Ref to hold the printable content
  const [showButtons, setShowButtons] = useState(true);
  const numberToWords = (num) => {
    const a = [
      "",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const b = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    const numToWords = (n) => {
      if (n < 20) return a[n];
      if (n < 100)
        return b[Math.floor(n / 10)] + (n % 10 ? "-" + a[n % 10] : "");
      if (n < 1000)
        return (
          a[Math.floor(n / 100)] +
          " hundred" +
          (n % 100 ? " and " + numToWords(n % 100) : "")
        );
      if (n < 1000000)
        return (
          numToWords(Math.floor(n / 1000)) +
          " thousand" +
          (n % 1000 ? " " + numToWords(n % 1000) : "")
        );
      return "";
    };

    if (isNaN(num) || num < 0) return "zero rupees only";

    const [whole, decimal] = num.toString().split(".");
    let result = "";

    if (whole) {
      result += numToWords(parseInt(whole)) + " rupees";
    } else {
      result += "zero rupees";
    }

    if (decimal) {
      const paisa = parseInt(decimal.slice(0, 2));
      if (paisa) {
        result += " and " + numToWords(paisa) + " paise";
      }
    }

    return result + " only";
  };

  // Native Print functionality
  const handlePrint = () => {
    const printContents = componentRef.current.innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };

  const handleDownload = () => {
    setShowButtons(false); // Hide buttons

    const content = componentRef.current;

    if (content) {
      // Make sure to set up your custom fonts in the CSS
      const pdfOptions = {
        margin: 2,
        filename: "downloaded-pdf.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          useCORS: true, // Ensure CORS is used to load external images and fonts
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          fonts: {
            // Add your custom fonts here if necessary
            // example: Helvetica: 'path/to/font.ttf'
          },
        },
      };

      html2pdf()
        .from(content)
        .set(pdfOptions)
        .save()
        .then(() => {
          setShowButtons(true); // Show buttons again after download
        });
    }
  };
  // Download PDF functionality using html2pdf.js

  if (!props.pdfResponse) {
    return <p>Loading...</p>;
  }

  const totalAmount = props.pdfResponse.item
    .reduce((total, item) => total + parseFloat(item.amount2 || 0), 0)
    .toFixed(2);
  const TaxVal = props.pdfResponse.totalAmount.reduce(
    (total, charge) => total + parseFloat(charge.tax_val || 0),
    0
  );
  const gstin = props.pdfResponse.customere[0].gstin;
  const stateCode = gstin ? gstin.substring(0, 2) : "";

  return (
    <div className="contain" ref={componentRef}>
      {showButtons && ( // Conditional rendering for buttons
        <div className="invoice-actions">
          <button onClick={handlePrint}>Print</button>
          <button onClick={handleDownload}>Download as PDF</button>
        </div>
      )}

      {/* The printable content should be referenced by componentRef */}
      <div className="invoice-page">
        {/* Invoice content */}
        <table
          style={{
            borderTop: "1px solid black",
            borderBottom: "1px solid black",
          }}
        >
          <tbody>
            <tr>
              <td className="border" rowSpan={5}>
                <div className="center">
                  <h1 style={{ fontSize: "24px" }}>TAX INVOICE</h1>
                  <h1 style={{ fontSize: "18px" }}>
                    {props.pdfResponse.company[0].company_name}
                  </h1>
                  <p>
                    {props.pdfResponse.address[0].site_desc}&nbsp;&nbsp;
                    {props.pdfResponse.address[0].add1}
                  </p>
                  <p>
                    {props.pdfResponse.address[0].ph1}&nbsp;&nbsp;
                    {props.pdfResponse.address[0].email}
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td className="right border">Original for Recipient</td>
            </tr>
            <tr>
              <td className="right border">Duplicate for Transporter</td>
            </tr>
            <tr>
              <td className="right border">Triplicate for Supplier</td>
            </tr>
            <tr
              style={{
                borderBottom: "1px solid black",
              }}
            >
              <td colSpan={2} className="center border">
                <img
                  style={{ width: "45px", height: "20px" }}
                  src="../logo(1).png"
                  alt="logo"
                />
              </td>
            </tr>

            <tr>
              <td className="border">
                <div>
                  <div style={{ marginBottom: "5px" }}>
                    Our GSTIN :{" "}
                    <strong>{props.pdfResponse.address[0].gst_no}</strong>
                  </div>

                  <div style={{ marginBottom: "5px" }}>
                    Invoice No:{" "}
                    <strong>{props.pdfResponse.customere[0].invoice_no}</strong>
                  </div>
                  <div style={{ marginBottom: "5px" }}>
                    Invoice Date:{" "}
                    <strong>
                      {" "}
                      {props.pdfResponse.customere[0].invoice_date}
                    </strong>
                  </div>
                </div>
              </td>
              <td className="border">
                <div>
                  <div>Mode of Transport:</div>
                  <div>Transporter Name:</div>
                  <div>
                    Vehicle NO:
                    <strong style={{ marginLeft: "1rem" }}>
                      {props.pdfResponse.customere[0].truck_number}
                    </strong>
                  </div>
                  <div>Date & Time of Supply:</div>
                  <div>Place of Supply:</div>
                  <div>E-WayBill No:</div>
                  <div>Remarks:</div>
                </div>
              </td>
            </tr>
            <tr>
              <td className="border" style={{ borderTop: "1px solid black" }}>
                <h4>Detail Of Receiver (Billed to)</h4>
                <p>
                  Name:{" "}
                  <strong style={{ marginLeft: "1rem" }}>
                    {" "}
                    {props.pdfResponse.customere[0].cust}
                  </strong>
                </p>
                <p>
                  Address:{" "}
                  <span>{props.pdfResponse.customere[0].delivery_add}</span>{" "}
                </p>
                <p>
                  State Code: <span>{stateCode}</span>{" "}
                </p>
                <p>GSTIN: {gstin}</p>
              </td>
              <td className="border" style={{ borderTop: "1px solid black" }}>
                <h4>Detail of Consignee (Shipped to)</h4>
                <p>
                  Name: <strong>{props.pdfResponse.customere[0].cust}</strong>
                </p>
                <p>Address: {props.pdfResponse.customere[0].delivery_add}</p>
                <p>State Code:</p>
                <p>GSTIN: {props.pdfResponse.customere[0].gstin}</p>
              </td>
            </tr>
          </tbody>
        </table>

        <table style={{ borderBottom: "1px solid black" }}>
          <thead>
            <tr>
              <th className="right border">S.NO</th>
              <th className="right border">Item</th>
              <th className="right border">HSN</th>
              <th className="right border">Size</th>
              <th className="right border">Qty(kg)</th>
              <th className="right border">Rate</th>
              <th className="right border">Amount</th>
              <th className="right border">Taxable Value</th>
            </tr>
          </thead>
          <tbody>
            {props.pdfResponse.item.map((item, index) => (
              <tr key={index}>
                <td className="right border">{index + 1}</td>
                <td className="right border">{item.item}</td>
                <td className="right border">{item.hsn}</td>
                <td className="right border">{item.size}</td>
                <td className="right border">{item.total_qty}</td>
                <td className="right border">{item.rate}</td>
                <td className="right border">{item.amount2}</td>
                <td className="right border">{item.amount2}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                className="border"
                colSpan="4"
                style={{ textAlign: "right", borderTop: "1px solid black" }}
              >
                <strong>Total</strong>
              </td>
              <td
                className="right border"
                style={{ borderTop: "1px solid black" }}
              >
                <strong>
                  {props.pdfResponse.item
                    .reduce(
                      (total, item) => total + parseFloat(item.total_qty || 0),
                      0
                    )
                    .toFixed(2)}
                </strong>
              </td>
              <td
                className="border"
                style={{ borderTop: "1px solid black" }}
              ></td>
              <td
                className="border"
                style={{ borderTop: "1px solid black" }}
              ></td>
              <td
                className="right border"
                style={{ borderTop: "1px solid black" }}
              >
                <strong>
                  {props.pdfResponse.item
                    .reduce(
                      (total, item) => total + parseFloat(item.amount2 || 0),
                      0
                    )
                    .toFixed(2)}
                </strong>
              </td>
            </tr>
          </tfoot>
        </table>
        <div
          className=""
          style={{ display: "flex", width: "100%", height: "100%" }}
        >
          <div
            className="table-1"
            style={{ width: "50%", display: "flex", flexDirection: "column" }}
          >
            {/* Tax Table */}
            <table
              className="tax"
              style={{
                borderBottom: "1px solid black",
                flex: "1",
                height: "100%",
              }}
            >
              <tbody>
                <tr style={{ height: "100%" }}>
                  <br />
                  <td
                    className="border"
                    style={{
                      height: "100%",
                      verticalAlign: "top",
                      borderRight: "none",
                    }}
                  >
                    <div>
                      <div style={{ padding: "5px", marginBottom: "2rem" }}>
                        {" "}
                        <strong>Total Tax (In Words):</strong> <br /> Rupees.
                        {numberToWords(Number(TaxVal))}{" "}
                      </div>
                      <div style={{ padding: "5px", marginTop: "2rem" }}>
                        {" "}
                        <strong>Total Invoice Value (In Words) :</strong> <br />
                        Rupees. {numberToWords(Number(totalAmount))}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div
            className="table-2"
            style={{ width: "50%", display: "flex", flexDirection: "column" }}
          >
            <table style={{ flex: "1", height: "100%" }}>
              <tbody>
                <tr>
                  <td
                    className="border"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    Total Amount Before Tax
                  </td>
                  <td
                    style={{ borderBottom: "1px solid black" }}
                    className="right border"
                  >
                    <strong>
                      {props.pdfResponse.item
                        .reduce(
                          (total, item) =>
                            total + parseFloat(item.amount2 || 0),
                          0
                        )
                        .toFixed(2)}
                    </strong>
                  </td>
                </tr>
                {props.pdfResponse.totalAmount &&
                  props.pdfResponse.totalAmount.map((charge, index) => (
                    <tr key={index}>
                      <td className="border">
                        <div>
                          Add: {charge.get_charge}@{" "}
                          {Math.floor(charge.charge_value)}{" "}
                          {charge.charge_type === "p"
                            ? "%"
                            : charge.charge_type}
                        </div>
                      </td>
                      <td className="right border">{charge.tax_val}</td>
                    </tr>
                  ))}
                <tr>
                  <td className="border">Net Amount</td>
                  <td className="right border">
                    {(
                      props.pdfResponse.item.reduce(
                        (total, item) => total + parseFloat(item.amount2 || 0),
                        0
                      ) +
                      props.pdfResponse.totalAmount.reduce(
                        (total, charge) =>
                          total + parseFloat(charge.tax_val || 0),
                        0
                      )
                    ).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="border">Round Off +/-</td>
                  <td className="right border">
                    {
                      // Calculate the round-off value based on the net amount
                      (() => {
                        const netAmount =
                          props.pdfResponse.item.reduce(
                            (total, item) =>
                              total + parseFloat(item.amount2 || 0),
                            0
                          ) +
                          props.pdfResponse.totalAmount.reduce(
                            (total, charge) =>
                              total + parseFloat(charge.tax_val || 0),
                            0
                          );
                        return (Math.round(netAmount) - netAmount).toFixed(2);
                      })()
                    }
                  </td>
                </tr>
                <tr style={{ borderBottom: "1px solid black" }}>
                  <td
                    className="border"
                    style={{ borderTop: "1px solid black" }}
                  >
                    Final Net Amount
                  </td>
                  <td
                    className="right border"
                    style={{ borderTop: "1px solid black" }}
                  >
                    <strong>
                      {
                        // Calculate Final Net Amount based on Net Amount and Round Off
                        (() => {
                          const netAmount =
                            props.pdfResponse.item.reduce(
                              (total, item) =>
                                total + parseFloat(item.amount2 || 0),
                              0
                            ) +
                            props.pdfResponse.totalAmount.reduce(
                              (total, charge) =>
                                total + parseFloat(charge.tax_val || 0),
                              0
                            );
                          const roundOff = Math.round(netAmount) - netAmount; // Calculate round off
                          return (netAmount + roundOff).toFixed(2);
                        })()
                      }
                    </strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Bank Details */}
        <table style={{ borderBottom: "1px solid black" }}>
          <tbody>
            <tr>
              <td className="border" rowSpan={8}>
                <div>
                  <strong>Our Bank Detail</strong>
                </div>
                <div>Bank Name : {props.pdfResponse.address[0].bank_name}</div>
                <div>
                  Bank Address : {props.pdfResponse.address[0].bank_add1}
                </div>
                <div>
                  Bank A/C No : {props.pdfResponse.address[0].account_no}
                </div>
                <div>Bank IFSC : {props.pdfResponse.address[0].ifsc_cd}</div>
                <br />
                <div>
                  <strong>TERMS & CONDITIONS : </strong>
                </div>
                <div>
                  <p>
                    Account should be settled by cross Cheques/Drafts payable to
                    the company act.
                  </p>
                </div>
                <div>
                  <p>
                    Interest @24% will be charged if payment not made within 15
                    Days
                  </p>
                </div>
                <div>
                  <p>
                    Our responsibility ceases absolutely once the goods have
                    been handed over in carriers
                  </p>
                </div>
              </td>
            </tr>
            <tr>
              <td className="border" style={{ textAlign: "right" }}>
                Certified that the particular given above are true & correct
              </td>
            </tr>
            <tr>
              <td
                className="border"
                style={{ borderTop: "1px solid black", textAlign: "right" }}
              >
                For {props.pdfResponse.company[0].company_name}
              </td>
            </tr>
            <tr>
              <td className="border" style={{ textAlign: "right" }}>
                {" "}
                Authorised Signatory <br />
                E.& O.E.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InvoiceComponent;
