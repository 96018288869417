import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import classes from "../Pages.module.css";

const { Option } = Select;

const ItemSizeEdit = (props) => {
  console.log(props.update);
  console.log(props.data);

  const [selectedSizeCodes, setSelectedSizeCodes] = useState([]);

  // Update selectedSizeCodes only when props.update is true
  useEffect(() => {
    if (props.update && props.data) {
      // Extract size_code values from props.data and set as selectedSizeCodes
      const initialSelectedSizeCodes = props.data.map((item) => item.size_code);
      setSelectedSizeCodes(initialSelectedSizeCodes);
    }
  }, [props.update, props.data]);

  const handleValueChanges = (changedValues, allValues) => {
    props.setData((data) => ({
      ...data,
      itemSize: allValues.itemSize,
    }));
  };

  const handleSelectChange = (value, index) => {
    setSelectedSizeCodes((prevSelected) => {
      const updated = [...prevSelected];
      updated[index] = value;
      return updated;
    });
  };

  return (
    <Form
      layout="vertical"
      name="filter_form"
      onValuesChange={handleValueChanges}
      autoComplete="off"
    >
      <Form.List name="itemSize" initialValue={props.data}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <Row key={key} gutter={16} style={{ alignItems: "center" }}>
                <Col lg={18} md={24}>
                  <Form.Item
                    {...restField}
                    name={[name, "size_code"]}
                    fieldKey={[fieldKey, "size_code"]}
                    label={
                      name === 0 ? (
                        <div
                          style={{
                            padding: "0rem 0.5rem",
                            fontSize: "0.7rem",
                            fontWeight: "bold",
                          }}
                          className={classes["Label"]}
                        >
                          Size Details <span style={{ color: "red" }}> *</span>
                        </div>
                      ) : null
                    }
                  >
                    <Select
                      showSearch
                      bordered={true}
                      dropdownStyle={{ textTransform: "capitalize" }}
                      style={{
                        width: "100%",
                        textAlign: "left",
                        backgroundColor: "white",
                        textTransform: "capitalize",
                        color: "#1777C4",
                        fontWeight: "bold",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                      placeholder="Search to Select"
                      optionFilterProp="children"
                      onChange={(value) => handleSelectChange(value, index)}
                    >
                      {props.options.rows.map((option) => (
                        <Option
                          key={option[props.options.fields[0].name]}
                          value={option[props.options.fields[0].name]}
                          disabled={selectedSizeCodes.includes(
                            option[props.options.fields[0].name]
                          )}
                          style={{
                            textTransform: "capitalize",
                            color: "#1777C4",
                          }}
                        >
                          {option[props.options.fields[1].name]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col
                  lg={1}
                  md={24}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "8px",
                    justifyContent: "center",
                  }}
                >
                  {name !== 0 && (
                    <MinusCircleOutlined
                      className={classes["Remove"]}
                      onClick={() => {
                        remove(name);
                        setSelectedSizeCodes((prevSelected) =>
                          prevSelected.filter(
                            (code) => code !== selectedSizeCodes[index]
                          )
                        );
                      }}
                    />
                  )}
                </Col>

                <Col
                  lg={1}
                  md={24}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <PlusCircleOutlined
                    className={classes["Add"]}
                    onClick={() => {
                      // Add a new size with a default structure
                      add({ size_code: "" }); // Initialize with a default value
                    }}
                  />
                </Col>
              </Row>
            ))}
          </>
        )}
      </Form.List>
    </Form>
  );
};

export default ItemSizeEdit;
