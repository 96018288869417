import React from "react";
import axios from "axios";
import Chat from "./Chat";
import { Row, Col } from "antd";
import { Scrollbars } from "react-custom-scrollbars-2";
import {
  LaptopOutlined,
  NotificationOutlined,
  LogoutOutlined,
  SettingOutlined,
  SearchOutlined,
  UploadOutlined,
  UserOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import classes from "./PayrollPageExtra.module.css";
// import "./PayrollPage.css";
import { Breadcrumb, Input, Layout, Menu, theme } from "antd";

import SiderContentS from "../../components/ProductionModule/SiderContentS/SiderContentS";

import { useEffect, useState } from "react";
import FieldDataS from "../../components/ProductionModule/FieldDataS/FieldDataS";

import { DataProvider } from "../../Context/dataContext";
import { Link, Route, Routes } from "react-router-dom";

const { Header, Content, Sider } = Layout;
const { Item: MenuItem } = Menu;
const { Search } = Input;

const StockModule = (props) => {
  const [profiledata, setProfiledata] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [miscList, setMiscList] = useState(null);
  const [userList, setUserList] = useState(null);
  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
  };

  const employeeData = {
    URL: props.url,
  };
  // // console.log(employeeData.analysis);
  // useEffect(() => {
  //   axios
  //     .get(props.url + "/api/v1/salesMisc", { withCredentials: true })
  //     .then((response) => {
  //       // console.log(response);
  //       setMiscList((miscList) => {
  //         const newList = response.data.data.tables.rows;
  //         // console.log(newList);
  //         return newList;
  //       });
  //       axios
  //         .get(props.url + "/api/v1/dashboard/dash-board", {
  //           withCredentials: true,
  //         })
  //         .then((response) => {
  //           // console.log(response);
  //           props.setAnalysis(response.data.data);
  //         });
  //     })

  //     .catch((err) => {
  //       // console.log(err.response);
  //     });
  // }, []);
  // // console.log(props.finyear);
  useEffect(() => {
    axios
      .get(props.url + "/api/v1/rollingprod/chat-bot-icon", {
        withCredentials: true,
      })
      .then((response) => {
        // console.log(response);
        setUserList((miscList) => {
          const newList = response.data.data;
          // console.log(newList.spec_code, "ssssssssssssssssss");
          return newList;
        });
      })

      .catch((err) => {
        // console.log(err.response);
      });
  }, []);

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "transparent" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        style={{ backgroundColor: "transparent" }}
      >
        <SiderContentS url={props.url} miscList={miscList} />
      </Sider>

      <Layout className={classes["SiteLayout"]}>
        <Scrollbars>
          <Row className={classes["row-end"]}>
            {/* Company Name Column */}
            <Col
              xs={12} // Full-width on extra small screens
              sm={14} // Wider on small screens
              md={16} // Medium screen width
              lg={19} // Larger width on large screens
              // style={{ border: "2px solid red" }}
            >
              <p className={classes["first-item"]}>
                {userList && userList.OpenningValue.rows[0].company_name}
              </p>
            </Col>

            {/* Unit Name Column */}
            <Col xs={12} sm={5} md={8} lg={5}>
              <p className={classes["text"]}>
                Unit-{userList && userList.site.unit_code} (
                {userList && userList.site.spec_code})
              </p>
            </Col>
          </Row>
          <DataProvider value={employeeData}>
            <Content>
              <FieldDataS />
            </Content>
          </DataProvider>
        </Scrollbars>
        <Row
          gutter={16}
          style={{
            backgroundColor: "transparent",
            width: "4rem",
            position: "fixed",
            bottom: "20px",
            right: "20px",
            textAlign: "right",
          }}
        >
          <Col md={24}>
            <Chat />
          </Col>
        </Row>
      </Layout>
    </Layout>
  );
};

export default StockModule;
