import React, { useState } from "react";
import { PieChart, Pie, Tooltip, Cell, LabelList } from "recharts";
import { Select, MenuItem, Box, Typography, Grid } from "@mui/material"; // Importing MUI components

// Define colors for the PieChart slices
// Define colors for the PieChart slices
// Define colors for the PieChart slices with even lighter shades
const COLORS = [
  "#1560BD", // Medium red
  "#3a7ca5", // Medium green
  "#45b6fe", // Medium blue
  "#E0E5FA", // Medium yellow
  "#50a5f1", // Medium purple
];

// Main component for rendering the chart
const Charts = ({ data, type }) => {
  const [chartValue, setChartValue] = useState("amount"); // Use "amount" as the default field

  // Handling dropdown change to toggle between different chart value fields
  const handleChange = (event) => {
    setChartValue(event.target.value);
  };

  // Helper function to capitalize the first letter of a string
  const capitalizeFirstLetter = (str) => {
    if (!str) return ""; // Handle empty string
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  return (
    <div>
      <Box
        sx={{
          width: "100%",
          maxWidth: 300,
          margin: "0 auto",
          padding: 0,
          borderRadius: 2,
          boxShadow: 0,
          textAlign: "center",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 0 }}
        >
          <Grid item xs={12}>
            <Select
              value={chartValue}
              onChange={handleChange}
              variant="standard"
              sx={{
                mb: 2,
                width: "50%",
                color: "#1560BD",
              }}
            >
              <MenuItem value="amount">Amount</MenuItem>
              <MenuItem value="qty">Quantity</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="h7"
              align="center"
              sx={{ fontWeight: "bold", color: "#1560BD" }}
            >
              {chartValue === "amount"
                ? `Amount Distribution ${capitalizeFirstLetter(type)} Wise`
                : `Quantity Distribution ${capitalizeFirstLetter(type)} Wise`}
            </Typography>
          </Grid>
        </Grid>

        <Box
          sx={{
            padding: 0,
            borderRadius: 2,
            height: 300,
          }}
        >
          <PieChart width={250} height={250}>
            <Pie
              data={data}
              dataKey={chartValue}
              nameKey={type}
              cx="50%"
              cy="50%"
              outerRadius={80}
              label={({ value }) => value}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              {/* <LabelList
                dataKey={chartValue} // Show values outside
                position="outside"
                style={{ fill: "#000", fontSize: "12px", fontWeight: "normal" }}
              /> */}
            </Pie>
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  return (
                    <div
                      style={{
                        backgroundColor: "white",
                        padding: "5px",
                        border: "1px solid #ccc",
                      }}
                    >
                      <strong>{payload[0].name}</strong>{" "}
                      {/* Show name in tooltip */}
                    </div>
                  );
                }
                return null;
              }}
            />
          </PieChart>
        </Box>
      </Box>
    </div>
  );
};

export default Charts;
