import { Row } from "antd";
import { useState } from "react";
import classes from "../Pages.module.css";
import DataField from "./DataField";

const BasicDetails = (props) => {
  const [pinError, setPinError] = useState("");
  const [panError, setPanError] = useState("");
  const [gstError, setGstError] = useState("");

  const handleChange = (e, param) => {
    const { value } = e.target;

    // For pin_no
    if (param === "pin_no") {
      // Allow only numeric input up to 6 digits
      if (!/^\d{0,6}$/.test(value)) {
        return; // Prevent invalid input
      }

      // Validate pin_no
      if (value.length < 6) {
        setPinError("Please enter exactly 6 digits for PIN.");
      } else {
        setPinError(""); // Clear error if valid
      }
    }
    // For pan_no
    else if (param === "pan_no") {
      // Validate length: must be exactly 10 characters
      if (value.length < 10) {
        setPanError(
          "Please enter exactly 10 characters for PAN No and valid format :XXXXX9999X."
        );
      } else if (value.length > 10) {
        return; // Prevent any further input if more than 10 characters
      } else {
        // Validate PAN format: 5 letters, 4 digits, 1 letter (XXXXX9999X)
        if (!/^[A-Z]{5}\d{4}[A-Z]$/.test(value)) {
          setPanError(
            "Invalid PAN format. Please enter in format: XXXXX9999X."
          );
        } else {
          setPanError(""); // Clear error if valid
        }
      }
    }

    // For s_tax_no (GST Number)
    else if (param === "s_tax_no") {
      // Validate length: must be exactly 15 characters for GST Number
      if (value.length < 15) {
        setGstError("Please enter exactly 15 characters for GST No.");
      } else if (value.length > 15) {
        return; // Prevent any further input if more than 15 characters
      } else {
        // Validate GST format:
        // 2 digits (state code) + 10 characters (PAN) +
        // 1 character (entity code) + 1 check digit +
        // 1 default character (Z)
        if (!/^\d{2}[A-Z]{5}\d{4}[A-Z][1-9][Z][A-Z\d]$/.test(value)) {
          setGstError(
            "Invalid GST format. Please enter in the format: 22ABCDE1234F1Z5."
          );
        } else {
          setGstError(""); // Clear error if valid
        }
      }
    }

    // Update the data state
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      newdata[0][param] = value;

      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };

  const handleSChange = (val, param) => {
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      newdata[0][param] = val;
      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };
  const handleDChange = (date, dateString, param) => {
    props.setData((data) => {
      const newdata = [...data["customerMaster"]];
      newdata[0][param] = dateString;
      return {
        ...data,
        customerMaster: newdata,
      };
    });
  };

  return (
    <div>
      <p></p>
      <Row className={props.editMode ? classes["RowDEX"] : classes["RowD"]}>
        {!props.create && (
          <DataField
            editMode={props.editMode}
            lg={12}
            md={24}
            handleChange={handleChange}
            name="CUSTOMER CODE"
            param="distributor_code"
            value={props.data.distributor_code}
          />
        )}
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="CUSTOMER NAME"
          id="customer_name"
          param="distributor_name"
          required="True"
          value={props.data.distributor_name}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.ACC_GROUP_CODE}
          type="Select"
          id="debitor_group"
          name="DEBITOR GROUP"
          required="True"
          param="acc_group_code"
          value={props.data.acc_group_code}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.EXT_ENTITY_TYPE_CODE}
          type="Select"
          id="customer_type"
          required="True"
          name="CUSTOMER TYPE"
          param="ext_entity_type_code"
          value={props.data.ext_entity_type_code}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="ADDRESS"
          id="address"
          param="address"
          required="True"
          value={props.data.address}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.CITY}
          type="Select"
          id="city"
          name="CITY"
          param="city"
          required="True"
          value={props.data.city}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleSChange}
          options={props.ad.LOCALITY_CODE}
          type="Select"
          id="locality"
          name="LOCALITY"
          param="locality_code"
          required="True"
          value={props.data.locality_code}
        />
        {/* <DataField editMode = {props.editMode} lg = {12} md = {24} handleChange = {handleChange} name = "HSN NO" param = "hsn" value = {props.data.hsn}/>  */}
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="PIN CODE"
          param="pin_no"
          id="pin_no"
          required="True"
          value={props.data.pin_no}
          numberVal={props.onlyNumber}
          error={pinError} // Assuming pin_no is at index 0
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="GST NO"
          id="s_tax_no"
          param="s_tax_no"
          required="True"
          value={props.data.s_tax_no}
          error={gstError}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="PAN NO"
          id="pan_no"
          param="pan_no"
          value={props.data.pan_no}
          error={panError}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="PAYMENT DAYS"
          id="payment_days"
          param="payment_days"
          value={props.data.payment_days}
        />
        {/* <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="INTEREST RATE(MO)"
          id="monthly_intrest"
          param="monthly_intrest"
          value={props.data.monthly_intrest}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="INTEREST RATE(YEAR)"
          id="yearly_intrest"
          param="yearly_intrest"
          value={props.data.yearly_intrest}
        /> */}

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="ECC NO"
          param="ecc_no"
          id="ecc_no"
          value={props.data.ecc_no}
        />

        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="CST NUMBER"
          id="cst_number"
          param="cst_no"
          value={props.data.cst_no}
        />
        <DataField
          editMode={props.editMode}
          lg={12}
          md={24}
          handleChange={handleChange}
          name="ACCOUNT CODE"
          param="account_code"
          id="account_code"
          value={props.data.account_code}
        />
      </Row>
    </div>
  );
};

export default BasicDetails;
