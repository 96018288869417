import React, { useContext, useEffect, useRef } from "react";
import "./Main.css";
import { assets } from "../../assets/assets";
import { Context } from "../../Context/ContextProvider";

const Main = () => {
  const {
    onSent,
    recentPrompt,
    showResult,
    loading,
    resultData,
    setInput,
    input,
    conversationHistory,
    newChat,
    prevPrompts,
    toggleDatabaseMode,
    isDatabaseMode,
    selectedAvatar,
  } = useContext(Context);
  const textareaRef = useRef(null);

  const handleCardClick = (text) => {
    setInput(text);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (input.trim() !== "") {
        onSent();
      }
    }
  };

  const handleNewChat = () => {
    newChat();
  };

  useEffect(() => {
    const handleCtrlQ = (e) => {
      if (e.ctrlKey && e.key === "q") {
        e.preventDefault();
        toggleDatabaseMode();
      }
    };

    window.addEventListener("keydown", handleCtrlQ);

    return () => {
      window.removeEventListener("keydown", handleCtrlQ);
    };
  }, [toggleDatabaseMode]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        150
      )}px`;
    }
  }, [input]);

  return React.createElement(
    "div",
    { className: "main" },
    React.createElement(
      "div",
      { className: "nav" },
      React.createElement("p", null, "Birbal"),
      React.createElement("img", {
        src: assets.Genie,
        alt: "Genie",
        className: "genie-photo",
      })
    ),
    React.createElement(
      "div",
      { className: "main-container" },
      !showResult
        ? React.createElement(
            React.Fragment,
            null,
            React.createElement(
              "div",
              { className: "greet" },
              React.createElement(
                "p",
                null,
                React.createElement("span", null, "Hello, Your Highness.")
              ),
              React.createElement(
                "p",
                null,
                React.createElement("span", null, "How can I help you today..?")
              )
            ),
            React.createElement(
              "div",
              { className: "cards" },
              [
                {
                  text: "Suggest best Business News websites.",
                  icon: assets.compass_icon,
                },
                {
                  text: "Show me current price of Iron in Market.",
                  icon: assets.bulb_icon,
                },
                {
                  text: "Brainstorm ideas to develop a successful Business.",
                  icon: assets.message_icon,
                },
                {
                  text: "Improve the readability of the following text.",
                  icon: assets.code_icon,
                },
              ].map((card, index) =>
                React.createElement(
                  "div",
                  {
                    key: index,
                    className: "card",
                    onClick: () => handleCardClick(card.text),
                  },
                  React.createElement("p", null, card.text),
                  React.createElement("img", { src: card.icon, alt: "" })
                )
              )
            )
          )
        : React.createElement(
            "div",
            { className: "result" },
            React.createElement(
              "div",
              { className: "history" },
              conversationHistory.map((entry, index) =>
                React.createElement(
                  "div",
                  {
                    key: index,
                    className:
                      entry.role === "user" ? "user-message" : "model-message",
                  },
                  React.createElement("p", {
                    dangerouslySetInnerHTML: { __html: entry.parts[0].text },
                  })
                )
              )
            ),
            React.createElement(
              "div",
              { className: "result-title" },
              React.createElement("img", { src: assets.user_icon, alt: "" }),
              React.createElement("p", null, recentPrompt)
            ),
            React.createElement(
              "div",
              { className: "result-data" },
              React.createElement("img", { src: assets.Genie, alt: "" }),
              loading
                ? React.createElement(
                    "div",
                    { className: "loader" },
                    React.createElement("hr", null),
                    React.createElement("hr", null),
                    React.createElement("hr", null)
                  )
                : React.createElement("p", {
                    dangerouslySetInnerHTML: { __html: resultData },
                  })
            )
          ),
      React.createElement(
        "div",
        { className: "main-bottom" },
        React.createElement(
          "div",
          { className: "search-box" },
          React.createElement("textarea", {
            ref: textareaRef,
            onChange: (e) => setInput(e.target.value),
            value: input,
            placeholder: "Ask anything...",
            onKeyDown: handleKeyDown,
            rows: "1",
            style: { resize: "none", overflow: "auto" },
          }),
          React.createElement(
            "div",
            { className: "toggle-container" },
            input
              ? React.createElement("img", {
                  onClick: () => onSent(),
                  src: assets.send_icon,
                  alt: "",
                })
              : null,
            React.createElement(
              "label",
              { className: "switch" },
              React.createElement("input", {
                type: "checkbox",
                checked: isDatabaseMode,
                onChange: toggleDatabaseMode,
              }),
              React.createElement("span", { className: "slider round" }),
              React.createElement(
                "span",
                { className: "ctrl-q-text" },
                "ctrl + q"
              )
            ),
            React.createElement("span", {
              className: "toggle-tooltip",
              dangerouslySetInnerHTML: {
                __html: isDatabaseMode ? "DB Mode On<br>" : "DB Mode Off<br>",
              },
            })
          )
        ),
        React.createElement(
          "p",
          { className: "bottom-info" },
          "Birbal is dedicated to assist you with all your questions, but it can make mistakes. Check important info."
        )
      )
    )
  );
};

export default Main;
