import React from "react";
import { Table, InputNumber, Select } from "antd";
const { Option } = Select;

const DummyTable = ({
  data,
  onInputChange,
  onSelectChange,
  onDiscountChange,
  onQuantityChage,
}) => {
  // Define columns
  const columns = [
    { title: "Item Name", dataIndex: "item", key: "item" },
    { title: "Hsn", dataIndex: "hsn", key: "hsn" },
    { title: "Uom", dataIndex: "uom", key: "uom" },
    { title: "Size Name", dataIndex: "size", key: "size" },
    { title: "Grade", dataIndex: "grade", key: "grade" },
    // { title: "Quality Code", dataIndex: "quality_code", key: "quality_code" },
    {
      title: "Quantity",
      key: "total_qty",
      dataIndex: "total_qty",
      render: (text, record) => (
        <InputNumber
          value={text}
          onChange={(value) =>
            onInputChange(value, record.unique_code, "total_qty")
          }
          // min={0}
          // step={1} // Adjust the step as needed
          // parser={(value) => value.replace(/\$\s?|(,*)/g, "")} // Parse input value
          // formatter={(value) => ` ${value}`} // Format displayed value
        />
      ),
      // render: (text, record) => (
      //   <InputNumber
      //     placeholder="Enter quantity"
      //     value={text}
      //     onChange={
      //       (value) => onQuantityChage(value, record.unique_code) // Use the new handler
      //     }
      //     min={0}
      //   />
      // ),
    },
    { title: "Uniq Id", dataIndex: "unique_code", key: "unique_code" },
    {
      title: "Rate",
      key: "rate",
      dataIndex: "rate",
    },

    {
      title: "Dis On",
      key: "discount_on",
      render: (text, record) => (
        <span>{record.discount_on === "r" ? "Rate" : "Total Amt"}</span>
      ),
    },

    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text, record) => (
        <span>{(record.total_qty * (record.rate || 0)).toFixed(2)}</span>
      ),
    },

    {
      title: "Dis Type",
      key: "dis_type",
      render: (text, record) => (
        <span>{record.dis_type === "p" ? "%" : "Amount"}</span>
      ),
    },

    {
      title: "Final Rate",
      dataIndex: "rate1",
      key: "rate1",
      render: (text) => <span>{text}</span>,
    },

    {
      title: "Net Amount",
      dataIndex: "amount2",
      key: "amount2",
      render: (text) => <span>{text}</span>,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={(record) => record.unique_code} // Assuming unique_code is unique for each row
    />
  );
};

export default DummyTable;
