import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import Main from "../Main/Main";
import ContextProvider from "../../Context/ContextProvider";
import "./Chat.css"; // Import the CSS file

function Chat() {
  return (
    <ContextProvider>
      <div className="chat-container">
        <Sidebar />
        <Main />
      </div>
    </ContextProvider>
  );
}

export default Chat;
