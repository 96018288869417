import React, { useContext, useState } from 'react';
import './Sidebar.css';
import { assets } from '../../assets/assets';
import { Context } from '../../Context/ContextProvider';

function Sidebar() {
  const [extended, setExtended] = useState(false);
  const {
    onSent,
    prevPrompts,
    setRecentPrompt,
    conversationHistory,
    setConversationHistory,
    setPrevPrompts,
    newChat,
  } = useContext(Context);

  const loadPrompt = async (prompt) => {
    setRecentPrompt(prompt);
    await onSent(prompt);
  };

  return React.createElement(
    'div',
    { className: 'sidebar' },
    React.createElement(
      'div',
      { className: 'top' },
      React.createElement('img', {
        onClick: () => setExtended((prev) => !prev),
        className: 'menu',
        src: assets.menu_icon,
        alt: 'Menu Icon',
      }),
      React.createElement(
        'div',
        { onClick: newChat, className: 'new-chat' },
        React.createElement('img', {
          src: assets.plus_icon,
          alt: 'New Chat Icon',
        }),
        extended ? React.createElement('p', null, 'New Chat') : null
      ),
      extended
        ? React.createElement(
            'div',
            { className: 'recent' },
            React.createElement('p', { className: 'recent-title' }, 'Recent'),
            prevPrompts && prevPrompts.length > 0
              ? prevPrompts.map((item, index) =>
                  React.createElement(
                    'div',
                    {
                      key: index,
                      onClick: () => loadPrompt(item),
                      className: 'recent-entry',
                    },
                    React.createElement('img', {
                      src: assets.message_icon,
                      alt: 'Message Icon',
                    }),
                    React.createElement('p', null, `${item.slice(0, 18)} ...`)
                  )
                )
              : React.createElement('p', null, 'No recent prompts available')
          )
        : null
    )
  );
}

export default Sidebar;
