import classes from "../Pages.module.css";
import { Row, Col, Tabs, Skeleton, message } from "antd";
import axios from "axios";
import DataContext from "../../../Context/dataContext";
import { useState, useEffect, useContext } from "react";
import BasicDetails from "./BasicDetails";
import ItemSize from "./ItemSize";
import GradeDetails from "./GradeDetails";
import AccountDetails from "./AccountDetails";
import dayjs from "dayjs";
import SyncLoader from "react-spinners/SyncLoader";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
const { TabPane } = Tabs;

const ItemView = (props) => {
  // // console.log(props);
  const { id } = useParams();
  const employeeData = useContext(DataContext);

  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(true);
  const [update, setUpdate] = useState(true);
  const [allData, setAllData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [ad, setAD] = useState(null);

  useEffect(() => {
    setAllData(null);
    setStoreData(null);
    setAD(null);

    axios
      .get(employeeData.URL + "/api/v1/items/" + id, {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);

        setAllData((allData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });

        setStoreData((storeData) => {
          let newDetails = response.data.data;
          return {
            ...newDetails,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });

    axios
      .get(employeeData.URL + "/api/v1/items/additional-data", {
        withCredentials: true,
      })
      .then((response) => {
        console.log(response);
        setAD((ad) => {
          let newad = response.data.data;
          return {
            ...newad,
          };
        });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const handleSave = (event) => {
    const postData = {
      ...allData,
    };

    const postDataS = {
      ...storeData,
      itemSize: storeData.itemSize.map((dep) => {
        return {
          ...dep,
        };
      }),
      gradeDetails: storeData.gradeDetails.map((dep) => {
        return {
          ...dep,
        };
      }),
      accountDetails: storeData.accountDetails.map((dep) => {
        return {
          ...dep,
        };
      }),
    };

    // console.log(postData);
    // console.log(postData);
    // console.log(postData);

    postData.itemSize.forEach((lang, index) => {
      if (!lang.uniq_code) {
        postData.itemSize[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.itemSize[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    postDataS.itemSize.forEach((lang, index) => {
      const dataIndex = postData.itemSize.findIndex(
        (element) => element.uniq_code === lang.uniq_code
      );
      if (dataIndex === -1) {
        postData.itemSize.push({
          uniq_code: lang.uniq_code,
          PARAM: "DELETE",
        });
      }
    });

    postData.gradeDetails.forEach((lang, index) => {
      if (!lang.uniq_code) {
        postData.gradeDetails[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.gradeDetails[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    postDataS.gradeDetails.forEach((lang, index) => {
      const dataIndex = postData.gradeDetails.findIndex(
        (element) => element.uniq_code === lang.uniq_code
      );
      if (dataIndex === -1) {
        postData.gradeDetails.push({
          uniq_code: lang.uniq_code,
          PARAM: "DELETE",
        });
      }
    });

    postData.accountDetails.forEach((lang, index) => {
      if (!lang.uniq_code) {
        postData.accountDetails[index] = {
          ...lang,
          PARAM: "INSERT",
        };
      } else {
        postData.accountDetails[index] = {
          ...lang,
          PARAM: "UPDATE",
        };
      }
    });

    postDataS.accountDetails.forEach((lang, index) => {
      const dataIndex = postData.accountDetails.findIndex(
        (element) => element.uniq_code === lang.uniq_code
      );
      if (dataIndex === -1) {
        postData.accountDetails.push({
          uniq_code: lang.uniq_code,
          PARAM: "DELETE",
        });
      }
    });

    // console.log(JSON.stringify(postData, undefined, 2));
    const removeDuplicates = (array, key) => {
      return array.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t[key] === value[key])
      );
    };

    // Check if the arrays exist and remove duplicates where applicable
    if (Array.isArray(postData.accountDetails)) {
      postData.accountDetails = removeDuplicates(
        postData.accountDetails,
        "account_code"
      );
    }

    if (Array.isArray(postData.gradeDetails)) {
      postData.gradeDetails = removeDuplicates(
        postData.gradeDetails,
        "quality_code"
      );
    }

    if (Array.isArray(postData.itemSize)) {
      postData.itemSize = removeDuplicates(postData.itemSize, "size_code");
    }

    axios
      .patch(
        employeeData.URL +
          "/api/v1/items/" +
          postData.itemMaster[0]["item_code"],
        postData,
        {
          withCredentials: true,
          credentials: "include",
        }
      )
      .then((response) => {
        if (response.data.status === "fail") {
          message.error({
            content: response.data.message,
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          history("/sales/itemMaster/item-details");
          setLoading(false);
        } else {
          message.success({
            content: "Item master Updated Successfully!!!",
            className: "custom-class",
            style: {
              marginTop: "2vh",
            },
          });
          setLoading(false);
          history("/sales/itemMaster/item-details");
        }
      })
      .catch((err) => {
        message.error({
          content: "An Error Occurred!!!!",
          className: "custom-class",
          style: {
            marginTop: "2vh",
          },
        });
        setLoading(false);
      });

    setEditMode(false);
  };

  const handleCancel = (event) => {
    setLoading(false);
    history("/sales/itemMaster/item-details");
  };

  return (
    <>
      {allData && ad ? (
        <Row className={classes["RowP"]}>
          <Col
            lg={editMode ? 13 : 19}
            md={editMode ? 13 : 19}
            className={classes["Col"]}
          ></Col>
          {editMode ? (
            <>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleSave(event)}
                    className={classes["ProfileButtonn"]}
                    id="save"
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                  >
                    Save
                  </button>
                )}
              </Col>
              <Col md={4}>
                {loading ? (
                  <SyncLoader color={"rgba(255,163,77,0.8)"} size={10} />
                ) : (
                  <button
                    style={{
                      padding: "7px 30px 14px 24px",
                      borderRadius: "50px",
                      background: "transparent",
                      width: "100%",
                      border: "none",
                      outline: "none",
                      fontSize: "16px",
                      fontWeight: "600",
                      lineHeight: "1.5",
                      color: "#071e54",
                      WebkitAppearance: "none",

                      boxShadow:
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff", // Original box shadow
                      height: "40px",

                      // Ensure the button takes full width
                    }}
                    onClick={(event) => handleCancel(event)}
                    onMouseOver={(event) => {
                      event.target.style.boxShadow = "0px 5px 15px 0px #264a9f"; // Apply spread color on hover with 0 spread at the top
                    }}
                    onMouseOut={(event) => {
                      event.target.style.boxShadow =
                        "-3px -3px 6px #fff, 3px 3px 6px #bfc3cf, 3px 3px 6px #bfc3cf, -3px -3px 6px #fff"; // Restore original shadow on mouse out
                    }}
                    className={classes["ProfileButtonn"]}
                  >
                    Cancel
                  </button>
                )}
              </Col>
            </>
          ) : (
            <Col md={4}>
              <button
                onClick={() => setEditMode(true)}
                className={classes["ProfileButton"]}
              >
                Edit Details
              </button>
            </Col>
          )}

          <Col lg={24} md={24} className={classes["Col"]}>
            <div style={{ textAlign: "center" }}>
              <h3
                style={{
                  display: "inline-block",
                  padding: "10px 20px",
                  borderRadius: "5px 5px 0 0",
                  backgroundColor: "white",
                  // border: "0.5px solid #d9d9d9",
                  color: "#1560BD",
                  fontSize: "14px",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Item Master
              </h3>
            </div>
            <BasicDetails
              create={true}
              ad={ad}
              setData={setAllData}
              data={allData.itemMaster[0]}
              editMode={editMode}
            />

            <Row style={{ width: "100%" }}>
              <Col
                lg={8}
                style={{
                  paddingLeft: "40px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h3
                    style={{
                      display: "inline-block",
                      // padding: "10px 20px",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: "white",
                      // border: "0.5px solid #d9d9d9",
                      color: "#1560BD",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Item Size Detail
                  </h3>
                </div>

                <ItemSize
                  ad={ad}
                  setData={setAllData}
                  data={allData.itemSize}
                  editMode={editMode}
                  update={update}
                />
              </Col>
              <Col
                lg={8}
                style={{
                  paddingLeft: "20px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h3
                    style={{
                      display: "inline-block",
                      // padding: "10px 20px",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: "white",
                      // border: "0.5px solid #d9d9d9",
                      color: "#1560BD",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Item Grade Details
                  </h3>
                </div>

                <GradeDetails
                  ad={ad}
                  setData={setAllData}
                  data={allData.gradeDetails}
                  editMode={editMode}
                  update={update}
                />
              </Col>
              <Col
                lg={8}
                style={{
                  paddingLeft: "20px",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h3
                    style={{
                      display: "inline-block",
                      // padding: "10px 20px",
                      borderRadius: "5px 5px 0 0",
                      backgroundColor: "white",
                      // border: "0.5px solid #d9d9d9",
                      color: "#1560BD",
                      fontSize: "14px",
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                  >
                    Account Details
                  </h3>
                </div>

                <AccountDetails
                  ad={ad}
                  setData={setAllData}
                  data={allData.accountDetails}
                  editMode={editMode}
                  update={update}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      ) : (
        <>
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
          <Skeleton active={true} />
        </>
      )}
    </>
  );
};

export default ItemView;
