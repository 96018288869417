import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Row,
  Col,
  Table,
  Input,
  message,
  Select,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import axios from "axios";
//import "./breakdown.css"; // Import CSS file for styles
import classes from "../Pages.module.css";
import styles from "../Pages.module.css";

const { Option } = Select;

const BreakdownFeeding = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [breakdownHeader, setBreakdownHeader] = useState({
    Feeding_Date: "",
    Remarks: "",
  });

  const [breakdownDetails, setBreakdownDetails] = useState([
    {
      f_date: null,
      f_time: null,
      t_date: null,
      t_time: null,
      solution: "",
      breakdownReason: "",
      location: "",
      breakdownTime: "",
      includeInSpeed: "",
    },
  ]);

  const [reasons, setReasons] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchReasons = async () => {
      try {
        const response = await axios.get(
          "http://sastetab.com/api/v1/breakdown/show/reason",
          {
            withCredentials: true,
            credentials: "include",
          }
        );
        const reasonsData = response.data.data.reason.rows;
        const locationsData = response.data.data.location.rows; // Access rows array
        setReasons(reasonsData);
        setLocations(locationsData);
      } catch (error) {
        console.error("Failed to fetch reasons:", error);
        message.error("Failed to fetch breakdown reasons.");
      }
    };
    fetchReasons();
  }, []);

  const handleHeaderChange = (changedValues) => {
    setBreakdownHeader({ ...breakdownHeader, ...changedValues });
  };

  const handleDetailChange = (index, changedValues) => {
    const updatedDetails = [...breakdownDetails];
    const updatedDetail = { ...updatedDetails[index], ...changedValues };

    if (updatedDetail.f_time && updatedDetail.t_time) {
      const fTime = dayjs(updatedDetail.f_time, "HH:mm");
      const tTime = dayjs(updatedDetail.t_time, "HH:mm");

      // Calculate difference in minutes
      const diffMinutes = tTime.diff(fTime, "minute");
      // Ensure breakdownTime is non-negative
      updatedDetail.breakdownTime = Math.max(0, diffMinutes);
    } else {
      updatedDetail.breakdownTime = ""; // Clear breakdownTime if not enough data
    }

    updatedDetails[index] = updatedDetail;
    setBreakdownDetails(updatedDetails);
  };

  const addRow = () => {
    setBreakdownDetails([
      ...breakdownDetails,
      {
        f_date: null,
        f_time: "",
        t_date: null,
        t_time: "",
        solution: "",
        breakdownReason: "",
        location: "",
        breakdownTime: "",
        includeInSpeed: "",
      },
    ]);
  };

  const removeRow = (index) => {
    if (breakdownDetails.length > 1) {
      const updatedDetails = [...breakdownDetails];
      updatedDetails.splice(index, 1);
      setBreakdownDetails(updatedDetails);
    }
  };

  const handleSubmit = async () => {
    try {
      const postData = {
        breakdownHeader: {
          ...breakdownHeader,
          Feeding_Date: breakdownHeader.Feeding_Date
            ? dayjs(breakdownHeader.Feeding_Date).format("YYYY-MM-DD")
            : "",
        },
        breakdownDetails: breakdownDetails.map((detail) => ({
          ...detail,
          f_date: detail.f_date
            ? dayjs(detail.f_date).format("YYYY-MM-DD")
            : "",
          t_date: detail.t_date
            ? dayjs(detail.t_date).format("YYYY-MM-DD")
            : "",
        })),
      };

      const response = await axios.post(
        "http://sastetab.com/api/v1/breakdown/create/breakdown",
        postData,
        {
          withCredentials: true,
          credentials: "include",
        }
      );

      if (response.data.status === "success") {
        message.success("Breakdown details submitted successfully!");
        history("/production/transaction/breakdown-feeding");
      }
    } catch (error) {
      console.error("Failed to submit breakdown details:", error);
      message.error("Failed to submit breakdown details. Please try again.");
    }
  };

  const columns = [
    {
      title: "From Date",
      dataIndex: "f_date",
      key: "f_date",
      width: 150, // Set fixed width
      render: (text, record, index) => (
        <DatePicker
          value={record.f_date ? dayjs(record.f_date) : null}
          onChange={(date) => handleDetailChange(index, { f_date: date })}
          format="DD-MM-YYYY"
        />
      ),
    },

    {
      title: "From Time",
      dataIndex: "f_time",
      key: "f_time",
      width: 170, // Set fixed width
      render: (text, record, index) => (
        <Input
          type="time"
          value={text}
          onChange={(e) =>
            handleDetailChange(index, { f_time: e.target.value })
          }
        />
      ),
    },
    {
      title: "To Date",
      dataIndex: "t_date",
      key: "t_date",
      width: 150, // Set fixed width
      render: (text, record, index) => (
        <DatePicker
          value={record.t_date ? dayjs(record.t_date) : null}
          onChange={(date) => handleDetailChange(index, { t_date: date })}
          format="DD-MM-YYYY"
        />
      ),
    },
    {
      title: "To Time",
      dataIndex: "t_time",
      key: "t_time",
      width: 170, // Set fixed width
      render: (text, record, index) => (
        <Input
          type="time"
          value={text}
          onChange={(e) =>
            handleDetailChange(index, { t_time: e.target.value })
          }
        />
      ),
    },
    {
      title: "Solution",
      dataIndex: "solution",
      key: "solution",
      width: 200, // Set fixed width
      render: (text, record, index) => (
        <Input
          value={text}
          onChange={(e) =>
            handleDetailChange(index, { solution: e.target.value })
          }
        />
      ),
    },
    {
      title: "Break Down Reason",
      dataIndex: "breakdownReason",
      key: "breakdownReason",
      width: 200, // Set fixed width
      render: (text, record, index) => (
        <Select
          value={text}
          onChange={(value) =>
            handleDetailChange(index, { breakdownReason: value })
          }
        >
          {reasons.length > 0 ? (
            reasons.map((reason) => (
              <Option key={reason.reason_code} value={reason.reason_code}>
                {reason.reason_name}
              </Option>
            ))
          ) : (
            <Option value="">No reasons available</Option>
          )}
        </Select>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 200, // Set fixed width
      render: (text, record, index) => (
        <Select
          value={text}
          onChange={(value) => handleDetailChange(index, { location: value })}
        >
          {locations.length > 0 ? (
            locations.map((location) => (
              <Option
                key={location.location_code}
                value={location.location_code}
              >
                {location.location}
              </Option>
            ))
          ) : (
            <Option value="">No locations available</Option>
          )}
        </Select>
      ),
    },
    {
      title: "Break Down Time (In min)",
      dataIndex: "breakdownTime",
      key: "breakdownTime",
      width: 180, // Set fixed width
      render: (text) => <Input value={text} disabled />,
    },
    {
      title: "Include In Speed",
      dataIndex: "includeInSpeed",
      key: "includeInSpeed",
      width: 150, // Set fixed width
      render: (text, record, index) => (
        <Select
          value={text}
          onChange={(value) =>
            handleDetailChange(index, { includeInSpeed: value })
          }
        >
          <Option value="yes">Yes</Option>
          <Option value="no">No</Option>
        </Select>
      ),
    },
    // {
    //   title: "Actions",
    //   key: "actions",
    //   width: 120, // Set fixed width
    //   render: (text, record, index) => (
    //     <>
    //       <Button type="link" onClick={addRow}>
    //         <svg
    //           fill="currentColor"
    //           height="1em"
    //           width="1em"
    //           viewBox="0 0 24 24"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path d="M12 5v14M5 12h14" />
    //         </svg>
    //       </Button>
    //       <Button type="link" danger onClick={() => removeRow(index)}>
    //         <svg
    //           fill="currentColor"
    //           height="1em"
    //           width="1em"
    //           viewBox="0 0 24 24"
    //           xmlns="http://www.w3.org/2000/svg"
    //         >
    //           <path d="M6 18L18 6M6 6l12 12" />
    //         </svg>
    //       </Button>
    //     </>
    //   ),
    // },
  ];

  return (
    <Form form={form} layout="vertical" onValuesChange={handleHeaderChange}>
      <Row
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Col lg={18} style={{ textAlign: "center" }}>
          <h5>Breakdown Feeding</h5>
        </Col>
        <Col lg={5}>
          <Form.Item>
            <button
              type="primary"
              onClick={handleSubmit}
              className={classes["ProfileButton"]}
            >
              Submit
            </button>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={6}>
          <Form.Item label="Feeding Date">
            <DatePicker
              value={
                breakdownHeader.Feeding_Date
                  ? dayjs(breakdownHeader.Feeding_Date)
                  : null
              }
              onChange={(date) =>
                setBreakdownHeader({ ...breakdownHeader, Feeding_Date: date })
              }
              format="DD-MM-YYYY"
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Col span={9}>
        <Form.Item label="Remarks">
          <Input.TextArea
            value={breakdownHeader.Remarks}
            onChange={(e) =>
              setBreakdownHeader({
                ...breakdownHeader,
                Remarks: e.target.value,
              })
            }
          />
        </Form.Item>
      </Col>

      <Table
        dataSource={breakdownDetails}
        columns={columns}
        pagination={false}
        scroll={{ x: 1500, y: 500 }}
        rowKey="id"
        // footer={() => (
        //   <Button
        //     type="dashed"
        //     onClick={addRow}
        //     icon={
        //       <svg
        //         fill="currentColor"
        //         height="1em"
        //         width="1em"
        //         viewBox="0 0 24 24"
        //         xmlns="http://www.w3.org/2000/svg"
        //       >
        //         <path d="M12 5v14M5 12h14" />
        //       </svg>
        //     }
        //   >
        //     Add Row
        //   </Button>
        // )}
      />
    </Form>
  );
};

export default BreakdownFeeding;
